import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { api_error_translate } from '../../texts/error';
import { model_names } from '../../texts/model_names';
import { interface_translate } from '../../texts/interface';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import OpeningBlock from '../../components/OpeningBlock';
import RelatedModelsCreate from '../../components/related_models_create/RelatedModelsCreate';
import ModalWindow from '../../components/ModalWindow';
import ShelvingCreatePage from '../../pages/shelving/ShelvingCreatePage';
import StorageCellCreatePage from '../../pages/storage_cell/StorageCellCreatePage';
import roleAccess from '../../roleAccess';
import storehouseService from '../../services/storehouse';
import shelvingService from '../../services/shelving';
import storageCellService from '../../services/storage_cell';
import styles from './StorehousePage.module.css';
import '../shared.css';

async function get_storehouse(id) {
  const storehouse = await storehouseService.get(id);
  return storehouse;
}

async function get_shelvings(filters = {}, size = 50) {
  const shelvingsPaginated = await shelvingService.get_by_filters(filters, {
    size: size,
  });
  return shelvingsPaginated.data;
}

async function get_storage_cells(filters = {}, size = 50) {
  const storageCellsPaginated = await storageCellService.get_by_filters(
    filters,
    { size: size }
  );
  return storageCellsPaginated.data;
}

async function delete_shelving(id, setGlobalError, language) {
  const result = await shelvingService.delete(id, setGlobalError, language);
  return result;
}

async function delete_storage_cell(id, setGlobalError, language) {
  const result = await storageCellService.delete(id, setGlobalError, language);
  return result;
}

function get_storage_cell_for_display(storageCell, language) {
  storageCell.shelving_name = storageCell.shelving.name;
  storageCell.free_name = storageCell.free
    ? interface_translate['free'][language]
    : interface_translate['occupied'][language];
  return storageCell;
}

const shelving_table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'shelving id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'name',
    name_for_display: 'name',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'storehouse_name',
    name_for_display: 'storehouse',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'storage_cell_free',
    name_for_display: 'free',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'storage_cell_not_free',
    name_for_display: 'occupied',
    translate_name_for_display: null,
    use_sort: true,
  },
];
const storage_cell_table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'storage cell id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'number',
    name_for_display: 'number',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'shelving_name',
    name_for_display: 'shelving',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'free_name',
    name_for_display: 'free',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const StorehousePage = (props) => {
  const { setGlobalError, language, userRole } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [storehouse, setStorehouse] = useState();
  const [shelvings, setShelvings] = useState();
  const [selectedShelvings, setSelectedShelvings] = useState([]);
  const [createShelvingView, setCreateShelvingView] = useState(false);
  const [shelvingStorageCellCount, setShelvingStorageCellCount] = useState({});
  const [storageCells, setStorageCells] = useState([]);
  const [selectedStorageCells, setSelectedStorageCells] = useState([]);
  const [createStorageCellView, setCreateStorageCellView] = useState(false);
  const [attrsForRead, setAttrsForRead] = useState(new Set());
  const [shelvingTableAttrs, setShelvingTableAttrs] = useState([]);
  const [storageCellTableAttrs, setStorageCellTableAttrs] = useState([]);

  useEffect(() => {
    requestGetStorehouse();
    pushPage(location.pathname);
    const attrs_for_read = new Set(
      roleAccess[userRole].fieldRestrictions.Storehouses?.read
    );
    setAttrsForRead(attrs_for_read);
    const shelving_table_attrs = shelving_table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions.Shelvings?.read_all.includes(
          attr_info.name
        )
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setShelvingTableAttrs(shelving_table_attrs);
    const storage_cell_table_attrs = storage_cell_table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions[
          'Storage cells'
        ]?.read_all.includes(attr_info.name)
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setStorageCellTableAttrs(storage_cell_table_attrs);
  }, []);

  useEffect(() => {
    for (let storage_cell of storageCells) {
      storage_cell = get_storage_cell_for_display(storage_cell, language);
    }
    setStorageCells([...storageCells]);
  }, [language]);

  const requestGetStorehouse = async () => {
    const storehouse_resp = await get_storehouse(params.id);
    const storageCells = await get_storage_cells({
      storehouse_id: storehouse_resp.id,
    });
    const shelvingStorageCellCount_ = {};
    for (let storage_cell of storageCells) {
      storage_cell = get_storage_cell_for_display(storage_cell, language);
      if (!shelvingStorageCellCount_.hasOwnProperty(storage_cell.shelving.id)) {
        shelvingStorageCellCount_[storage_cell.shelving.id] = {
          count: 0,
          free: 0,
          notFree: 0,
        };
      }
      shelvingStorageCellCount_[storage_cell.shelving.id].count += 1;
      if (storage_cell.free) {
        shelvingStorageCellCount_[storage_cell.shelving.id].free += 1;
      } else {
        shelvingStorageCellCount_[storage_cell.shelving.id].notFree += 1;
      }
    }
    setStorageCells(storageCells);
    setShelvingStorageCellCount(shelvingStorageCellCount_);
    const shelvings = await get_shelvings({
      storehouse_id: storehouse_resp.id,
    });
    for (const shelving of shelvings) {
      shelving.storehouse_name = shelving.storehouse.name;
      if (shelvingStorageCellCount_.hasOwnProperty(shelving.id)) {
        shelving.storage_cell_free =
          shelvingStorageCellCount_[shelving.id]['free'];
        shelving.storage_cell_not_free =
          shelvingStorageCellCount_[shelving.id]['notFree'];
      } else {
        shelving.storage_cell_free = 0;
        shelving.storage_cell_not_free = 0;
      }
    }
    setShelvings(shelvings);
    setStorehouse(storehouse_resp);
  };

  const deleteShelvings = async () => {
    setShelvings(
      shelvings.filter((model) => !selectedShelvings.includes(model.id))
    );
    for (const id of selectedShelvings) {
      const result = await delete_shelving(id, setGlobalError, language);
      if (!result) {
        requestGetStorehouse();
      }
    }
  };

  const addShelving = (shelving) => {
    shelving.storage_cell_free = 0;
    shelving.storage_cell_not_free = 0;
    shelving.storehouse_name = shelving.storehouse.name;
    setShelvings([shelving, ...shelvings]);
  };

  const deleteStorageCells = async () => {
    setStorageCells(
      storageCells.filter((model) => !selectedStorageCells.includes(model.id))
    );
    for (const id of selectedStorageCells) {
      const result = await delete_storage_cell(id, setGlobalError, language);
      if (!result) {
        requestGetStorehouse();
      }
    }
  };

  const addStorageCell = (storageCell) => {
    storageCell = get_storage_cell_for_display(storageCell, language);
    setStorageCells([storageCell, ...storageCells]);
    let shelvingForChange = null;
    let shelvingIndex = null;
    for (let i = 0; i < shelvings.length; i++) {
      if (shelvings[i].id == storageCell.shelving.id) {
        shelvingForChange = shelvings[i];
        shelvingIndex = i;
        break;
      }
    }
    shelvingForChange.storage_cell_free += 1;
    shelvings.splice(shelvingIndex, 1, shelvingForChange);
    setShelvings([...shelvings]);
  };

  if (!storehouse) return;

  return (
    <div>
      <div className="controls">
        {roleAccess[userRole].permissions.Storehouses.edit && (
          <button
            onClick={() => {
              navigate('edit');
            }}
          >
            {interface_translate['Edit'][language]}
          </button>
        )}
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      <div className="pageViewModel">
        <div className="viewData">
          <OpeningBlock
            title={interface_translate['About storehouse'][language]}
            open={true}
          >
            {attrsForRead.has('id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['storehouse id'][language]}
                </div>
                <span>{storehouse.id}</span>
              </div>
            )}
            {attrsForRead.has('name') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Name'][language]}
                </div>
                <span>{storehouse.name}</span>
              </div>
            )}
            {attrsForRead.has('address') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Address'][language]}
                </div>
                <span>{storehouse.address}</span>
              </div>
            )}
          </OpeningBlock>
        </div>
      </div>
      <div className={styles.storehouseEditShelvingsAndStorageCells}>
        <div className={styles.manageForm}>
          <div className="openingBlockAttrName">
            {interface_translate['Shelvings'][language]}
          </div>
          <RelatedModelsCreate
            section="Shelvings"
            tableAttrs={shelvingTableAttrs}
            models={shelvings}
            setModels={setShelvings}
            setSelectedModels={setSelectedShelvings}
            addModel={() => setCreateShelvingView(true)}
            deleteModels={deleteShelvings}
            handleModelClick={(model) =>
              navigate(`/shelvings/${model.id}`, {
                state: { from: location },
              })
            }
          />
        </div>
        <div className={styles.manageForm}>
          <div className="openingBlockAttrName">
            {interface_translate['Storage cells'][language]}
          </div>
          <RelatedModelsCreate
            section="Storage cells"
            tableAttrs={storageCellTableAttrs}
            models={storageCells}
            setModels={setStorageCells}
            setSelectedModels={setSelectedStorageCells}
            addModel={() => setCreateStorageCellView(true)}
            deleteModels={deleteStorageCells}
            handleModelClick={(model) =>
              navigate(`/storage_cells/${model.id}`, {
                state: { from: location },
              })
            }
          />
        </div>
      </div>
      {storehouse && createShelvingView && (
        <ModalWindow
          isActive={createShelvingView}
          setIsActive={setCreateShelvingView}
        >
          <div className={styles.mоdalCreateShelving}>
            <ShelvingCreatePage
              storehouseId={storehouse.id}
              addShelving={addShelving}
              handleExit={() => setCreateShelvingView(false)}
            />
          </div>
        </ModalWindow>
      )}
      {storehouse && createStorageCellView && (
        <ModalWindow
          isActive={createStorageCellView}
          setIsActive={setCreateStorageCellView}
        >
          <div className={styles.mоdalCreateStorageCell}>
            <StorageCellCreatePage
              storehouseId={storehouse.id}
              addStorageCell={addStorageCell}
              handleExit={() => setCreateStorageCellView(false)}
            />
          </div>
        </ModalWindow>
      )}
    </div>
  );
};

export default StorehousePage;
