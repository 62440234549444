import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TfiReload } from 'react-icons/tfi';
import { IoIosAddCircleOutline } from 'react-icons/io';
import useCommon from '../../hooks/useCommon.jsx';
import { interface_translate } from '../../texts/interface.js';
import ConfirmDialog from '../..//components/ConfirmDialog.jsx';
import roleAccess from '../../roleAccess.js';
import styles from './ModelsControls.module.css';

const ModelsControls = (props) => {
  const { language, userRole } = useCommon();
  const navigate = useNavigate();
  const {
    children,
    model_name,
    filtersActivated,
    toggleShowFilters,
    requestModels,
    deleteModels,
    section,
  } = props;
  const [viewDeleteConfirm, setViewDeleteConfirm] = useState(false);
  return (
    <div className={styles.modelsControls}>
      {viewDeleteConfirm && (
        <ConfirmDialog
          message={interface_translate['Delete?'][language]}
          onConfirm={() => {
            setViewDeleteConfirm(false);
            deleteModels();
          }}
          onCancel={() => setViewDeleteConfirm(false)}
        />
      )}
      <h1>{model_name}</h1>
      <div
        className={`${styles.btnReload} ${styles.btn}`}
        onClick={requestModels}
      >
        <TfiReload />
      </div>
      {roleAccess[userRole].permissions?.[section]?.create && (
        <button
          className={`${styles.btnAdd} ${styles.btn}`}
          onClick={() => navigate('create')}
        >
          <IoIosAddCircleOutline className={styles.btnIcon} />
          <p>{interface_translate['Create'][language]}</p>
        </button>
      )}
      <button
        className={
          `$${styles.btn}` + ` ${filtersActivated ? styles.btnActivated : ''}`
        }
        onClick={toggleShowFilters}
      >
        <p>{interface_translate['Filter'][language]}</p>
      </button>
      {roleAccess[userRole].permissions?.[section]?.delete && (
        <button
          className={`${styles.btn}`}
          onClick={() => setViewDeleteConfirm(true)}
        >
          <p>{interface_translate['Delete'][language]}</p>
        </button>
      )}
      {children}
    </div>
  );
};

export default ModelsControls;
