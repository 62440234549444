import apiRequester from '../ApiRequester';
import { model_names } from '../texts/model_names.js';
import { api_error_translate } from '../texts/error.js';

class CustomerCargoProductService {
  async get_by_filters(filterData, paginationSettings = { size: 20 }) {
    const jsonData = JSON.stringify(filterData);
    try {
      const response = await apiRequester.post(
        `/customer_cargo_products/filters`,
        jsonData,
        {
          params: paginationSettings,
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }
}

const customerCargoProductService = new CustomerCargoProductService();
export default customerCargoProductService;
