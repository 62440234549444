import axios from 'axios';
import { domain } from './conf';

const apiRequester = axios.create({
  baseURL: `https://${domain}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default apiRequester;
