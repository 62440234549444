export const api_error_translate = {
  'counterparty already exists': {
    ru: 'Контрагент уже создан',
    en: 'counterparty already exists',
    ch: '交易对手已经创建',
  },
  'product already exists': {
    ru: 'Товар уже создан',
    en: 'product already exists',
    ch: '产品已经创建',
  },
  'delivery already exists': {
    ru: 'Посылка уже создана',
    en: 'delivery already exists',
    ch: '包已创建',
  },
  'storehouse already exists': {
    ru: 'Склад уже создан',
    en: 'storehouse already exists',
    ch: '仓库已创建',
  },
  'shelving already exists': {
    ru: 'Стеллаж уже создан',
    en: 'shelving already exists',
    ch: '搁架已创建',
  },
  'storage cell already exists': {
    ru: 'Ячейка хранения уже создана',
    en: 'storage cell already exists',
    ch: '存储单元已创建',
  },
  'delivery not found': {
    ru: 'Посылка не найдена',
    en: 'delivery not found',
    ch: '未找到包裹',
  },
  'not found': { ru: 'Не найдено', en: 'not found', ch: '未找到' },
  'Field required': {
    ru: 'Обязательное поле',
    en: 'Field required',
    ch: '该字段是必需的',
  },
  'Invalid phone number': {
    ru: 'Неверный формат номера',
    en: 'Invalid phone number',
    ch: '无效的数字格式',
  },
  'Invalid link': {
    ru: 'Неверный формат ссылки',
    en: 'Invalid link',
    ch: '无效的链接格式',
  },
  'Should be no repetitions': {
    ru: 'Повторений быть не должно',
    en: 'Should be no repetitions',
    ch: '不应该有重复',
  },
  'Least one': { ru: 'Не менее одного', en: 'Least one', ch: '至少一个' },
  'co_executors_ids already in responsible_ids': {
    ru: 'В ответственных уже есть некоторые соисполнители',
    en: 'co executors already in responsible',
    ch: '已经有一些共同执行者负责',
  },
  'Must be greater than 0': {
    ru: 'Должно быть больше 0',
    en: 'Must be greater than 0',
    ch: '必须大于0',
  },
  'Must be greater than or equal to 0': {
    ru: 'Должно быть больше или равно 0',
    en: 'Must be greater than or equal to 0',
    ch: 'Must be greater than or equal to 0',
  },
  'There are blank fields left': {
    ru: 'Остались незаполненные поля',
    en: 'There are blank fields left',
    ch: '还有空行',
  },
  'Input should be a valid number, unable to parse string as a number': {
    ru: 'Не валидное число',
    en: 'Input should be a valid number',
    ch: '无效数',
  },
  'Input should be a valid string': {
    ru: 'Невалидная строка',
    en: 'Input should be a valid string',
    ch: '',
  },
  'Input should be a valid number': {
    ru: 'Не валидное число',
    en: 'Input should be a valid number',
    ch: '无效数',
  },
  'Input should be a valid integer, unable to parse string as an integer': {
    ru: 'Не валидное целое число',
    en: 'Input should be a valid integer',
    ch: '无效整数',
  },
  'Input should be a valid integer': {
    ru: 'Не валидное целое число',
    en: 'Input should be a valid integer',
    ch: '无效整数',
  },
  'must belong to the client group': {
    ru: 'Должен быть клиентом',
    en: 'must belong to the client group',
    ch: '必须客户',
  },
  'must belong to the supplier group': {
    ru: 'Должен быть поставщиком',
    en: 'must belong to the supplier group',
    ch: '必须供应商',
  },
  'Unauthorized file extension': {
    ru: 'Неразрешенное расширение файла',
    en: 'Unauthorized file extension',
    ch: '未经授权的文件扩展名',
  },

  "Input should be 'rub', 'usd' or 'cny'": {
    ru: 'Должны быть выбраны rub, usd или chy',
    en: "Input should be 'rub', 'usd' or 'cny'",
    ch: '必须选择Rub, usd或chy',
  },
  'You need to select an order': {
    ru: 'Нужно выбрать заказ',
    en: 'You need to select an order',
    ch: '您需要选择订单',
  },
  'not have storage cell': {
    ru: 'Не имеет ячейки хранения',
    en: 'Not have storage cell',
    ch: '没有存储单元',
  },
  'bad user email': {
    ru: 'Не верный логин или пароль',
    en: 'Bad user email',
    ch: '用户名或密码无效',
  },
  'bad user password': {
    ru: 'Не верный пароль',
    en: 'Bad user password',
    ch: '密码无效',
  },
  'duplicated in other records': {
    ru: 'Дублируется в других записях',
    en: 'Duplicated in other records',
    ch: '在其他记录中重复',
  },
  'linked to another model': {
    ru: 'Имеются связи с другой моделью',
    en: 'Linked to another model',
    ch: '与另一个模型有联系',
  },
  'should be free': {
    ru: 'Должна быть пустая',
    en: 'Should be free',
    ch: '应该是空的',
  },
  'Estimated date arrival date must be greater than departure date': {
    ru: 'Предполагаемая дата прибытия должна быть больше даты отъезда',
    en: 'Estimated date arrival date must be greater than departure date',
    ch: '预计抵达日期必须大于出发日期',
  },
  'not ready to ship': {
    ru: 'Не готова к отправке',
    en: 'Not ready to ship',
    ch: '尚未准备好发送',
  },
  'more qty in delivery_product': {
    ru: 'Больше кол-ва товара в поставаке',
    en: 'More qty in delivery product',
    ch: '',
  },
  'full_name required': {
    ru: 'Поле ФИО обязательно для заполнения',
    en: 'Full Name is required',
    ch: '姓名是必填项',
  },
  'invalid full_name': {
    ru: 'Введите полное ФИО (Фамилия Имя Отчество)',
    en: 'Please enter a valid full name (Last Name First Name Patronymic)',
    ch: '请输入完整的姓名（姓 名 父名）',
  },
  'Field required': {
    ru: 'Обязательное поле',
    en: 'Field required',
    ch: '必填字段',
  },
  'Invalid request': {
    ru: 'Некорректный запрос',
    en: 'Invalid request',
    ch: '无效请求',
  },
  'Invalid full name format': {
    ru: 'Некорректный формат ФИО',
    en: 'Invalid full name format',
    ch: '全名格式无效',
  },
  'Invalid phone number': {
    ru: 'Некорректный номер телефона',
    en: 'Invalid phone number',
    ch: '电话号码无效',
  },
  'Link to messenger': {
    ru: 'Ссылка на соц. сеть',
    en: 'Link to messenger',
    ch: '社交媒体链接',
  },
};
