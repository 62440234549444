import { useNavigate } from 'react-router-dom';
import styles from './AuthBtns.module.css';
import useCommon from '../../hooks/useCommon';
import { interface_translate } from '../../texts/interface';
import { Lan, Language } from '@mui/icons-material';

const AuthBtns = () => {
  const { language } = useCommon();
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleRegisterClick = () => {
    navigate('/register');
  };

  return (
    <div className={styles.authButtons}>
      <button
        id="login_btn"
        className={styles.authButton}
        onClick={handleLoginClick}
      >
        {interface_translate['Log in'][language]}
      </button>
      <button
        id="register_btn"
        className={styles.authButton}
        onClick={handleRegisterClick}
      >
        {interface_translate['Registration'][language]}
      </button>
    </div>
  );
};

export default AuthBtns;
