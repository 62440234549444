import { useState, React, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import { api_error_translate } from '../../texts/error';
import { statuses_translate } from '../../texts/statuses.js';
import { interface_translate } from '../../texts/interface';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import ConfirmDialog from '../../components/ConfirmDialog';
import ListAttrMedia from '../../components/ListAttrMedia';
import productService from '../../services/product.js';
import DropDownMediaBlock from '../../components/DropDownMediaBlock';
import { getPrevViewImgAndMediaBlockByImageObj } from '../../utils/image.js';
import roleAccess from '../../roleAccess.js';
import shipmentService from '../../services/shipment';
import orderService from '../../services/order';
import customerCargoService from '../../services/customer_cargo.js';
import storehouseService from '../../services/storehouse.js';
import storageCellService from '../../services/storage_cell.js';
import userService from '../../services/user.js';
import styles from './CustomerCargoEditPage.module.css';
import '../shared.css';
import ResponsibleListInput from '../../components/input/ResponsibleListInput.jsx';
import CoExecutorsInput from '../../components/input/CoExecutorsInput.jsx';

async function get_customer_cargo(id) {
  const result = await customerCargoService.get(id);
  return result;
}

async function get_customer_cargo_statuses() {
  const result = await customerCargoService.get_statuses();
  return result;
}

async function update_customer_cargo(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await customerCargoService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_shipments(filters = {}, size = 50) {
  filters = {
    customer_cargo_id: null,
    ...filters,
  };
  const shipments_paginated = await shipmentService.get_by_filters(filters, {
    size: size,
  });
  return shipments_paginated.data;
}

async function get_storehouses(filters = {}, size = 50) {
  const result = await storehouseService.get_by_filters(filters, {
    size: size,
  });
  return result.data;
}

async function get_storage_cells(filters = {}, size = 50) {
  const result = await storageCellService.get_by_filters(filters, {
    size: size,
  });
  return result.data;
}

async function get_users(filters = {}, size = 50) {
  const result = await userService.get_by_filters(filters, { size: size });
  return result.data;
}

async function get_user(id) {
  const result = await userService.get(id);
  return result;
}

const CustomerCargoEditPage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState();
  const [oldCustomerCargo, setOldCustomerCargo] = useState();
  const [attrsForEdit, setAttrsForEdit] = useState(new Set());
  const [customerCargoStatuses, setCustomerCargoStatuses] = useState([]);
  const [defUsers, setDefUsers] = useState(null);
  const [shipmentInputValue, setShipmentInputValue] = useState('');
  const [shipmentElementsForSelect, setShipmentElementsForSelect] = useState(
    []
  );
  const [storehouse, setStorehouse] = useState(null);
  const [storehouseInputValue, setStorehouseInputValue] = useState('');
  const [storehouseElementsForSelect, setStorehouseElementsForSelect] =
    useState([]);
  const [storageCellInputValue, setStorageCellInputValue] = useState('');
  const [storageCellElementsForSelect, setStorageCellElementsForSelect] =
    useState([]);
  const [mediaContentForCreate, setMediaContentForCreate] = useState({});
  const [selectedMediaContentIdToDelete, setSelectedMediaContentIdToDelete] =
    useState(null);
  const [
    viewDeleteMediaContentConfirmDialog,
    setViewDeleteMediaContentConfirmDialog,
  ] = useState(false);
  const [mediaContentIdForDelete, setMediaContentIdForDelete] = useState([]);
  const [invoiceDocumentsForCreate, setInvoiceDocumentsForCreate] = useState(
    {}
  );
  const [
    selectedInvoiceDocumentsIdToDelete,
    setSelectedInvoiceDocumentsIdToDelete,
  ] = useState(null);
  const [
    viewDeleteInvoiceDocumentsConfirmDialog,
    setViewDeleteInvoiceDocumentsConfirmDialog,
  ] = useState(false);
  const [invoiceDocumentsIdForDelete, setInvoiceDocumentsIdForDelete] =
    useState([]);
  const [responsible, setResponsible] = useState({});
  const [coExecutors, setCoExecutors] = useState({});

  useEffect(() => {
    const req = async () => {
      const customer_cargo_resp = await get_customer_cargo(params.id);
      const order = await orderService.get(customer_cargo_resp.order_id);
      customer_cargo_resp.order = order;
      const responsible = [];
      for (const user_id of customer_cargo_resp.responsible_ids) {
        const user = await get_user(user_id);
        responsible.push(user);
      }
      customer_cargo_resp.responsible = responsible;
      const co_executors = [];
      for (const user_id of customer_cargo_resp.co_executors_ids) {
        const user = await get_user(user_id);
        co_executors.push(user);
      }
      customer_cargo_resp.coExecutors = co_executors;

      if (!customer_cargo_resp.weight_in_kg)
        customer_cargo_resp.weight_in_kg = '';
      if (!customer_cargo_resp.volume_in_m3)
        customer_cargo_resp.volume_in_m3 = '';
      if (!customer_cargo_resp.content) customer_cargo_resp.content = '';
      if (customer_cargo_resp.storage_cell) {
        setStorehouse(customer_cargo_resp.storage_cell.shelving.storehouse);
      }

      const customer_cargo = customer_cargo_resp;
      setOldCustomerCargo({ ...customer_cargo });
      setModelData(customer_cargo_resp);
      const customerCargoStatuses = await get_customer_cargo_statuses();
      setCustomerCargoStatuses(customerCargoStatuses);

      const users = await get_users({});
      setDefUsers(users);
    };
    req();
    pushPage(location.pathname);
    const attrsForEdit = new Set(
      roleAccess[userRole].fieldRestrictions['Customer cargo list']?.edit
    );
    setAttrsForEdit(attrsForEdit);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (!oldCustomerCargo) return;
      let filters = { complete: false };
      if (shipmentInputValue) filters['id'] = shipmentInputValue;
      const new_elements = await get_shipments(filters);

      setShipmentElementsForSelect([
        ...new_elements.map((m) => m.id),
        oldCustomerCargo.shipment_id,
      ]);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [shipmentInputValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (storehouseInputValue) filters['name'] = storehouseInputValue;
      const new_elements = await get_storehouses(filters);
      setStorehouseElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storehouseInputValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (!storehouse) return;
      let filters = { storehouse_id: storehouse.id };
      if (storageCellInputValue)
        filters['storage_cell_name'] = storageCellInputValue;
      const new_elements = await get_storage_cells(filters);
      setStorageCellElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storageCellInputValue]);

  const getDataFromAttr = () => {
    if (modelData.shipment_id == null) {
      setErrors({
        shipment_id: ['There are blank fields left'],
      });
      return;
    }
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    for (let id of responsible_ids) {
      if (id === null) {
        setErrors({
          responsible_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const co_executor_ids = Object.values(coExecutors).map((m) =>
      m ? m.id : null
    );
    for (let id of co_executor_ids) {
      if (id === null) {
        setErrors({
          co_executors_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const data = {
      shipment_id: modelData.shipment_id,
      status: modelData.status,
      responsible_ids: responsible_ids,
      co_executors_ids: co_executor_ids,
      media_content_for_create: Object.values(mediaContentForCreate),
      media_content_id_for_delete: mediaContentIdForDelete,
      invoice_documents_for_create: Object.values(invoiceDocumentsForCreate),
      invoice_documents_id_for_delete: invoiceDocumentsIdForDelete,
    };
    if (modelData.weight_in_kg) {
      data.weight_in_kg = modelData.weight_in_kg;
    }
    if (modelData.volume_in_m3) {
      data.volume_in_m3 = modelData.volume_in_m3;
    }
    if (modelData.content) {
      data.content = modelData.content;
    }
    if (modelData.storage_cell !== oldCustomerCargo.storage_cell) {
      data.storage_cell_id = modelData.storage_cell
        ? modelData.storage_cell.id
        : null;
    }
    return data;
  };

  if (!modelData) return;

  return (
    <div className="pageUpdateModel">
      {viewDeleteMediaContentConfirmDialog && (
        <ConfirmDialog
          message={interface_translate['Delete?'][language]}
          onConfirm={() => {
            setViewDeleteMediaContentConfirmDialog(false);
            setMediaContentIdForDelete((prevData) => {
              const new_data = [...prevData, selectedMediaContentIdToDelete];
              return new_data;
            });
            setSelectedMediaContentIdToDelete(null);
            setModelData({
              ...modelData,
              media_content: modelData.media_content.filter(
                (model) => model.id !== selectedMediaContentIdToDelete
              ),
            });
          }}
          onCancel={() => setViewDeleteMediaContentConfirmDialog(false)}
        />
      )}
      {viewDeleteInvoiceDocumentsConfirmDialog && (
        <ConfirmDialog
          message={interface_translate['Delete?'][language]}
          onConfirm={() => {
            setViewDeleteInvoiceDocumentsConfirmDialog(false);
            setInvoiceDocumentsIdForDelete((prevData) => {
              const new_data = [
                ...prevData,
                selectedInvoiceDocumentsIdToDelete,
              ];
              return new_data;
            });
            setSelectedInvoiceDocumentsIdToDelete(null);
            setModelData({
              ...modelData,
              invoice_documents: modelData.invoice_documents.filter(
                (model) => model.id !== selectedInvoiceDocumentsIdToDelete
              ),
            });
          }}
          onCancel={() => setViewDeleteInvoiceDocumentsConfirmDialog(false)}
        />
      )}
      <div className={styles.controls}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await update_customer_cargo(
              params.id,
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(
                interface_translate['Customer cargo updated'][language]
              );
              resetGlobalError();
              setErrors({});
              popPage();
              const previousPage = getCurrentPage();
              navigate(previousPage ? previousPage : '..', {
                relative: 'path',
              });
            }
          }}
        >
          {interface_translate['Update'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('../..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      {modelData.hasOwnProperty('responsible') && (
        <>
          <div className="inputData">
            <OpeningBlock
              title={interface_translate['About customer cargo'][language]}
              open={true}
            >
              {attrsForEdit.has('shipment_id') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Shipment'][language]}
                    </div>
                    <Autocomplete
                      value={modelData.shipment_id}
                      onChange={(e, newElement) => {
                        setModelData({
                          ...modelData,
                          shipment_id: newElement,
                        });
                      }}
                      options={shipmentElementsForSelect}
                      getOptionLabel={(model) => model.toString()}
                      onInputChange={(e, value) => {
                        setShipmentInputValue(value);
                        setShipmentElementsForSelect([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={interface_translate['Shipment'][language]}
                          size="small"
                        />
                      )}
                      loading={shipmentElementsForSelect.length == 0}
                    />
                  </div>
                  {errors['shipment_id'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['shipment_id'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('status_translate') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Status'][language]}
                    </div>
                    <select
                      value={modelData.status}
                      onChange={(e) => {
                        setModelData({ ...modelData, status: e.target.value });
                      }}
                    >
                      {customerCargoStatuses.map((status, id) => (
                        <option
                          key={id}
                          value={status}
                        >
                          {statuses_translate[status][language]}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors['status'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['status'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('weight_in_kg') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Weight in kg'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.weight_in_kg}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          weight_in_kg: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['weight_in_kg'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['weight_in_kg'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('volume_in_m3') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Volume in м³'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.volume_in_m3}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          volume_in_m3: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['volume_in_m3'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['volume_in_m3'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('content') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Content'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.content}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          content: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['content'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['content'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('storehouse_name') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Storehouse'][language]}
                    </div>
                    <Autocomplete
                      value={storehouse}
                      onChange={async (e, newElement) => {
                        setStorehouse(newElement);
                        if (!newElement) {
                          setModelData({ ...modelData, storage_cell: null });
                          return;
                        }
                        const filters = { storehouse_id: newElement.id };
                        const new_storage_cells = await get_storage_cells(
                          filters
                        );
                        setStorageCellElementsForSelect(new_storage_cells);
                      }}
                      options={storehouseElementsForSelect}
                      getOptionLabel={(model) => model.name}
                      onInputChange={(e, value) => {
                        setStorehouseInputValue(value);
                        setStorehouseElementsForSelect([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={interface_translate['Storehouse'][language]}
                          size="small"
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      loading={storehouseElementsForSelect.length == 0}
                    />
                  </div>
                </div>
              )}
              {attrsForEdit.has('storage_cell_name') && (
                <div>
                  {storehouse && (
                    <div className="openingBlockAttr">
                      <div className="openingBlockAttrName">
                        {interface_translate['Storage cell'][language]}
                      </div>
                      <Autocomplete
                        value={modelData.storage_cell}
                        onChange={(e, newElement) => {
                          setModelData({
                            ...modelData,
                            storage_cell: newElement,
                          });
                        }}
                        options={storageCellElementsForSelect}
                        getOptionLabel={(model) =>
                          `${model.shelving.name}${model.number}`
                        }
                        onInputChange={(e, value) => {
                          setStorageCellInputValue(value);
                          setStorageCellElementsForSelect([]);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={
                              interface_translate['Storage cell'][language]
                            }
                            size="small"
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        loading={storageCellElementsForSelect.length == 0}
                      />
                    </div>
                  )}
                  {errors['storage_cell_id'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['storage_cell_id'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('responsible') && (
                <div>
                  <ResponsibleListInput
                    containerClass={'openingBlockAttr'}
                    attrNameClass={'openingBlockAttrName'}
                    responsible={responsible}
                    setSelectedElement={(value) => {
                      setResponsible((prevData) => {
                        return { ...prevData, ...value };
                      });
                    }}
                    deleteSelectedElement={(id) => {
                      setResponsible((prevElements) => {
                        delete prevElements[id];
                        return { ...prevElements };
                      });
                    }}
                    initialElements={modelData.responsible}
                    defaultElements={defUsers}
                  />
                  {errors['responsible_ids'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['responsible_ids'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('co_executors') && (
                <div>
                  <CoExecutorsInput
                    containerClass={'openingBlockAttr'}
                    attrNameClass={'openingBlockAttrName'}
                    coExecutors={coExecutors}
                    setSelectedElement={(value) => {
                      setCoExecutors((prevData) => {
                        return { ...prevData, ...value };
                      });
                    }}
                    deleteSelectedElement={(id) => {
                      setCoExecutors((prevElements) => {
                        delete prevElements[id];
                        return { ...prevElements };
                      });
                    }}
                    initialElements={modelData.coExecutors}
                    defaultElements={defUsers}
                  />
                  {errors['co_executors_ids'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['co_executors_ids'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('media_content') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Media content'][language]}
                      <p>{`${interface_translate['Allowed formats'][language]}: "jpg", "png", "webp"`}</p>
                      <p>{`"heic", "mp4", "mov", "pdf", "docx", "xlsx"`}</p>
                    </div>
                    <div>
                      <div className="listDropDomnMedia">
                        {modelData.media_content.map((image, id) => {
                          let { mediaBlock, preViewImg } =
                            getPrevViewImgAndMediaBlockByImageObj(
                              image,
                              language
                            );
                          return (
                            <div className="DropDownMediaBlockDelete">
                              <DropDownMediaBlock
                                key={id}
                                preViewImg={preViewImg}
                                mediaBlock={mediaBlock}
                              />
                              <button
                                onClick={() => {
                                  setSelectedMediaContentIdToDelete(image.id);
                                  setViewDeleteMediaContentConfirmDialog(true);
                                }}
                              >
                                {interface_translate['Delete'][language]}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                      <ListAttrMedia
                        setSelectedElement={(value) => {
                          setMediaContentForCreate((prevData) => {
                            return { ...prevData, ...value };
                          });
                        }}
                        deleteSelectedElement={(id) => {
                          setMediaContentForCreate((prevElements) => {
                            delete prevElements[id];
                            return { ...prevElements };
                          });
                        }}
                      />
                    </div>
                  </div>
                  {errors['media_content'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['media_content'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('invoice_documents') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Invoice documents'][language]}
                      <p>{`${interface_translate['Allowed formats'][language]}: "jpg", "png", "webp"`}</p>
                      <p>{`"heic", "mp4", "mov", "pdf", "docx", "xlsx"`}</p>
                    </div>
                    <div>
                      <div className="listDropDomnMedia">
                        {modelData.invoice_documents.map((model, id) => {
                          let { mediaBlock, preViewImg } =
                            getPrevViewImgAndMediaBlockByImageObj(
                              model,
                              language
                            );
                          return (
                            <div className="DropDownMediaBlockDelete">
                              <DropDownMediaBlock
                                key={id}
                                preViewImg={preViewImg}
                                mediaBlock={mediaBlock}
                              />
                              <button
                                onClick={() => {
                                  setSelectedMediaContentIdToDelete(model.id);
                                  setViewDeleteMediaContentConfirmDialog(true);
                                }}
                              >
                                {interface_translate['Delete'][language]}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                      <ListAttrMedia
                        setSelectedElement={(value) => {
                          setMediaContentForCreate((prevData) => {
                            return { ...prevData, ...value };
                          });
                        }}
                        deleteSelectedElement={(id) => {
                          setMediaContentForCreate((prevElements) => {
                            delete prevElements[id];
                            return { ...prevElements };
                          });
                        }}
                      />
                    </div>
                  </div>
                  {errors['invoice_documents'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['invoice_documents'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
            </OpeningBlock>
          </div>
        </>
      )}
      {!modelData && <div>Пусто</div>}
    </div>
  );
};

export default CustomerCargoEditPage;
