import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import OpeningBlock from '../../components/OpeningBlock';
import DropDownMediaBlock from '../../components/DropDownMediaBlock';
import roleAccess from '../../roleAccess';
import orderService from '../../services/order';
import userService from '../../services/user';
import productService from '../../services/product';
import customerCargoProductService from '../../services/customer_cargo_product';
import SelectStorageCellBtn from '../../components/SelectStorageCellBtn.jsx';
import { interface_translate } from '../../texts/interface';
import { statuses_translate } from '../../texts/statuses';
import { types } from '../../texts/types';
import styles from './ProductPage.module.css';
import '../shared.css';
import deliveryProductService from '../../services/delivery_product.js';
import DeliveryCheckOpeningBlock from '../../components/openingBlocks/DeliveryCheck.jsx';
import CustomerCargoCheckOpeningBlock from '../../components/openingBlocks/CustomerCargoCheck.jsx';

async function get_product(id) {
  const product = await productService.get(id);
  return product;
}

async function get_delivery_products(filters) {
  const deliveryProductPaginated = await deliveryProductService.get_by_filters(
    filters
  );
  return deliveryProductPaginated.data;
}

async function get_customer_cargo_products(filters) {
  const customerCargoProductPaginated =
    await customerCargoProductService.get_by_filters(filters);
  return customerCargoProductPaginated.data;
}

async function get_user(id) {
  const user = await userService.get(id);
  return user;
}

async function update_product_storage_cell(
  id,
  storage_cell_id,
  setErrors,
  setGlobalError,
  resetGlobalMsg
) {
  const data = { storage_cell_id: storage_cell_id };
  const result = await productService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg
  );
  return result;
}

async function get_product_for_display(product, language) {
  product.supplier_name = product.supplier?.name;
  product.status_translate = statuses_translate[product.status][language];
  product.qty_with_unit = `${product.qty} ${
    types[product.measurement_unit][language]
  }`;
  product.storehouse_id = product.storage_cell
    ? product.storage_cell.shelving.storehouse.id
    : '';
  product.storehouse_name = product.storage_cell
    ? product.storage_cell.shelving.storehouse.name
    : '';
  product.storage_cell_name = product.storage_cell
    ? `${product.storage_cell.shelving.name}${product.storage_cell.number}`
    : '';
  const order = await orderService.get(product.order_id);
  product.order = order;
  product.client_name = order.client.name;

  return product;
}

const ProductPage = (props) => {
  const { language, setGlobalError, resetGlobalMsg, userRole } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [product, setProduct] = useState();
  const [deliveryProducts, setDeliveryProducts] = useState([]);
  const [customerCargoProducts, setCustomerCargoProducts] = useState([]);
  const [selectStorageCellErrors, setSelectStorageCellErrors] = useState({});
  const [attrsForRead, setAttrsForRead] = useState(new Set());
  console.log(customerCargoProducts);
  useEffect(() => {
    const req = async () => {};

    requestGetProduct();
    req();
    pushPage(location.pathname);

    const attrs_for_read = new Set(
      roleAccess[userRole].fieldRestrictions.Products?.read
    );
    setAttrsForRead(attrs_for_read);
  }, []);

  useEffect(() => {
    if (!product) return;
    requestGetDeliveryProducts();
    requestGetCustomerCargoProducts();
  }, [product]);

  const updateStorageCell = async (storage_cell) => {
    if (!storage_cell) return;
    const result = await update_product_storage_cell(
      product.id,
      storage_cell.id,
      setSelectStorageCellErrors,
      setGlobalError,
      resetGlobalMsg
    );
    if (result) {
      requestGetProduct();
    }
    return result;
  };

  const requestGetProduct = async () => {
    let product_resp = await get_product(params.id);
    if (!product_resp) {
      return;
    }
    const responsible = [];
    for (const user_id of product_resp.responsible_ids) {
      const user = await get_user(user_id);
      responsible.push(user);
    }
    product_resp.responsible = responsible;
    const co_executors = [];
    for (const user_id of product_resp.co_executors_ids) {
      const user = await get_user(user_id);
      co_executors.push(user);
    }
    product_resp.co_executors = co_executors;
    product_resp = await get_product_for_display(product_resp, language);
    setProduct(product_resp);
  };

  const requestGetDeliveryProducts = async () => {
    const filters = { product_id: product.id };
    const delivery_product_resp = await get_delivery_products(filters);
    setDeliveryProducts(delivery_product_resp);
  };

  const requestGetCustomerCargoProducts = async () => {
    const filters = { product_id: product.id };
    const customer_cargo_products_resp = await get_customer_cargo_products(
      filters
    );
    setCustomerCargoProducts(customer_cargo_products_resp);
  };

  if (!product) return;

  return (
    <div className={`pageViewModel`}>
      <div className="controls">
        {roleAccess[userRole].permissions.Products.edit && (
          <button
            onClick={() => {
              navigate('edit', {
                state: { from: location },
              });
            }}
          >
            {interface_translate['Edit'][language]}
          </button>
        )}
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        {roleAccess[userRole].permissions.Products.assign_storage_cell && (
          <SelectStorageCellBtn
            btnName={interface_translate['Assign storage cell'][language]}
            setSelectedStorageCell={async (storage_cell) => {
              const result = await updateStorageCell(storage_cell);
              return result;
            }}
            errors={selectStorageCellErrors}
          />
        )}
      </div>

      <div className="viewData">
        {attrsForRead.has('name') && (
          <div className="viewName">
            <div className="openingBlockAttrName">
              {interface_translate['Name'][language]}
            </div>
            <samp>{product.name}</samp>
          </div>
        )}
        <div className="horizontallyContainer">
          <OpeningBlock
            title={interface_translate['About product'][language]}
            open={true}
          >
            {attrsForRead.has('id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['product id'][language]}
                </div>
                <span>{product.id}</span>
              </div>
            )}
            {attrsForRead.has('description') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Description'][language]}
                </div>
                <span>{product.description}</span>
              </div>
            )}
            {attrsForRead.has('order_id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Order'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(`/orders/${product.order.id}`, {
                      state: { from: location },
                    })
                  }
                >
                  {product.order.id}
                </span>
              </div>
            )}
            {attrsForRead.has('status') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Status'][language]}
                </div>
                <span>{statuses_translate[product.status][language]}</span>
              </div>
            )}
            {attrsForRead.has('link') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Link'][language]}
                </div>
                <span>{product.link}</span>
              </div>
            )}
            {attrsForRead.has('qty') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Qty'][language]}
                </div>
                <span>{product.qty_with_unit}</span>
              </div>
            )}
            {attrsForRead.has('price') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Price'][language]}
                </div>
                <span>{product.price}</span>
              </div>
            )}
            {attrsForRead.has('currency') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Currency'][language]}
                </div>
                <span>{product.currency}</span>
              </div>
            )}
            {attrsForRead.has('supplier_name') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Supplier'][language]}
                </div>
                {product.supplier && (
                  <span
                    className="linkToModel"
                    onClick={() => {
                      navigate(`/counterparties/${product.supplier.id}`, {
                        state: { from: location },
                      });
                    }}
                  >
                    {product.supplier?.name}
                  </span>
                )}
              </div>
            )}
            {attrsForRead.has('delivery_id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Delivery'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(`/deliverys/${product.delivery_id}`, {
                      state: { from: location },
                    })
                  }
                >
                  {product.delivery_id}
                </span>
              </div>
            )}
            {attrsForRead.has('shipment_id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Shipment'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(`/shipments/${product.shipment_id}`, {
                      state: { from: location },
                    })
                  }
                >
                  {product.shipment_id}
                </span>
              </div>
            )}
            {attrsForRead.has('customer_cargo_id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Customer cargo'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(`/customer_cargo/${product.customer_cargo_id}`, {
                      state: { from: location },
                    })
                  }
                >
                  {product.customer_cargo_id}
                </span>
              </div>
            )}
            {attrsForRead.has('storehouse_name') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Storehouse'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(`/storehouses/${product.storehouse_id}`, {
                      state: { from: location },
                    })
                  }
                >
                  {product.storehouse_name}
                </span>
              </div>
            )}
            {attrsForRead.has('storage_cell_name') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Storage cell'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(`/storage_cells/${product.storage_cell.id}`, {
                      state: { from: location },
                    })
                  }
                >
                  {product.storage_cell_name}
                </span>
              </div>
            )}
            {attrsForRead.has('responsible') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Responsible'][language]}
                </div>
                <div className="listModels">
                  {product.responsible.map((model) => {
                    return (
                      <div
                        className="modelBlock"
                        key={model.id}
                      >
                        {`${model.surname} ${model.name} ${model.patronymic}`}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {attrsForRead.has('co_executors') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Co executors'][language]}
                </div>
                <div className="listModels">
                  {product.co_executors.map((model) => {
                    return (
                      <div
                        className="modelBlock"
                        key={model.id}
                      >
                        {`${model.surname} ${model.name} ${model.patronymic}`}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {attrsForRead.has('images') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Media'][language]}
                </div>
                <div className="listDropDomnMedia">
                  {product.images.map((model, id) => {
                    let mediaBlock;
                    let preViewImg;
                    if (model.file_type === 'photo') {
                      mediaBlock = (
                        <img
                          src={model.url}
                          alt="image"
                        />
                      );
                      preViewImg = mediaBlock;
                    } else if (model.file_type === 'video') {
                      mediaBlock = (
                        <video
                          src={model.url}
                          alt="video"
                          controls
                          style={{ maxWidth: '100%' }}
                        />
                      );
                      preViewImg = (
                        <p>{interface_translate['Video'][language]}</p>
                      );
                    }
                    return (
                      <DropDownMediaBlock
                        key={id}
                        preViewImg={preViewImg}
                        mediaBlock={mediaBlock}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </OpeningBlock>
          <OpeningBlock title={'Кол-ва'}>
            {attrsForRead.has('qty_ordered_products') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Qty ordered products'][language]}
                </div>
                <span>{product.qty_ordered_products}</span>
              </div>
            )}
            {attrsForRead.has('qty_products_in_china') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Qty products in china'][language]}
                </div>
                <span>{product.qty_products_in_china}</span>
              </div>
            )}
            {attrsForRead.has('qty_shipped_products') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Qty shipped products'][language]}
                </div>
                <span>{product.qty_shipped_products}</span>
              </div>
            )}
            {attrsForRead.has('qty_products_in_russia') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Qty products in russia'][language]}
                </div>
                <span>{product.qty_products_in_russia}</span>
              </div>
            )}
            {attrsForRead.has('qty_issued_products') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Qty issued roducts'][language]}
                </div>
                <span>{product.qty_issued_products}</span>
              </div>
            )}
          </OpeningBlock>
          <div className={styles.deliveryCheckBlocks}>
            <div className={styles.deliveryCheckBlocksColumn}>
              {deliveryProducts
                .slice(0, Math.ceil(deliveryProducts.length / 2))
                .map((deliveryProduct, id) => (
                  <DeliveryCheckOpeningBlock
                    key={id}
                    id={id}
                    deliveryProduct={deliveryProduct}
                  />
                ))}
            </div>
            <div className={styles.deliveryCheckBlocksColumn}>
              {deliveryProducts
                .slice(Math.ceil(deliveryProducts.length / 2))
                .map((deliveryProduct, id) => (
                  <DeliveryCheckOpeningBlock
                    key={id}
                    id={id}
                    deliveryProduct={deliveryProduct}
                  />
                ))}
            </div>
          </div>
          <div className={styles.deliveryCheckBlocks}>
            <div className={styles.deliveryCheckBlocksColumn}>
              {customerCargoProducts
                .slice(0, Math.ceil(customerCargoProducts.length / 2))
                .map((deliveryProduct, id) => (
                  <CustomerCargoCheckOpeningBlock
                    id={id}
                    customerCargoProduct={deliveryProduct}
                  />
                ))}
            </div>
            <div className={styles.deliveryCheckBlocksColumn}>
              {customerCargoProducts
                .slice(Math.ceil(customerCargoProducts.length / 2))
                .map((deliveryProduct, id) => (
                  <CustomerCargoCheckOpeningBlock
                    id={id}
                    customerCargoProduct={deliveryProduct}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      {!product && <div>Пусто</div>}
    </div>
  );
};

export default ProductPage;
