import { useState, React, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import { api_error_translate } from '../../texts/error';
import { statuses_translate } from '../../texts/statuses.js';
import { interface_translate } from '../../texts/interface';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import ConfirmDialog from '../../components/ConfirmDialog';
import ListAttrMedia from '../../components/ListAttrMedia';
import DropDownMediaBlock from '../../components/DropDownMediaBlock';
import roleAccess from '../../roleAccess.js';
import unidentifiedCargoService from '../../services/unidentified_cargo';
import userService from '../../services/user';
import storehouseService from '../../services/storehouse';
import storageCellService from '../../services/storage_cell';
import styles from './UnidentifiedCargoEditPage.module.css';
import '../shared.css';
import ResponsibleListInput from '../../components/input/ResponsibleListInput.jsx';

async function get_unidentified_cargo(id) {
  const unidentifiedCargo = await unidentifiedCargoService.get(id);
  return unidentifiedCargo;
}

async function get_unidentified_cargo_statuses() {
  const statuses = await unidentifiedCargoService.get_statuses();
  return statuses;
}

async function update_unidentified_cargo(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await unidentifiedCargoService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_users(filters = {}, size = 50) {
  const usersPaginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return usersPaginated.data;
}

async function get_user(id) {
  const user = await userService.get(id);
  return user;
}

async function get_storehouses(filters = {}, size = 50) {
  const storehousesPaginated = await storehouseService.get_by_filters(filters, {
    size: size,
  });
  return storehousesPaginated.data;
}

async function get_storage_cells(filters = {}, size = 50) {
  const storageCellsPaginated = await storageCellService.get_by_filters(
    filters,
    {
      size: size,
    }
  );
  return storageCellsPaginated.data;
}

const UnidentifiedCargoEditPage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState();
  const [oldUnidentifiedCargo, setOldUnidentifiedCargo] = useState();
  const [attrsForEdit, setAttrsForEdit] = useState(new Set());
  const [defUsers, setDefUsers] = useState(null);
  const [unidentifiedCargoStatuses, setUnidentifiedCargoStatuses] = useState(
    []
  );
  const [unidentifiedCargo, setUnidentifiedCargo] = useState();
  const [storehouse, setStorehouse] = useState(null);
  const [storehouseInputValue, setStorehouseInputValue] = useState('');
  const [storehouseElementsForSelect, setStorehouseElementsForSelect] =
    useState([]);
  const [storageCellInputValue, setStorageCellInputValue] = useState('');
  const [storageCellElementsForSelect, setStorageCellElementsForSelect] =
    useState([]);
  const [mediaContentAcceptanceForCreate, setMediaContentAcceptanceForCreate] =
    useState({});
  const [
    selectedMediaContentAcceptanceIdToDelete,
    setSelectedMediaContentAcceptanceIdToDelete,
  ] = useState(null);
  const [
    viewDeleteMediaContentAcceptanceConfirmDialog,
    setViewDeleteMediaContentAcceptanceConfirmDialog,
  ] = useState(false);
  const [
    mediaContentAcceptanceIdForDelete,
    setMediaContentAcceptanceIdForDelete,
  ] = useState([]);
  const [responsible, setResponsible] = useState({});

  useEffect(() => {
    const req = async () => {
      const unidentified_cargo_resp = await get_unidentified_cargo(params.id);
      const responsible = [];
      for (const user_id of unidentified_cargo_resp.responsible_ids) {
        const user = await get_user(user_id);
        responsible.push(user);
      }
      unidentified_cargo_resp.responsible = responsible;

      if (!unidentified_cargo_resp.comment)
        unidentified_cargo_resp.comment = '';

      const unidentifiedCargoStatuses = await get_unidentified_cargo_statuses();
      setUnidentifiedCargoStatuses(unidentifiedCargoStatuses);

      const unidentified_cargo = unidentified_cargo_resp;
      setUnidentifiedCargo(unidentified_cargo);
      setModelData(unidentified_cargo_resp);
      setOldUnidentifiedCargo({ ...unidentified_cargo_resp });

      if (unidentified_cargo_resp.storage_cell) {
        setStorehouse(unidentified_cargo_resp.storage_cell.shelving.storehouse);
      }

      const users = await get_users();
      setDefUsers(users);
    };
    req();
    pushPage(location.pathname);
    const attrsForEdit = new Set(
      roleAccess[userRole].fieldRestrictions['Unidentified cargo list']?.edit
    );
    setAttrsForEdit(attrsForEdit);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (storehouseInputValue) filters['name'] = storehouseInputValue;
      const new_elements = await get_storehouses(filters);
      setStorehouseElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storehouseInputValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (!storehouse) return;
      let filters = { storehouse_id: storehouse.id };
      if (storageCellInputValue)
        filters['storage_cell_name'] = storageCellInputValue;
      const new_elements = await get_storage_cells(filters);
      setStorageCellElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storageCellInputValue]);

  const getDataFromAttr = () => {
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    for (let id of responsible_ids) {
      if (id === null) {
        setErrors({
          responsible_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const data = {
      status: modelData.status,
      comment: modelData.comment,
      invoice_number: modelData.invoice_number,
      responsible_ids: responsible_ids,
      media_content_acceptance_for_create: Object.values(
        mediaContentAcceptanceForCreate
      ),
      media_content_acceptance_id_for_delete: mediaContentAcceptanceIdForDelete,
    };
    if (modelData.storage_cell !== oldUnidentifiedCargo.storage_cell) {
      data.storage_cell_id = modelData.storage_cell
        ? modelData.storage_cell.id
        : null;
    }
    return data;
  };

  if (!modelData) return;

  return (
    <div className="pageUpdateModel">
      {viewDeleteMediaContentAcceptanceConfirmDialog && (
        <ConfirmDialog
          message={interface_translate['Delete?'][language]}
          onConfirm={() => {
            setViewDeleteMediaContentAcceptanceConfirmDialog(false);
            setMediaContentAcceptanceIdForDelete((prevData) => {
              const new_data = [
                ...prevData,
                selectedMediaContentAcceptanceIdToDelete,
              ];
              return new_data;
            });
            setSelectedMediaContentAcceptanceIdToDelete(null);
            setModelData({
              ...modelData,
              media_content_acceptance:
                modelData.media_content_acceptance.filter(
                  (model) =>
                    model.id !== selectedMediaContentAcceptanceIdToDelete
                ),
            });
          }}
          onCancel={() =>
            setViewDeleteMediaContentAcceptanceConfirmDialog(false)
          }
        />
      )}
      <div className={styles.controls}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await update_unidentified_cargo(
              params.id,
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(
                interface_translate['Unidentified cargo updated'][language]
              );
              resetGlobalError();
              setErrors({});
              popPage();
              const previousPage = getCurrentPage();
              navigate(previousPage ? previousPage : '..', {
                relative: 'path',
              });
            }
          }}
        >
          {interface_translate['Update'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('../..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      {modelData.hasOwnProperty('responsible') && (
        <>
          <div className="inputData">
            <OpeningBlock
              title={interface_translate['About unidentified cargo'][language]}
              open={true}
            >
              {attrsForEdit.has('comment') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Comment'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.comment}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          comment: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['comment'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['comment'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('invoice_number') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Invoice number'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.invoice_number}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          invoice_number: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['invoice_number'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['invoice_number'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('status_translate') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Status'][language]}
                    </div>
                    <select
                      value={modelData.status}
                      onChange={(e) => {
                        setModelData({ ...modelData, status: e.target.value });
                      }}
                    >
                      {unidentifiedCargoStatuses.map((status, id) => (
                        <option
                          key={id}
                          value={status}
                        >
                          {statuses_translate[status][language]}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors['status'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['status'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('storehouse_name') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Storehouse'][language]}
                    </div>
                    <Autocomplete
                      value={storehouse}
                      onChange={async (e, newElement) => {
                        setStorehouse(newElement);
                        if (!newElement) {
                          setModelData({ ...modelData, storage_cell: null });
                          return;
                        }
                        const filters = { storehouse_id: newElement.id };
                        const new_storage_cells = await get_storage_cells(
                          filters
                        );
                        setStorageCellElementsForSelect(new_storage_cells);
                      }}
                      options={storehouseElementsForSelect}
                      getOptionLabel={(model) => model.name}
                      onInputChange={(e, value) => {
                        setStorehouseInputValue(value);
                        setStorehouseElementsForSelect([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={interface_translate['Storehouse'][language]}
                          size="small"
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      loading={storageCellElementsForSelect.length == 0}
                    />
                  </div>
                </div>
              )}
              {attrsForEdit.has('storage_cell_name') && (
                <div>
                  {storehouse && (
                    <div className="openingBlockAttr">
                      <div className="openingBlockAttrName">
                        {interface_translate['Storage cell'][language]}
                      </div>
                      <Autocomplete
                        value={modelData.storage_cell}
                        onChange={(e, newElement) => {
                          setModelData({
                            ...modelData,
                            storage_cell: newElement,
                          });
                        }}
                        options={storageCellElementsForSelect}
                        getOptionLabel={(model) =>
                          `${model.shelving.name}${model.number}`
                        }
                        onInputChange={(e, value) => {
                          setStorageCellInputValue(value);
                          setStorageCellElementsForSelect([]);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={
                              interface_translate['Storage cell'][language]
                            }
                            size="small"
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        loading={storageCellElementsForSelect.length == 0}
                      />
                    </div>
                  )}
                  {errors['storage_cell_id'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['storage_cell_id'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('media_content_acceptance') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Media'][language]}
                      <p>{`${interface_translate['Allowed formats'][language]}: "jpg", "png", "webp"`}</p>
                      <p>{`"heic", "mp4", "mov", "pdf", "docx", "xlsx"`}</p>
                    </div>
                    <div>
                      <div className="listDropDomnMedia">
                        {modelData.media_content_acceptance.map((model, id) => {
                          let mediaBlock;
                          let preViewImg;
                          if (model.file_type === 'photo') {
                            mediaBlock = (
                              <img
                                src={model.url}
                                alt="image"
                              />
                            );
                            preViewImg = mediaBlock;
                          } else if (model.file_type === 'video') {
                            mediaBlock = (
                              <video
                                src={model.url}
                                alt="video"
                                controls
                                style={{ maxWidth: '100%' }}
                              />
                            );
                            preViewImg = (
                              <p>{interface_translate['Vido'][language]}</p>
                            );
                          }
                          return (
                            <div className="DropDownMediaBlockDelete">
                              <DropDownMediaBlock
                                key={id}
                                preViewImg={preViewImg}
                                mediaBlock={mediaBlock}
                              />
                              <button
                                onClick={() => {
                                  setSelectedMediaContentAcceptanceIdToDelete(
                                    model.id
                                  );
                                  setViewDeleteMediaContentAcceptanceConfirmDialog(
                                    true
                                  );
                                }}
                              >
                                {interface_translate['Delete'][language]}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                      <ListAttrMedia
                        setSelectedElement={(value) => {
                          setMediaContentAcceptanceForCreate((prevData) => {
                            return { ...prevData, ...value };
                          });
                        }}
                        deleteSelectedElement={(id) => {
                          setMediaContentAcceptanceForCreate((prevElements) => {
                            delete prevElements[id];
                            return { ...prevElements };
                          });
                        }}
                      />
                    </div>
                  </div>
                  {errors['media_content_acceptance'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[
                          errors['media_content_acceptance'][0]
                        ][language]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('responsible') && (
                <div>
                  <ResponsibleListInput
                    containerClass={'openingBlockAttr'}
                    attrNameClass={'openingBlockAttrName'}
                    responsible={responsible}
                    setSelectedElement={(value) => {
                      setResponsible((prevData) => {
                        return { ...prevData, ...value };
                      });
                    }}
                    deleteSelectedElement={(id) => {
                      setResponsible((prevElements) => {
                        delete prevElements[id];
                        return { ...prevElements };
                      });
                    }}
                    initialElements={modelData.responsible}
                    defaultElements={defUsers}
                  />
                  {errors['responsible_ids'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['responsible_ids'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
            </OpeningBlock>
          </div>
        </>
      )}
      {!modelData && <div>Пусто</div>}
    </div>
  );
};

export default UnidentifiedCargoEditPage;
