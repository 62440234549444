import useCommon from '../../hooks/useCommon';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiRequester from '../../ApiRequester';
import styles from './shered.module.css';
import { interface_translate } from '../../texts/interface';

async function register(data, setGlobalError) {
  const jsonData = JSON.stringify(data);
  try {
    const response = await apiRequester.post('/users', jsonData);
    return true;
  } catch (err) {
    if (err.response.status === 400) {
      const err_msg = err.response.data.errors;
    } else {
      console.error(`Could not fetch data: ${err}`);
    }
  }
}

const RegisterForm = (props) => {
  const { language, setGlobalError, setGlobalMsg } = useCommon();
  const { isHidden } = props;
  const [email, setEmail] = useState('');
  const [linkToMessenger, setLinkToMessenger] = useState('');
  const [pass, setPass] = useState('');
  const navigate = useNavigate();

  const onClickRegister = async (event) => {
    event.preventDefault();

    const data = {
      email: email,
      link_to_messenger: `http://test${linkToMessenger}.com`,
      password: pass,
    };
    const result = await register(data, setGlobalError);
    if (result) {
      setGlobalMsg(interface_translate['You are registered'][language]);
      navigate('/', { replace: true });
    }
  };

  return (
    <form
      id="registerForm"
      className={`${styles.form} ${isHidden ? 'hide' : ''}`}
      onSubmit={onClickRegister}
    >
      <h2>{interface_translate['Register'][language]}</h2>
      <div className={styles.formFields}>
        <div className={styles.formField}>
          <label htmlFor="new_email">
            {interface_translate['Email'][language]}:
          </label>
          <input
            type="text"
            id="new_email"
            name="new_email"
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className={styles.formField}>
          <label htmlFor="link_to_messenger">
            {interface_translate['Link to messenger'][language]}:
          </label>
          <input
            type="text"
            id="link_to_messenger"
            name="link_to_messenger"
            required
            value={linkToMessenger}
            onChange={(e) => {
              setLinkToMessenger(e.target.value);
            }}
          />
        </div>
        <div className={styles.formField}>
          <label htmlFor="new_password">
            {interface_translate['Password'][language]}:
          </label>
          <input
            type="password"
            id="new_password"
            name="new_password"
            required
            value={pass}
            onChange={(e) => {
              setPass(e.target.value);
            }}
          />
        </div>
      </div>
      <button type="submit">{interface_translate['Register'][language]}</button>
    </form>
  );
};

export default RegisterForm;
