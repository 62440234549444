import apiRequester from '../ApiRequester';
import { model_names } from '../texts/model_names.js';
import { api_error_translate } from '../texts/error.js';

class CounterpartyService {
  async create(data, setErrors, setGlobalError, resetGlobalMsg, language) {
    const jsonData = JSON.stringify(data);
    try {
      await apiRequester.post('/counterparties', jsonData);
      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const errors = err.response.data.errors;
          if (detail_msg === 'counterparty already exists') {
            resetGlobalMsg();
            setGlobalError(api_error_translate[detail_msg][language]);
          }
          setErrors({ ...errors });
          resetGlobalMsg();
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
    }
  }

  async get(id) {
    try {
      const response = await apiRequester.get(`/counterparties/${id}`);
      const counterparty = response.data;
      return counterparty;
    } catch (err) {
      if (err.response.status !== 200) {
        throw new Error(`HTTP error: ${err.response.status}`);
      }
      console.error(`Could not fetch data: ${err}`);
    }
  }

  async get_by_filters(filterData, paginationSettings = { size: 20 }) {
    const jsonData = JSON.stringify(filterData);
    try {
      const response = await apiRequester.post(
        `/counterparties/filters`,
        jsonData,
        {
          params: paginationSettings,
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }

  async update(id, data, setErrors, setGlobalError, resetGlobalMsg, language) {
    const jsonData = JSON.stringify(data);
    try {
      await apiRequester.patch(`/counterparties/${id}`, jsonData);
      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (detail_msg === 'counterparty already exists') {
            setErrors({});
            setGlobalError(api_error_translate[detail_msg][language]);
            resetGlobalMsg();
          }
          setErrors({ ...err_msg });
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
    }
  }

  async delete(id, setGlobalError, language) {
    try {
      const response = await apiRequester.delete(`/counterparties/${id}`);
      return response.data;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (err_msg === 'foreign connection') {
            let msg = `${api_error_translate['linked to another model'][language]}: ${model_names[detail_msg][language]}`;
            setGlobalError(msg);
          }
        }
        return;
      }
      console.error(`Could not fetch data: ${err}`);
      throw err;
    }
  }
}

const counterpartyService = new CounterpartyService();
export default counterpartyService;
