import { useState, React, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import { api_error_translate } from '../../texts/error';
import { statuses_translate } from '../../texts/statuses.js';
import { types } from '../../texts/types.js';
import { interface_translate } from '../../texts/interface';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import ConfirmDialog from '../../components/ConfirmDialog';
import ListAttrMedia from '../../components/ListAttrMedia';
import DropDownMediaBlock from '../../components/DropDownMediaBlock';
import roleAccess from '../../roleAccess.js';
import orderService from '../../services/order.js';
import productService from '../../services/product.js';
import shipmentService from '../../services/shipment.js';
import userService from '../../services/user.js';
import storehouseService from '../../services/storehouse.js';
import storageCellService from '../../services/storage_cell.js';
import styles from './ShipmentEditPage.module.css';
import '../shared.css';
import ResponsibleListInput from '../../components/input/ResponsibleListInput.jsx';
import CoExecutorsInput from '../../components/input/CoExecutorsInput.jsx';
import ProductsWithQtyInput from '../../components/input/ProductsWithQtyInput.jsx';

async function update_shipment(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await shipmentService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_shipment(id) {
  const shipment = await shipmentService.get(id);
  return shipment;
}

async function get_shipment_packing() {
  const packing = await shipmentService.get_packing();
  return packing;
}

async function get_shipment_statuses() {
  const statuses = await shipmentService.get_statuses();
  return statuses;
}

async function get_users(filters = {}, size = 50) {
  const usersPaginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return usersPaginated.data;
}

async function get_storehouses(filters = {}, size = 50) {
  const storehousesPaginated = await storehouseService.get_by_filters(filters, {
    size: size,
  });
  return storehousesPaginated.data;
}

async function get_storage_cells(filters = {}, size = 50) {
  const storareCellPaginated = await storageCellService.get_by_filters(
    filters,
    { size: size }
  );
  return storareCellPaginated.data;
}

async function get_user(id) {
  const user = await userService.get(id);
  return user;
}

async function get_orders(filters = {}, size = 50) {
  const orders_paginated = await orderService.get_by_filters(filters, {
    size: size,
  });
  return orders_paginated.data;
}

async function get_products(filters = {}, size = 50) {
  filters = {
    ...filters,
  };
  const productsPaginated = await productService.get_by_filters(filters, {
    size: size,
  });
  return productsPaginated.data;
}

const ShipmentEditPage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState();
  const [oldShipment, setOldShipment] = useState();
  const [attrsForEdit, setAttrsForEdit] = useState(new Set());
  const [defUsers, setDefUsers] = useState(null);
  const [packing, setPacking] = useState([]);
  const [shipmentStatuses, setShipmentStatuses] = useState([]);
  const [orderInputValue, setOrderInputValue] = useState('');
  const [orderElementsForSelect, setOrderElementsForSelect] = useState([]);
  const [productsForSelect, setProductsForSelect] = useState([]);
  const [products, setProducts] = useState({});
  const [storehouse, setStorehouse] = useState(null);
  const [storehouseInputValue, setStorehouseInputValue] = useState('');
  const [storehouseElementsForSelect, setStorehouseElementsForSelect] =
    useState([]);
  const [storageCellInputValue, setStorageCellInputValue] = useState('');
  const [storageCellElementsForSelect, setStorageCellElementsForSelect] =
    useState([]);
  const [responsible, setResponsible] = useState({});
  const [coExecutors, setCoExecutors] = useState({});
  const [logisticsDocumentsForCreate, setLogisticsDocumentsForCreate] =
    useState({});
  const [
    selectedLogisticsDocumentsIdToDelete,
    setSelectedLogisticsDocumentsIdToDelete,
  ] = useState(null);
  const [
    viewDeleteLogisticsDocumentsConfirmDialog,
    setViewDeleteLogisticsDocumentsConfirmDialog,
  ] = useState(false);
  const [logisticsDocumentsIdForDelete, setLogisticsDocumentsIdForDelete] =
    useState([]);
  const [mediaContentForCreate, setMediaContentForCreate] = useState({});
  const [selectedMediaContentIdToDelete, setSelectedMediaContentIdToDelete] =
    useState(null);
  const [
    viewDeleteMediaContentConfirmDialog,
    setViewDeleteMediaContentConfirmDialog,
  ] = useState(false);
  const [mediaContentIdForDelete, setMediaContentIdForDelete] = useState([]);

  useEffect(() => {
    const req = async () => {
      const shipment_resp = await get_shipment(params.id);
      const order = await orderService.get(shipment_resp.order_id);
      shipment_resp.order = order;
      const responsible = [];
      for (const user_id of shipment_resp.responsible_ids) {
        const user = await get_user(user_id);
        responsible.push(user);
      }
      shipment_resp.responsible = responsible;
      const co_executors = [];
      for (const user_id of shipment_resp.co_executors_ids) {
        const user = await get_user(user_id);
        co_executors.push(user);
      }
      shipment_resp.coExecutors = co_executors;
      if (shipment_resp.storage_cell) {
        setStorehouse(shipment_resp.storage_cell.shelving.storehouse);
      }
      const oldShipment = { ...shipment_resp };
      setOldShipment(oldShipment);
      setModelData(shipment_resp);

      const packing = await get_shipment_packing();
      setPacking(packing);

      const shipement_statuses = await get_shipment_statuses();
      setShipmentStatuses(shipement_statuses);

      const products = await get_products({
        order_id: shipment_resp.order_id,
      });
      setProductsForSelect([
        ...products,
        ...shipment_resp.shipment_products,
      ]);

      const users = await get_users();
      setDefUsers(users);
    };
    req();
    pushPage(location.pathname);
    const attrsForEdit = new Set(
      roleAccess[userRole].fieldRestrictions.Shipments?.edit
    );
    setAttrsForEdit(attrsForEdit);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = { complete: false };
      if (orderInputValue) filters['id'] = orderInputValue;
      const new_elements = await get_orders(filters);

      setOrderElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [orderInputValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (storehouseInputValue) filters['name'] = storehouseInputValue;
      const new_elements = await get_storehouses(filters);
      setStorehouseElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storehouseInputValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (!storehouse) return;
      let filters = { storehouse_id: storehouse.id };
      if (storageCellInputValue)
        filters['storage_cell_name'] = storageCellInputValue;
      const new_elements = await get_storage_cells(filters);
      setStorageCellElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storageCellInputValue]);

  const getDataFromAttr = () => {
    if (modelData.order == null) {
      setErrors({
        order_id: ['There are blank fields left'],
      });
      return;
    }
    const shipment_products = Object.values(products).map((m) => {
      return { id: m.id ? m.id : null, product_id: m.product.id, qty: m.qty };
    });
    for (let shipment_product of shipment_products) {
      if (!shipment_product.product_id || !shipment_product.qty) {
        setErrors({
          products_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    for (let id of responsible_ids) {
      if (id === null) {
        setErrors({
          responsible_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const co_executor_ids = Object.values(coExecutors).map((m) =>
      m ? m.id : null
    );
    for (let id of co_executor_ids) {
      if (id === null) {
        setErrors({
          co_executors_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const data = {
      order_id: modelData.order.id,
      shipment_products: shipment_products,
      status: modelData.status,
      type_logistics: modelData.type_logistics,
      packing: modelData.packing,
      weight_in_kg: modelData.weight_in_kg,
      volume_in_m3: modelData.volume_in_m3,
      number_of_seats: modelData.number_of_seats,
      responsible_ids: responsible_ids,
      co_executors_ids: co_executor_ids,
      logistics_documents_for_create: Object.values(
        logisticsDocumentsForCreate
      ),
      logistics_documents_id_for_delete: logisticsDocumentsIdForDelete,
      media_content_for_create: Object.values(mediaContentForCreate),
      media_content_id_for_delete: mediaContentIdForDelete,
    };
    if (modelData.storage_cell !== oldShipment.storage_cell) {
      data.storage_cell_id = modelData.storage_cell
        ? modelData.storage_cell.id
        : null;
    }

    return data;
  };

  if (!modelData) return;
  
  return (
    <div className="pageUpdateModel">
      {viewDeleteLogisticsDocumentsConfirmDialog && (
        <ConfirmDialog
          message={interface_translate['Delete?'][language]}
          onConfirm={() => {
            setViewDeleteLogisticsDocumentsConfirmDialog(false);
            setLogisticsDocumentsIdForDelete((prevData) => {
              const new_data = [
                ...prevData,
                selectedLogisticsDocumentsIdToDelete,
              ];
              return new_data;
            });
            setSelectedLogisticsDocumentsIdToDelete(null);
            setModelData({
              ...modelData,
              logistics_documents: modelData.logistics_documents.filter(
                (model) => model.id !== selectedLogisticsDocumentsIdToDelete
              ),
            });
          }}
          onCancel={() => setViewDeleteLogisticsDocumentsConfirmDialog(false)}
        />
      )}
      {viewDeleteMediaContentConfirmDialog && (
        <ConfirmDialog
          message={interface_translate['Delete?'][language]}
          onConfirm={() => {
            setViewDeleteMediaContentConfirmDialog(false);
            setMediaContentIdForDelete((prevData) => {
              const new_data = [...prevData, selectedMediaContentIdToDelete];
              return new_data;
            });
            setSelectedMediaContentIdToDelete(null);
            setModelData({
              ...modelData,
              media_content: modelData.media_content.filter(
                (model) => model.id !== selectedMediaContentIdToDelete
              ),
            });
          }}
          onCancel={() => setViewDeleteMediaContentConfirmDialog(false)}
        />
      )}
      <div className={styles.controls}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await update_shipment(
              params.id,
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(interface_translate['Shipment updated'][language]);
              resetGlobalError();
              setErrors({});
              popPage();
              const previousPage = getCurrentPage();
              navigate(previousPage ? previousPage : '..', {
                relative: 'path',
              });
            }
          }}
        >
          {interface_translate['Update'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('../..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      {modelData.hasOwnProperty('responsible') && (
        <div className="inputData">
          <OpeningBlock
            title={interface_translate['About shipment'][language]}
            open={true}
          >
            {attrsForEdit.has('order_id') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Order'][language]}
                  </div>
                  <Autocomplete
                    value={modelData.order}
                    onChange={(e, newElement) => {
                      if (newElement == null) {
                        modelData.products = [];
                        setProducts([]);
                      } else {
                        let new_products = newElement.products;
                        if (newElement.id == oldShipment.order.id) {
                          new_products = [
                            ...new_products,
                            ...oldShipment.products,
                          ];
                        }
                        setProductsForSelect(new_products);
                      }
                      setModelData({
                        ...modelData,
                        order: newElement,
                      });
                    }}
                    options={orderElementsForSelect}
                    getOptionLabel={(model) => model.id.toString()}
                    onInputChange={(e, value) => {
                      setOrderInputValue(value);
                      setOrderElementsForSelect([]);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={interface_translate['Order'][language]}
                        size="small"
                      />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    loading={orderElementsForSelect.length == 0}
                  />
                </div>
                {errors['order_id'] && (
                  <div className="openingBlockErrorAttr">
                    {api_error_translate[errors['order_id'][0]][language]}
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('product') && (
              <div>
                <ProductsWithQtyInput
                  containerClass={'openingBlockAttr'}
                  attrNameClass={'openingBlockAttrName'}
                  products={products}
                  setSelectedElement={(value) => {
                    setProducts((prevData) => {
                      return { ...prevData, ...value };
                    });
                  }}
                  deleteSelectedElement={(id) => {
                    setProducts((prevElements) => {
                      delete prevElements[id];
                      return { ...prevElements };
                    });
                  }}
                  order={modelData.order}
                  defaultElements={productsForSelect}
                  initialElements={modelData.shipment_products}
                  product_ids_errors={(() => {
                    const err_data = errors['shipment_product.qty'];
                    if (!err_data) return {};
                    return {
                      [err_data.productId]: `Превышает допустимый лимит ${err_data.availableStock}`,
                    };
                  })()}
                  errorContainerClass={'openingBlockErrorAttr'}
                />
                {errors['products_ids'] && (
                  <div className="openingBlockErrorAttr">
                    {api_error_translate[errors['products_ids'][0]][language]}
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('status_translate') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Status'][language]}
                  </div>
                  <select
                    value={modelData.status}
                    onChange={(e) => {
                      setModelData({ ...modelData, status: e.target.value });
                    }}
                  >
                    {shipmentStatuses.map((status, id) => (
                      <option
                        key={id}
                        value={status}
                      >
                        {statuses_translate[status][language]}
                      </option>
                    ))}
                  </select>
                </div>
                {errors['status'] && (
                  <div className="openingBlockErrorAttr">
                    {api_error_translate[errors['status'][0]][language]}
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('packing_translate') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Packing'][language]}
                  </div>
                  <select
                    value={modelData.packing}
                    onChange={(e) => {
                      setModelData({ ...modelData, packing: e.target.value });
                    }}
                  >
                    {packing.map((status, id) => (
                      <option
                        key={id}
                        value={status}
                      >
                        {types[status][language]}
                      </option>
                    ))}
                  </select>
                </div>
                {errors['packing'] && (
                  <div className="openingBlockErrorAttr">
                    {api_error_translate[errors['packing'][0]][language]}
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('weight_in_kg') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Weight in kg'][language]}
                  </div>
                  <input
                    type="text"
                    value={modelData.weight_in_kg}
                    onChange={(e) => {
                      setModelData({
                        ...modelData,
                        weight_in_kg: e.target.value,
                      });
                    }}
                  />
                </div>
                {errors['weight_in_kg'] && (
                  <div className="openingBlockErrorAttr">
                    {api_error_translate[errors['weight_in_kg'][0]][language]}
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('volume_in_m3') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Volume in м³'][language]}
                  </div>
                  <input
                    type="text"
                    value={modelData.volume_in_m3}
                    onChange={(e) => {
                      setModelData({
                        ...modelData,
                        volume_in_m3: e.target.value,
                      });
                    }}
                  />
                </div>
                {errors['volume_in_m3'] && (
                  <div className="openingBlockErrorAttr">
                    {api_error_translate[errors['volume_in_m3'][0]][language]}
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('number_of_seats') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Number of seats'][language]}
                  </div>
                  <input
                    type="text"
                    value={modelData.number_of_seats}
                    onChange={(e) => {
                      setModelData({
                        ...modelData,
                        number_of_seats: e.target.value,
                      });
                    }}
                  />
                </div>
                {errors['number_of_seats'] && (
                  <div className="openingBlockErrorAttr">
                    {
                      api_error_translate[errors['number_of_seats'][0]][
                        language
                      ]
                    }
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('storehouse_name') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Storehouse'][language]}
                  </div>
                  <Autocomplete
                    value={storehouse}
                    onChange={async (e, newElement) => {
                      setStorehouse(newElement);
                      if (!newElement) {
                        setModelData({ ...modelData, storage_cell: null });
                        return;
                      }
                      const filters = { storehouse_id: newElement.id };
                      const new_storage_cells = await get_storage_cells(
                        filters
                      );
                      setStorageCellElementsForSelect(new_storage_cells);
                    }}
                    options={storehouseElementsForSelect}
                    getOptionLabel={(model) => model.name}
                    onInputChange={(e, value) => {
                      setStorehouseInputValue(value);
                      setStorehouseElementsForSelect([]);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={interface_translate['Storehouse'][language]}
                        size="small"
                      />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    loading={storageCellElementsForSelect.length == 0}
                  />
                </div>
              </div>
            )}
            {attrsForEdit.has('storage_cell_name') && (
              <div>
                {storehouse && (
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Storage cell'][language]}
                    </div>
                    <Autocomplete
                      value={modelData.storage_cell}
                      onChange={(e, newElement) => {
                        setModelData({
                          ...modelData,
                          storage_cell: newElement,
                        });
                      }}
                      options={storageCellElementsForSelect}
                      getOptionLabel={(model) =>
                        `${model.shelving.name}${model.number}`
                      }
                      onInputChange={(e, value) => {
                        setStorageCellInputValue(value);
                        setStorageCellElementsForSelect([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={interface_translate['Storage cell'][language]}
                          size="small"
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      loading={storageCellElementsForSelect.length == 0}
                    />
                  </div>
                )}
                {errors['storage_cell_id'] && (
                  <div className="openingBlockErrorAttr">
                    {
                      api_error_translate[errors['storage_cell_id'][0]][
                        language
                      ]
                    }
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('responsible') && (
              <div>
                <ResponsibleListInput
                  containerClass={'openingBlockAttr'}
                  attrNameClass={'openingBlockAttrName'}
                  responsible={responsible}
                  setSelectedElement={(value) => {
                    setResponsible((prevData) => {
                      return { ...prevData, ...value };
                    });
                  }}
                  deleteSelectedElement={(id) => {
                    setResponsible((prevElements) => {
                      delete prevElements[id];
                      return { ...prevElements };
                    });
                  }}
                  initialElements={modelData.responsible}
                  defaultElements={defUsers}
                />
                {errors['responsible_ids'] && (
                  <div className="openingBlockErrorAttr">
                    {
                      api_error_translate[errors['responsible_ids'][0]][
                        language
                      ]
                    }
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('co_executors') && (
              <div>
                <CoExecutorsInput
                  containerClass={'openingBlockAttr'}
                  attrNameClass={'openingBlockAttrName'}
                  coExecutors={coExecutors}
                  setSelectedElement={(value) => {
                    setCoExecutors((prevData) => {
                      return { ...prevData, ...value };
                    });
                  }}
                  deleteSelectedElement={(id) => {
                    setCoExecutors((prevElements) => {
                      delete prevElements[id];
                      return { ...prevElements };
                    });
                  }}
                  initialElements={modelData.coExecutors}
                  defaultElements={defUsers}
                />
                {errors['co_executors_ids'] && (
                  <div className="openingBlockErrorAttr">
                    {
                      api_error_translate[errors['co_executors_ids'][0]][
                        language
                      ]
                    }
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('logistics_documents') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Documents logístics'][language]}
                    <p>{`${interface_translate['Allowed formats'][language]}: "pdf", "docx", "xlsx"`}</p>
                  </div>
                  <div>
                    <div className="listDropDomnMedia">
                      {modelData.logistics_documents.map((model, id) => {
                        let mediaBlock;
                        let preViewImg;
                        if (model.file_type === 'photo') {
                          mediaBlock = (
                            <img
                              src={model.url}
                              alt="image"
                            />
                          );
                          preViewImg = mediaBlock;
                        } else if (model.file_type === 'video') {
                          mediaBlock = (
                            <video
                              src={model.url}
                              alt="video"
                              controls
                              style={{ maxWidth: '100%' }}
                            />
                          );
                          preViewImg = (
                            <p>{interface_translate['Video'][language]}</p>
                          );
                        } else if (model.file_type === 'document') {
                          mediaBlock = (
                            <iframe
                              src={model.url}
                              alt="document"
                              controls
                              style={{ width: '90vw', height: '89vh' }}
                            />
                          );
                          preViewImg = (
                            <p>{interface_translate['Document'][language]}</p>
                          );
                        }
                        return (
                          <div className="DropDownMediaBlockDelete">
                            <DropDownMediaBlock
                              key={id}
                              preViewImg={preViewImg}
                              mediaBlock={mediaBlock}
                            />
                            <button
                              onClick={() => {
                                setSelectedLogisticsDocumentsIdToDelete(
                                  model.id
                                );
                                setViewDeleteLogisticsDocumentsConfirmDialog(
                                  true
                                );
                              }}
                            >
                              {interface_translate['Delete'][language]}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    <ListAttrMedia
                      setSelectedElement={(value) => {
                        setLogisticsDocumentsForCreate((prevData) => {
                          return { ...prevData, ...value };
                        });
                      }}
                      deleteSelectedElement={(id) => {
                        setLogisticsDocumentsForCreate((prevElements) => {
                          delete prevElements[id];
                          return { ...prevElements };
                        });
                      }}
                    />
                  </div>
                </div>
                {errors['logistics_documents'] && (
                  <div className="openingBlockErrorAttr">
                    {
                      api_error_translate[errors['logistics_documents'][0]][
                        language
                      ]
                    }
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('media_content') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Media content'][language]}
                    <p>{`${interface_translate['Allowed formats'][language]}: "jpg", "png", "webp"`}</p>
                    <p>{`"heic", "mp4", "mov", "pdf", "docx", "xlsx"`}</p>
                  </div>
                  <div>
                    <div className="listDropDomnMedia">
                      {modelData.media_content.map((model, id) => {
                        let mediaBlock;
                        let preViewImg;
                        if (model.file_type === 'photo') {
                          mediaBlock = (
                            <img
                              src={model.url}
                              alt="image"
                            />
                          );
                          preViewImg = mediaBlock;
                        } else if (model.file_type === 'video') {
                          mediaBlock = (
                            <video
                              src={model.url}
                              alt="video"
                              controls
                              style={{ maxWidth: '100%' }}
                            />
                          );
                          preViewImg = (
                            <p>{interface_translate['Video'][language]}</p>
                          );
                        }
                        return (
                          <div className="DropDownMediaBlockDelete">
                            <DropDownMediaBlock
                              key={id}
                              preViewImg={preViewImg}
                              mediaBlock={mediaBlock}
                            />
                            <button
                              onClick={() => {
                                setSelectedMediaContentIdToDelete(model.id);
                                setViewDeleteMediaContentConfirmDialog(true);
                              }}
                            >
                              {interface_translate['Delete'][language]}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    <ListAttrMedia
                      setSelectedElement={(value) => {
                        setMediaContentForCreate((prevData) => {
                          return { ...prevData, ...value };
                        });
                      }}
                      deleteSelectedElement={(id) => {
                        setMediaContentForCreate((prevElements) => {
                          delete prevElements[id];
                          return { ...prevElements };
                        });
                      }}
                    />
                  </div>
                </div>
                {errors['media_content'] && (
                  <div className="openingBlockErrorAttr">
                    {api_error_translate[errors['media_content'][0]][language]}
                  </div>
                )}
              </div>
            )}
          </OpeningBlock>
        </div>
      )}
      {!modelData && <div>Пусто</div>}
    </div>
  );
};

export default ShipmentEditPage;
