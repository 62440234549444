import { interface_translate } from '../texts/interface';
import styles from '../App.css';

export function getPrevViewImgAndMediaBlockByImageObj(image, language) {
  let mediaBlock;
  let preViewImg;

  if (image.file_type === 'photo') {
    mediaBlock = (
      <img
        src={image.url}
        alt="image"
      />
    );
    preViewImg = mediaBlock;
  } else if (image.file_type === 'video') {
    mediaBlock = (
      <video
        src={image.url}
        alt="video"
        controls
        style={{ maxWidth: '100%' }}
      />
    );
    preViewImg = <p>{interface_translate['Video'][language]}</p>;
  } else if (image.file_type === 'document') {
    mediaBlock = (
      <div className="blockDownload">
        <a
          href={image.url}
          download={image.filename}
          target="_blank"
          rel="noopener noreferrer"
        >
          {interface_translate['Download'][language]}{' '}
        </a>
      </div>
    );
    preViewImg = <p>{interface_translate['Document'][language]}</p>;
  }

  return { mediaBlock, preViewImg };
}
