import apiRequester from '../ApiRequester';
import { model_names } from '../texts/model_names.js';
import { api_error_translate } from '../texts/error.js';

class DeliveryService {
  async create(data, setErrors, setGlobalError, resetGlobalMsg, language) {
    const jsonData = JSON.stringify(data);
    const co_executor_ids_already_in_responsible_ids_pattern =
      /ids:.*already in responsible_ids/;
    const quantityErrorPattern =
      /Quantity of (\d+(?:\.\d+)?) exceeds available stock of (\d+(?:\.\d+)?) for product ID: (\w+)\./;
    try {
      await apiRequester.post('/deliveries', jsonData);
      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (detail_msg === 'delivery already exists') {
            setErrors({});
            setGlobalError(api_error_translate[detail_msg][language]);
            resetGlobalMsg();
          } else if (
            err_msg.hasOwnProperty('co_executors_ids') &&
            co_executor_ids_already_in_responsible_ids_pattern.test(
              err_msg['co_executors_ids']
            )
          ) {
            setErrors({
              co_executors_ids: ['co_executors_ids already in responsible_ids'],
            });
            return;
          } else if (err_msg.hasOwnProperty('delivery_product.qty')) {
            const match = quantityErrorPattern.exec(
              err_msg['delivery_product.qty'][0]
            );
            const [_, quantityOrdered, availableStock, productId] = match;
            setErrors({
              'delivery_product.qty': {
                msg: err_msg,
                productId,
                availableStock,
                quantityOrdered,
              },
            });
            return;
          }
          setErrors({ ...err_msg });
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
    }
  }

  async get(id) {
    try {
      const response = await apiRequester.get(`/deliveries/${id}`);
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }

  async get_by_filters(filterData, paginationSettings = { size: 20 }) {
    const jsonData = JSON.stringify(filterData);
    try {
      const response = await apiRequester.post(
        `/deliveries/filters`,
        jsonData,
        {
          params: paginationSettings,
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }

  async get_statuses() {
    const response = await apiRequester.get(`/deliveries/statuses`);
    return response.data;
  }

  async update(id, data, setErrors, setGlobalError, resetGlobalMsg, language) {
    const co_executor_ids_already_in_responsible_ids_pattern =
      /ids: .* already in responsible_ids/;
    const quantityErrorPattern =
      /Quantity of (\d+(?:\.\d+)?) exceeds available stock of (\d+(?:\.\d+)?) for product ID: (\w+)\./;
    try {
      const formData = new FormData();
      if (data.order_id !== undefined) {
        formData.append('order_id', data.order_id);
      }
      if (data.delivery_products !== undefined) {
        if (data.delivery_products.length > 0) {
          data.delivery_products.forEach((m) => {
            formData.append(`delivery_products`, JSON.stringify(m));
          });
        } else {
          formData.append('delivery_products', -1);
        }
      }
      if (data.track_number !== undefined) {
        const track_number = data.track_number
          ? data.track_number
          : JSON.stringify('');
        formData.append('track_number', track_number);
      }
      if (data.status !== undefined) {
        formData.append('status', data.status);
      }
      if (data.responsible_ids !== undefined) {
        if (data.responsible_ids.length > 0) {
          data.responsible_ids.forEach((m) => {
            formData.append(`responsible_ids`, m);
          });
        } else {
          formData.append('responsible_ids', -1);
        }
      }
      if (data.co_executors_ids !== undefined) {
        if (data.co_executors_ids.length > 0) {
          data.co_executors_ids.forEach((m) => {
            formData.append(`co_executors_ids`, m);
          });
        } else {
          formData.append('co_executors_ids', -1);
        }
      }
      if (data.media_content_acceptance_for_create !== undefined) {
        data.media_content_acceptance_for_create.forEach((m) => {
          formData.append(`media_content_acceptance_for_create`, m);
        });
      }
      if (data.media_content_acceptance_id_for_delete !== undefined) {
        if (data.media_content_acceptance_id_for_delete.length > 0) {
          data.media_content_acceptance_id_for_delete.forEach((m) => {
            formData.append(`media_content_acceptance_id_for_delete`, m);
          });
        } else {
          formData.append('media_content_acceptance_id_for_delete', -1);
        }
      }
      if (data.products_is_agreed !== undefined) {
        formData.append('products_is_agreed', data.products_is_agreed);
      }
      if (data.for_products_selected_storage_cells !== undefined) {
        formData.append(
          'for_products_selected_storage_cells',
          data.for_products_selected_storage_cells
        );
      }
      await apiRequester.patch(`/deliveries/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (detail_msg === 'delivery already exists') {
            setErrors({});
            setGlobalError(api_error_translate[detail_msg][language]);
            resetGlobalMsg();
          } else if (
            err_msg?.hasOwnProperty('co_executors_ids') &&
            co_executor_ids_already_in_responsible_ids_pattern.test(
              err_msg['co_executors_ids']
            )
          ) {
            setErrors({
              co_executors_ids: ['co_executors_ids already in responsible_ids'],
            });
            return;
          } else if (err_msg.hasOwnProperty('delivery_product.qty')) {
            const match = quantityErrorPattern.exec(
              err_msg['delivery_product.qty'][0]
            );
            const [_, quantityOrdered, availableStock, productId] = match;
            setErrors({
              'delivery_product.qty': {
                msg: err_msg,
                productId,
                availableStock,
                quantityOrdered,
              },
            });
            return;
          }
          setErrors({ ...err_msg });
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
    }
  }

  async scan_track_number(track_number, setGlobalError, language) {
    try {
      const response = await apiRequester.post(
        `/deliveries/scan_track_number?track_number=${track_number}`
      );
      const data = await response.data;
      return data;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const err_msg = err.response.data.errors;
          if (err_msg === 'delivery not found') {
            setGlobalError(api_error_translate[err_msg][language]);
            return;
          }
        }
      }
      console.error(`Could not fetch data: ${err}`);
    }
  }

  async delete(id, setGlobalError, language) {
    try {
      const response = await apiRequester.delete(`/deliveries/${id}`);
      return response.data;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (err_msg === 'foreign connection') {
            let msg = `${api_error_translate['linked to another model'][language]}: ${model_names[detail_msg][language]}`;
            setGlobalError(msg);
          }
        }
        return;
      }
      console.error(`Could not fetch data: ${err}`);
      throw err;
    }
  }
}

const deliveryService = new DeliveryService();
export default deliveryService;
