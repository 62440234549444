import { NavLink } from 'react-router-dom';
import styles from './Header.module.css';

const HeaderSection = (props) => {
  const { children, name, path, setSectionNameIsActive } = props;
  return (
    <NavLink
      to={path}
      className={styles.sectionContent}
      onClick={() => setSectionNameIsActive(name)}
    >
      {children}
    </NavLink>
  );
};

export default HeaderSection;
