import apiRequester from '../ApiRequester';
import { model_names } from '../texts/model_names.js';
import { api_error_translate } from '../texts/error.js';
import unidentifiedDeliveryService from './unidentified_delivery.js';

class DeliveryProductService {
  async create(data, setErrors, setGlobalError, resetGlobalMsg, language) {
    try {
      const formData = new FormData();
      //   if (data.name) formData.append('name', data.name);
      //   if (data.description) formData.append('description', data.description);
      //   if (data.link) formData.append('link', data.link);
      //   if (data.measurement_unit)
      //     formData.append('measurement_unit', data.measurement_unit);
      //   if (data.qty) formData.append('qty', data.qty);
      //   if (data.price) formData.append('price', data.price);
      //   if (data.currency) formData.append('currency', data.currency);
      //   if (data.supplier_id) formData.append('supplier_id', data.supplier_id);
      //   formData.append('order_id', data.order_id);

      //   data.images?.forEach((m) => {
      //     formData.append(`images`, m);
      //   });
      const response = await apiRequester.post('/delivery_products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (detail_msg === 'delivery_product already exists') {
            setErrors({});
            setGlobalError(api_error_translate[detail_msg][language]);
            resetGlobalMsg();
          }
          setErrors({ ...err_msg });
          resetGlobalMsg();
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
    }
  }

  async get_by_filters(filterData, paginationSettings = { size: 20 }) {
    const jsonData = JSON.stringify(filterData);
    try {
      const response = await apiRequester.post(
        `/delivery_products/filters`,
        jsonData,
        {
          params: paginationSettings,
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }

  async get(id) {
    try {
      const response = await apiRequester.get(`/delivery_products/${id}`);
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }

  async get_statuses() {
    try {
      const response = await apiRequester.get(`/delivery_products/statuses`);
      const statuses = response.data;
      return statuses;
    } catch (err) {
      if (err.response.status !== 200) {
        throw new Error(`HTTP error: ${err.response.status}`);
      }
      console.error(`Could not fetch data: ${err}`);
    }
  }

  async update(id, data, setErrors, setGlobalError, resetGlobalMsg, language) {
    try {
      await apiRequester.patch(`/delivery_products/${id}`, data);
      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (detail_msg === 'delivery_product already exists') {
            setErrors({});
            setGlobalError(api_error_translate[detail_msg][language]);
            resetGlobalMsg();
          }
          setErrors({ ...err_msg });
          resetGlobalMsg();
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
    }
  }

  async check(id, data, setErrors, resetGlobalMsg) {
    try {
      const formData = new FormData();
      console.log(data);
      formData.append(
        'check_weight_in_kg',
        data.check_weight_in_kg ? data.check_weight_in_kg : null
      );
      formData.append(
        'check_volume_in_m3',
        data.check_volume_in_m3 ? data.check_volume_in_m3 : null
      );
      formData.append(
        'check_content',
        data.check_content ? data.check_content : ''
      );
      formData.append('check_qty', data.check_qty);
      formData.append('was_qty_check', data.was_qty_check);
      data.media_content?.forEach((m) => {
        formData.append(`media_content`, m);
      });
      await apiRequester.post(`/delivery_products/${id}/check`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const err_msg = err.response.data.errors;
          setErrors({ ...err_msg });
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
      console.error(`Could not fetch data: ${err}`);
    }
  }

  async delete(id, setGlobalError, language) {
    try {
      const response = await apiRequester.delete(`/delivery_products/${id}`);
      return response.data;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (err_msg === 'foreign connection') {
            let msg = `${api_error_translate['linked to another model'][language]}: ${model_names[detail_msg][language]}`;
            setGlobalError(msg);
          }
        }
        return;
      }
      console.error(`Could not fetch data: ${err}`);
      throw err;
    }
  }
}

const deliveryProductService = new DeliveryProductService();
export default deliveryProductService;
