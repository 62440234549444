export const types = {
  pallet: { ru: 'паллет', en: 'pallet', ch: '托盘' },
  crate: { ru: 'обрешетка', en: 'crate', ch: '木架' },
  bag: { ru: 'мешок', en: 'bag', ch: '木架' },
  wooden_box: { ru: 'деревянный короб', en: 'wooden_box', ch: '木箱' },
  individual_packing: {
    ru: 'индивидуальная упаковка',
    en: 'individual_packing',
    ch: '个人包装',
  },
  white: { ru: 'бeлая', en: 'white', ch: '白的' },
  cargo: { ru: 'карго', en: 'cargo', ch: '物流' },
  supplier: { ru: 'поставщик', en: 'supplier', ch: '供应商' },
  client: { ru: 'клиент', en: 'client', ch: '客户' },
  car_fast: { ru: 'авто быстрое', en: 'car_fast', ch: '特快车' },
  car_slow: { ru: 'авто медленное', en: 'car_slow', ch: '普快车' },
  car: { ru: 'авто', en: 'car', ch: '车物流' },
  air: { ru: 'авиа', en: 'air', ch: '飞机物流' },
  railway: { ru: 'Ж/Д', en: 'railway', ch: '火车物流' },
  combined: { ru: 'комбинированная', en: 'combined', ch: '合并物流' },
  piece: { ru: 'шт', en: 'piece', ch: '' },
  liter: { ru: 'литр', en: 'liter', ch: '' },
  м3: { ru: 'м³', en: 'м3', ch: '' },
  kg: { ru: 'кг', en: 'kg', ch: '' },
  linear_meter: { ru: 'погонный метр', en: 'linear_meter', ch: '' },
};
