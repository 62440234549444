import React, { useState } from 'react';
import HeaderSections from './HeaderSections';
import LanguageSwitcher from '../LanguageSwitcher';
import useCommon from '../../hooks/useCommon';
import AuthBtns from '../auth/AuthBtns';
import useAuth from '../../hooks/useAuth';
import styles from './Header.module.css';
import UserActions from './UserActions';
import AddEmployeeForm from '../auth/AddEmployeeForm';
import * as roles from '../../roles';
import ModalWindow from '../ModalWindow';

const Header = (props) => {
  const { isAuth } = useAuth();
  const { userRole, setLanguage } = useCommon();
  const [isModalActive, setIsModalActive] = useState(false);

  const openModal = () => setIsModalActive(true);
  const closeModal = () => setIsModalActive(false);

  return (
    <header className={styles.header}>
      <nav className={styles.navbar}>
        <HeaderSections />
      </nav>
      {!isAuth && <AuthBtns />}
      {isAuth && (
        <>
          <UserActions />
          {userRole === roles.admin && (
            <>
              <button className={styles.addEmployeeBtn} onClick={openModal}>
                Добавить сотрудника
              </button>
              <ModalWindow isActive={isModalActive} setIsActive={setIsModalActive}>
                <AddEmployeeForm closeModal={closeModal} />
              </ModalWindow>
            </>
          )}
        </>
      )}
      <LanguageSwitcher setLanguage={setLanguage} />
    </header>
  );
};

export default Header;
