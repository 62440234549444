import useCommon from '../hooks/useCommon';
import { interface_translate } from '../texts/interface';
import styles from './ModelFilters.module.css';
import './ModelFilters.module.css'

const ModelFilters = (props) => {
  const { language } = useCommon()
  const { children, requestModels, resetFilterData } = props;

  return (
    <div className={styles.modelFilters}>
      <div className={styles.controlBtns}>
        <button onClick={requestModels}>
          {interface_translate['Encontrar'][language]}
        </button>
        <button onClick={resetFilterData}>
          {interface_translate['Borrar'][language]}
        </button>
      </div>
      {children}
    </div>
  );
};

export default ModelFilters;
