export const model_names = {
  counterparties: { ru: 'Контрагенты', en: 'Counterparties' },
  orders: { ru: 'Заказы', en: 'Orders' },
  products: { ru: 'Товары', en: 'Products' },
  deliverys: { ru: 'Поставки', en: 'Delivery' },
  unidentified_deliverys: {ru: 'Неопознанные посылки', en: 'Unidentified deliverys'},
  shipments: { ru: 'Отправления', en: 'Shipments' },
  customer_cargo: { ru: 'Клиентские грузы', en: 'Customer cargo' },
  unidentified_cargo: {ru: 'Неопознанные грузы', en: 'Unidentified cargo'},
  shelving: { ru: 'Стеллажи', en: 'Shelving' },
  storage_cells: { ru: 'Ячейки хранения', en: 'Storage cells' },
};
