import { useEffect } from 'react';
import { ImCross } from 'react-icons/im';
import styles from './GlobalError.module.css';

const GlobalError = (props) => {
  const { error, resetError } = props;
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        resetError();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [error]);
  if (!error) return null;
  return (
    <div className={styles.globalError}>
      {error}
      <ImCross
        style={{ cursor: 'pointer', fontSize: '10px' }}
        onClick={resetError}
      />
    </div>
  );
};

export default GlobalError;
