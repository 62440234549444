import LoginForm from '../components/auth/LoginForm';

const LoginPage = (props) => {
  const { setGlobalError, resetGlobalError, setGlobalMsg, resetGlobalMsg } =
    props;
  return (
    <div>
      <LoginForm setGlobalError={setGlobalError} />
    </div>
  );
};

export default LoginPage;
