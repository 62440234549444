import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Filter from './Filter';

const AutocompleteFilter = (props) => {
  const {
    title_name,
    attr,
    addFiltersData,
    defaultValue,
    getElementLabel,
    searchElementsDelay = 500,
    searchElements,
  } = props;
  const [elements, setElements] = useState([]);
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      const new_elements = await searchElements(inputValue);
      setElements(new_elements);
    }, searchElementsDelay);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [inputValue]);

  return (
    <Filter title_name={title_name}>
      <Autocomplete
        value={defaultValue}
        onChange={(e, newElement) => {
          addFiltersData(attr, newElement);
        }}
        options={elements}
        getOptionLabel={getElementLabel}
        onInputChange={(e, value) => {
          setInputValue(value);
          setElements([]);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        loading={elements.length == 0}
      />
    </Filter>
  );
};

export default AutocompleteFilter;
