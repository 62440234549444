import React from 'react';
import './ModalWindow.css';

const ModalWindow = (props) => {
  const { isActive, setIsActive, children } = props;
  return (
    <div
      className={isActive ? 'modal_window active' : 'modal_window'}
      onClick={() => setIsActive(false)}
    >
      <div
        className={
          isActive ? 'modal_window__content active' : 'modal_window__content'
        }
        onClick={(e) => e.stopPropagation()}
      >{children}</div>
    </div>
  );
};

export default ModalWindow;
