import React, { useEffect, useState } from 'react';
import { interface_translate } from '../../texts/interface';
import { types } from '../../texts/types';
import useCommon from '../../hooks/useCommon';
import ListAttrAutocomplete from '../ListAttrAutocomplete';
import { Autocomplete, TextField } from '@mui/material';
import productService from '../../services/product';
import { v4 as uuidv4 } from 'uuid';
import styles from './ProductWithQtyInput.module.css';
import '../../App.css';

async function get_products(order_id, name, supplier_id, size = 50) {
  const filters = {
    name: name,
    order_id: order_id,
    supplier_id: supplier_id,
    delivery_id: null,
  };

  try {
    const products_paginated = await productService.get_by_filters(filters, {
      size: size,
    });
    return products_paginated.data;
  } catch (err) {
    console.error(`Could not fetch data: ${err}`);
  }
}

const ProductsWithQtyInput = (props) => {
  const { language } = useCommon();
  const {
    products,
    setSelectedElement,
    deleteSelectedElement,
    order,
    supplier = null,
    initialElements = [],
    defaultElements = [],
    containerClass,
    attrNameClass,
    searchElementsDelay = 500,
    product_ids_errors = {},
    errorContainerClass,
  } = props;

  const [blocksId, setBlocksId] = useState([]);
  const [blocksElements, setBlocksElements] = useState({});
  const [blocksInputValue, setBlocksInputValue] = useState({});
  const [blockIdInFocus, setBlockIdInFocus] = useState(null);
  const [isLoadData, setIsLoadData] = useState(false);
  const [idsDefaultElements, setIdsDefaultElements] = useState([]);

  useEffect(() => {
    if (!blockIdInFocus) return;
    const delayDebounceFn = setTimeout(async () => {
      const new_elements = await searchElements(
        blocksInputValue[blockIdInFocus]
      );
      setIsLoadData(false);
      setBlocksElements((prevElements) => ({
        ...prevElements,
        [blockIdInFocus]: [...new_elements],
      }));
    }, searchElementsDelay);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [blockIdInFocus, blocksInputValue]);

  const removeElementByIndex = (id) => {
    deleteSelectedElement(id);
    setBlocksId((prevData) => {
      let index = prevData.indexOf(id);
      if (index !== -1) {
        prevData.splice(index, 1);
      }
      return [...prevData];
    });
    setBlocksElements((prevData) => {
      delete prevData[id];
      return { ...prevData };
    });
  };

  const addElement = (def_id, def_element) => {
    let element = { product: null, qty: null };
    if (def_element) element = def_element;
    let id = uuidv4();
    if (def_id) id = def_id;
    setSelectedElement({ [id]: element });
    setBlocksId((prevData) => {
      const new_data = [...prevData, id];
      return new_data;
    });
    setBlocksElements((prevData) => {
      const new_data = { ...prevData, [id]: defaultElements };
      return new_data;
    });
  };

  useEffect(() => {
    if (!initialElements) return;
    const idsDefaultElements = [];
    for (const element of initialElements) {
      const id = uuidv4();
      idsDefaultElements.push(id);
      addElement(id, element);
    }
    setIdsDefaultElements(idsDefaultElements);
  }, []);

  const searchElements = async (value) => {
    const products = await get_products(order?.id, value, supplier?.id);
    return products;
  };

  return (
    <div className={containerClass}>
      <div className={attrNameClass}>
        {interface_translate['Products'][language]}
      </div>
      <div className={styles.attrsContainer}>
        {blocksId.map((id) => {
          return (
            <div className="vertical" width='100%'>
              <div
                className={styles.attr}
                id={id}
                key={id}
              >
                <div className={styles.productWithQtyContainer}>
                  <Autocomplete
                    key={id}
                    value={products[id].product}
                    onChange={(event, newElement) => {
                      let value = products[id];
                      if (value) {
                        value.product = newElement;
                      }
                      setSelectedElement({ [id]: value });
                    }}
                    disabled={idsDefaultElements.includes(id)}
                    options={blocksElements[id] ? blocksElements[id] : []}
                    getOptionLabel={(model) => model.name}
                    onInputChange={(e, value) => {
                      setBlocksInputValue((prevInputValue) => ({
                        ...prevInputValue,
                        [id]: value,
                      }));
                      setBlockIdInFocus(id);
                      setBlocksElements((prevData) => {
                        const new_data = { ...prevData, [id]: [] };
                        return new_data;
                      });
                      setIsLoadData(true);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={interface_translate['Product'][language]}
                        size="small"
                      />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    loading={isLoadData}
                  />
                  <input
                    className={styles.qtyInput}
                    value={products[id].qty}
                    onChange={(event) => {
                      let value = products[id];
                      if (value) {
                        value.qty = event.target.value;
                      }
                      setSelectedElement({ [id]: value });
                    }}
                  ></input>
                  <p>
                    {products[id].product
                      ? types[products[id].product.measurement_unit][language]
                      : ''}
                  </p>
                  <button
                    onClick={() => {
                      removeElementByIndex(id);
                    }}
                  >
                    -
                  </button>
                </div>
              </div>
              {product_ids_errors[products[id].product?.id] && (
                <div className={errorContainerClass}>
                  {product_ids_errors[products[id].product.id]}
                </div>
              )}
            </div>
          );
        })}
        <button
          className={styles.addBtn}
          onClick={() => addElement()}
        >
          {interface_translate['Add'][language]}
        </button>
      </div>
    </div>
  );
};

export default ProductsWithQtyInput;
