import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { statuses_translate } from '../../texts/statuses.js';
import { types } from '../../texts/types.js';
import { interface_translate } from '../../texts/interface';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import ConfirmDialog from '../..//components/ConfirmDialog.jsx';
import WhiteLogisticsCreateForm from '../../components/shipment/WhiteLogisticsCreateForm.jsx';
import CargoLogisticsCreateForm from '../../components/shipment/CargoLogisticsCreateForm.jsx';
import ModalWindow from '../../components/ModalWindow';
import roleAccess from '../../roleAccess.js';
import userService from '../../services/user.js';
import shipmentService from '../../services/shipment.js';
import orderService from '../../services/order.js';
import SelectStorageCellBtn from '../../components/SelectStorageCellBtn.jsx';
import styles from './ShipmentPage.module.css';
import ShipmentRead from '../../components/model_cards/ShipmentRead.jsx';
import '../shared.css';
import { ShieldOutlined } from '@mui/icons-material';

async function get_shipment(id) {
  const shipment = await shipmentService.get(id);
  return shipment;
}

async function get_shipment_logistics(id) {
  const shipment_logistics = await shipmentService.get_logistics(id);
  return shipment_logistics;
}

async function set_status_ready_to_ship(
  id,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const data = { status: 'ready_to_ship' };
  const result = await shipmentService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function update_shipment_storage_cell(
  id,
  storage_cell_id,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const data = { storage_cell_id: storage_cell_id };
  const result = await shipmentService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function shipment_enter_white_logistics(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await shipmentService.enter_white_logistics(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function shipment_enter_cargo_logistics(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await shipmentService.enter_cargo_logistics(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_order(id) {
  const order = await orderService.get(id);
  return order;
}

async function get_user(id) {
  const user = await userService.get(id);
  return user;
}

function get_product_for_display(shipment_product, order, language) {
  const product = shipment_product.product;
  if (!product.link) product.link = '';
  product.qty_with_unit = `${product.qty} ${
    types[product.measurement_unit][language]
  }`;
  product.total_cost = product.price * product.qty;
  product.supplier_name = product.supplier.name;
  product.client_name = order.client.name;

  shipment_product.shipment_qty_with_unit = `${shipment_product.qty} ${
    types[shipment_product.product.measurement_unit][language]
  }`;

  return { ...product, ...shipment_product };
}

const ShipmentPage = () => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [shipment, setShipment] = useState();
  const [shipmentLogistics, setShipmentLogistics] = useState();
  const [logisticsData, setLogisticsData] = useState({});
  const [logisticsDataErrors, setLogisticsDataErrors] = useState({});
  const [
    viewEnterWhiteLogisticsModalWindow,
    setViewEnterWhiteLogisticsModalWindow,
  ] = useState();
  const [
    viewEnterCargoLogisticsModalWindow,
    setViewEnterCargoLogisticsModalWindow,
  ] = useState();
  const [viewReadyToShipConfirm, setViewReadyToShipConfirm] = useState(false);
  const [selectStorageCellErrors, setSelectStorageCellErrors] = useState({});

  useEffect(() => {
    const req = async () => {
      await requestGetShipment();
      await requestGetShipmentLogistics();
    };
    req();
    pushPage(location.pathname);
  }, []);

  useEffect(() => {
    if (!shipment) return;
    shipment.status_translate = statuses_translate[shipment.status][language];
    shipment.packing_translate = types[shipment.packing][language];
    setShipment({ ...shipment });
  }, [language]);

  const updateStorageCell = async (storage_cell) => {
    if (!storage_cell) return;
    const result = await update_shipment_storage_cell(
      shipment.id,
      storage_cell.id,
      setSelectStorageCellErrors,
      setGlobalError,
      resetGlobalMsg,
      language
    );
    if (result) {
      requestGetShipment();
    }
    return result;
  };

  const requestGetShipment = async () => {
    const shipment_resp = await get_shipment(params.id);
    let order = await get_order(shipment_resp.order_id);
    shipment_resp.order = order;
    const responsible = [];
    for (const user_id of shipment_resp.responsible_ids) {
      const user = await get_user(user_id);
      responsible.push(user);
    }
    shipment_resp.responsible = responsible;
    const co_executors = [];
    for (const user_id of shipment_resp.co_executors_ids) {
      const user = await get_user(user_id);
      co_executors.push(user);
    }
    shipment_resp.co_executors = co_executors;
    shipment_resp.status_translate =
      statuses_translate[shipment_resp.status][language];
    shipment_resp.packing_translate = types[shipment_resp.packing][language];
    shipment_resp.storehouse_id = shipment_resp.storage_cell
      ? shipment_resp.storage_cell.shelving.storehouse.id
      : '';
    shipment_resp.storehouse_name = shipment_resp.storage_cell
      ? shipment_resp.storage_cell.shelving.storehouse.name
      : '';
    shipment_resp.storage_cell_name = shipment_resp.storage_cell
      ? `${shipment_resp.storage_cell.shelving.name}${shipment_resp.storage_cell.number}`
      : '';
    let total_amount = 0;
    const shipment_products = [];
    for (let shipment_product of shipment_resp.shipment_products) {
      shipment_product = get_product_for_display(
        shipment_product,
        order,
        language
      );
      if (shipment_product.currency == 'cny') {
        total_amount += shipment_product.total_cost * order.rate_cny_to_rub;
      } else if (shipment_product.currency == 'usd') {
        total_amount += shipment_product.total_cost * order.rate_dollar_to_rub;
      } else if (shipment_product.currency == 'rub') {
        total_amount += shipment_product.total_cost;
      }
      shipment_products.push(shipment_product);
    }
    shipment_resp.shipment_products = shipment_products;
    shipment_resp.total_amount = total_amount;
    setShipment(shipment_resp);
  };

  const requestGetShipmentLogistics = async () => {
    const shipmentLogistics = await get_shipment_logistics(params.id);
    setShipmentLogistics(shipmentLogistics);
  };

  const getDataForEnterLogistics = (type_logistics) => {
    const data = {};
    for (const key of Object.keys(logisticsData)) {
      if (logisticsData[key]) {
        data[key] = logisticsData[key];
      }
    }
    return data;
  };

  if (shipment == null) return;

  return (
    <div className="pageViewModel">
      <div className="controls">
        {roleAccess[userRole].permissions.Shipments.edit && (
          <button
            onClick={() => {
              navigate('edit');
            }}
          >
            {interface_translate['Edit'][language]}
          </button>
        )}
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
        {roleAccess[userRole].permissions['Shipments'].assign_storage_cell && (
          <SelectStorageCellBtn
            btnName={interface_translate['Assign storage cell'][language]}
            setSelectedStorageCell={async (storage_cell) => {
              const result = await updateStorageCell(storage_cell);
              return result;
            }}
            errors={selectStorageCellErrors}
          />
        )}
        {shipment.status == 'created' && roleAccess[userRole].permissions['Shipments'].ready_to_ship && (
          <button
            onClick={async (e) => {
              setViewReadyToShipConfirm(true);
            }}
          >
            {interface_translate['Ready to ship'][language]}
          </button>
        )}
        {shipment.status == 'ready_to_ship' && (
          <button
            onClick={(e) => {
              setViewEnterWhiteLogisticsModalWindow(true);
            }}
          >
            {interface_translate['White delivery'][language]}
          </button>
        )}
        {shipment.status == 'ready_to_ship' && (
          <button
            onClick={(e) => {
              setViewEnterCargoLogisticsModalWindow(true);
            }}
          >
            {interface_translate['Cargo delivery'][language]}
          </button>
        )}
      </div>
      {viewReadyToShipConfirm && (
        <div className={styles}>
          <ConfirmDialog
            message="Подтвердить действие?"
            confirmMsg="Да"
            cancelMsg="Нет"
            onConfirm={async () => {
              await set_status_ready_to_ship(
                shipment.id,
                () => {},
                setGlobalError,
                resetGlobalMsg,
                language
              );
              requestGetShipment();
              setViewReadyToShipConfirm(false);
            }}
            onCancel={() => setViewReadyToShipConfirm(false)}
          />
        </div>
      )}
      {viewEnterWhiteLogisticsModalWindow && (
        <ModalWindow
          isActive={viewEnterWhiteLogisticsModalWindow}
          setIsActive={(v) => {
            setLogisticsDataErrors({});
            setViewEnterWhiteLogisticsModalWindow(v);
          }}
        >
          <div className={styles.AddLogisticsData}>
            <div className={styles.controlBtns}>
              <button
                onClick={async () => {
                  const data = getDataForEnterLogistics('white');
                  if (!data) return;

                  const result = await shipment_enter_white_logistics(
                    shipment.id,
                    data,
                    setLogisticsDataErrors,
                    setGlobalError,
                    resetGlobalMsg,
                    language
                  );
                  if (result) {
                    setGlobalMsg(
                      interface_translate['The sending data has been added'][
                        language
                      ]
                    );
                    resetGlobalError();
                    requestGetShipment();
                    setViewEnterWhiteLogisticsModalWindow(false);
                    setLogisticsData({});
                    requestGetShipmentLogistics();
                  }
                }}
              >
                {interface_translate['Create'][language]}
              </button>
              <button
                onClick={() => {
                  setLogisticsDataErrors({});
                  setViewEnterWhiteLogisticsModalWindow(false);
                }}
              >
                {interface_translate['Back'][language]}
              </button>
              <button
                onClick={() => {
                  navigate('..', { relative: 'path' });
                }}
              >
                {interface_translate['Exit'][language]}
              </button>
            </div>
            <WhiteLogisticsCreateForm
              data={logisticsData}
              setData={setLogisticsData}
              errors={logisticsDataErrors}
              setErrors={setLogisticsDataErrors}
            />
          </div>
        </ModalWindow>
      )}
      {viewEnterCargoLogisticsModalWindow && (
        <ModalWindow
          isActive={viewEnterCargoLogisticsModalWindow}
          setIsActive={(v) => {
            setLogisticsDataErrors({});
            setViewEnterCargoLogisticsModalWindow(v);
          }}
        >
          <div className={styles.AddLogisticsData}>
            <div className={styles.controlBtns}>
              <button
                onClick={async () => {
                  const data = getDataForEnterLogistics('cargo');
                  if (!data) return;

                  const result = await shipment_enter_cargo_logistics(
                    shipment.id,
                    data,
                    setLogisticsDataErrors,
                    setGlobalError,
                    resetGlobalMsg,
                    language
                  );
                  if (result) {
                    setGlobalMsg(
                      interface_translate['The sending data has been added'][
                        language
                      ]
                    );
                    resetGlobalError();
                    requestGetShipment();
                    setViewEnterCargoLogisticsModalWindow(false);
                    setLogisticsData({});
                    requestGetShipmentLogistics();
                  }
                }}
              >
                {interface_translate['Create'][language]}
              </button>
              <button
                onClick={() => {
                  setLogisticsDataErrors({});
                  setViewEnterCargoLogisticsModalWindow(false);
                }}
              >
                {interface_translate['Exit'][language]}
              </button>
            </div>
            <CargoLogisticsCreateForm
              data={logisticsData}
              setData={setLogisticsData}
              errors={logisticsDataErrors}
              setErrors={setLogisticsDataErrors}
            />
          </div>
        </ModalWindow>
      )}
      {shipment && (
        <ShipmentRead
          shipment={shipment}
          shipmentLogistics={shipmentLogistics}
        />
      )}
      {!shipment && <div>Пусто</div>}
    </div>
  );
};

export default ShipmentPage;
