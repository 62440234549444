import { useState, useRef, useEffect } from 'react';
import { IoIosSettings } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { TiArrowSortedDown } from 'react-icons/ti';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import styles from './ModelsViewBox.module.css';

const ModelsViewBox = (props) => {
  const {
    tableAttrs,
    models,
    useSelected = true,
    setSelectedModels,
    sortData,
    setSortData,
    settings,
    showSetting,
    toggleShowSettings,
    handleModelClick,
  } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const settingsMenuRef = useRef(null);
  const settingsBtnRef = useRef(null);

  const handleCheckboxAll = (event) => {
    if (event.target.checked) {
      const newSelectedRows = models.map((model) => model.id);
      setSelectedRows(newSelectedRows);
      setSelectedModels(newSelectedRows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleCheckboxModel = (event) => {
    const index = Number(event.target.value);
    let new_selected = [];
    if (event.target.checked) {
      new_selected = [...selectedRows, index];
    } else {
      new_selected = selectedRows.filter((v) => v !== index);
    }
    setSelectedRows(new_selected);
    setSelectedModels(new_selected);
  };

  return (
    <div className={styles.modelViewBox}>
      <table>
        <thead>
          <tr>
            {useSelected && (
              <th>
                <input
                  type="checkbox"
                  value="all"
                  onChange={handleCheckboxAll}
                />
              </th>
            )}
            {tableAttrs.map((attr_info, index) => {
              return (
                <TableHeader
                  attr={attr_info.name}
                  name_for_display={attr_info.translate_name_for_display}
                  use_sort={attr_info.use_sort}
                  key={index}
                  sortData={sortData}
                  setSortData={setSortData}
                />
              );
            })}
            {settings && (
              <th className={styles.settings}>
                <button
                  className={styles.settingsBtn}
                  onClick={toggleShowSettings}
                  ref={settingsBtnRef}
                >
                  <IoIosSettings />
                  <TiArrowSortedDown />
                </button>
                {showSetting && (
                  <div
                    className={styles.settingsMenu}
                    ref={settingsMenuRef}
                  >
                    {settings}
                  </div>
                )}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {models.map((model, index) => {
            const attrs_value = [];
            tableAttrs.forEach((attr_info) => {
              attrs_value.push(model[attr_info.name]);
            });
            return (
              <TableRow
                attrs_value={attrs_value}
                index={index}
                useCheckbox={useSelected}
                checked={selectedRows.includes(model.id)}
                checkboxValue={model.id}
                handleCheckbox={handleCheckboxModel}
                handleModelClick={() => {
                  handleModelClick(model);
                }}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ModelsViewBox;
