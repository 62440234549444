import { useState, useEffect } from 'react';
import ModalWindow from './ModalWindow';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import apiRequester from '../ApiRequester';
import useCommon from '../hooks/useCommon';
import storehouseService from '../services/storehouse';
import storageCellService from '../services/storage_cell';
import { api_error_translate } from '../texts/error';
import { interface_translate } from '../texts/interface';

import styles from './SelectStorageCellBtn.module.css';

async function get_storehouses(filters = {}, size = 50) {
  const storehousesPaginated = await storehouseService.get_by_filters(filters, {
    size: size,
  });
  return storehousesPaginated.data;
}

async function get_storage_cells(filters = {}, size = 50) {
  const storageCellsPaginated = await storageCellService.get_by_filters(
    filters,
    { size: size }
  );
  return storageCellsPaginated.data;
}

const SelectStorageCellBtn = (props) => {
  const { btnName, setSelectedStorageCell, errors } = props;
  const { resetGlobalError, setGlobalMsg, language } = useCommon();
  const [selectStorageCellData, setSelectStorageCellData] = useState({
    storehouseElementsForSelect: [],
    storageCellElementsForSelect: [],
  });
  const [viewSelectStorageCell, setViewSelectStorageCell] = useState(false);

  const clearSelectStorageCellData = () => {
    setSelectStorageCellData({
      storehouseElementsForSelect:
        selectStorageCellData.storehouseElementsForSelect,
      storageCellElementsForSelect: [],
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (selectStorageCellData.storehouseInputValue)
        filters['name'] = selectStorageCellData.storehouseInputValue;
      const new_elements = await get_storehouses(filters);
      setSelectStorageCellData({
        ...selectStorageCellData,
        storehouseElementsForSelect: new_elements,
      });
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [selectStorageCellData.storehouseInputValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (!selectStorageCellData.storehouse) return;
      let filters = { storehouse_id: selectStorageCellData.storehouse.id };
      console.log(selectStorageCellData.storageCellInputValue);
      if (selectStorageCellData.storageCellInputValue)
        filters['storage_cell_name'] =
          selectStorageCellData.storageCellInputValue;
      const new_elements = await get_storage_cells(filters);
      setSelectStorageCellData({
        ...selectStorageCellData,
        storageCellElementsForSelect: new_elements,
      });
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [selectStorageCellData.storageCellInputValue]);

  return (
    <div>
      <button
        onClick={() => {
          setViewSelectStorageCell(true);
        }}
      >
        {btnName}
      </button>
      {viewSelectStorageCell && (
        <ModalWindow
          isActive={viewSelectStorageCell}
          setIsActive={(v) => {
            clearSelectStorageCellData();
            setViewSelectStorageCell(v);
          }}
        >
          <div className={styles.selectStorageCellContainer}>
            <div>
              <button
                onClick={async () => {
                  const storageCell = selectStorageCellData.storageCell;
                  if (!storageCell) return;
                  const result = setSelectedStorageCell(storageCell);
                  if (result) {
                    clearSelectStorageCellData();
                    setViewSelectStorageCell(false);
                  }
                }}
              >
                {interface_translate['Save'][language]}
              </button>
              <button
                onClick={() => {
                  clearSelectStorageCellData();
                  setViewSelectStorageCell(false);
                }}
              >
                {interface_translate['Exit'][language]}
              </button>
            </div>
            <div className="openingBlockAttr">
              <div className="openingBlockAttrName">
                {interface_translate['Storehouse'][language]}
              </div>
              <Autocomplete
                value={selectStorageCellData.storehouse}
                onChange={async (e, newElement) => {
                  let new_storage_cells = [];
                  if (newElement) {
                    const filters = { storehouse_id: newElement.id };
                    new_storage_cells = await get_storage_cells(filters);
                  }
                  setSelectStorageCellData((prevData) => {
                    prevData.storehouse = newElement;
                    prevData.storageCell = null;
                    prevData.storageCellElementsForSelect = new_storage_cells;
                    return { ...prevData };
                  });
                }}
                options={selectStorageCellData.storehouseElementsForSelect}
                getOptionLabel={(model) => model.name}
                onInputChange={(e, value) => {
                  setSelectStorageCellData((prevData) => {
                    prevData.storehouseInputValue = value;
                    prevData.storehouseElementsForSelect = [];
                    return { ...prevData };
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={interface_translate['Storehouse'][language]}
                    size="small"
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={
                  selectStorageCellData.storehouseElementsForSelect.length == 0
                }
              />
            </div>
            <div className="openingBlockAttr">
              <div className="openingBlockAttrName">
                {interface_translate['Storage cell'][language]}
              </div>
              <Autocomplete
                value={selectStorageCellData.storageCell}
                disabled={!selectStorageCellData.storehouse}
                onChange={(e, newElement) => {
                  setSelectStorageCellData((prevData) => {
                    return {
                      ...prevData,
                      storageCell: newElement,
                    };
                  });
                }}
                options={selectStorageCellData.storageCellElementsForSelect}
                getOptionLabel={(model) =>
                  `${model.shelving.name}${model.number}`
                }
                onInputChange={(e, value) => {
                  setSelectStorageCellData((prevData) => {
                    prevData.storageCellInputValue = value;
                    prevData.storageCellElementsForSelect = [];
                    return { ...prevData };
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={interface_translate['Storage cell'][language]}
                    size="small"
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={
                  selectStorageCellData.storageCellElementsForSelect.length == 0
                }
              />
            </div>
            {errors['storage_cell_id'] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[errors['storage_cell_id'][0]][language]}
              </div>
            )}
          </div>
        </ModalWindow>
      )}
    </div>
  );
};

export default SelectStorageCellBtn;
