import { useState, React, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { api_error_translate } from '../../texts/error';
import { interface_translate } from '../../texts/interface';
import useCommon from '../../hooks/useCommon';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import roleAccess from '../../roleAccess';
import shelvingService from '../../services/shelving';
import storehouseService from '../../services/storehouse';
import styles from './ShelvingCreatePage.module.css';
import '../shared.css';

async function create(
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await shelvingService.create(
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_storehouses(filters = {}, size = 50) {
  const storehousePaginated = await storehouseService.get_by_filters(filters, {
    size: size,
  });
  return storehousePaginated.data;
}

async function get_storehouse(id) {
  const storehouse = storehouseService.get(id);
  return storehouse;
}

const ShelvingCreatePage = (props) => {
  const { storehouseId, addShelving, handleExit } = props;
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState({});
  const [storehouseInputValue, setStorehouseInputValue] = useState(null);
  const [storehouseElementsForSelect, setStorehouseElementsForSelect] =
    useState([]);
  const [attrsForCreate, setAttrsForCreate] = useState(new Set());

  useEffect(() => {
    const req = async () => {
      if (storehouseId) {
        const storehouse = await get_storehouse(storehouseId);
        console.log(storehouse);
        setModelData({ storehouse: storehouse });
      }
    };
    req();
    pushPage(location.pathname);
    const attrsForCreate = new Set(
      roleAccess[userRole].fieldRestrictions.Shelvings?.create
    );
    setAttrsForCreate(attrsForCreate);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (storehouseInputValue) filters['name'] = storehouseInputValue;
      const new_elements = await get_storehouses(filters);
      setStorehouseElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storehouseInputValue]);

  const getDataFromAttr = () => {
    const data = {
      ...modelData,
      name: modelData.name,
      storehouse_id: modelData.storehouse.id,
    };
    return data;
  };

  if (!modelData.storehouse) return;

  return (
    <div className="pageCreateModel">
      <div className={styles.saveAndExitBtns}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await create(
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg('Стеллаж создан');
              resetGlobalError();
              setErrors({});
              addShelving(result);
              if (handleExit) {
                handleExit();
                return;
              }
              navigate('..', { relative: 'path' });
            }
          }}
        >
          {interface_translate['Create'][language]}
        </button>
      </div>
      <div className="inputData">
        <OpeningBlock
          title={interface_translate['About shelving'][language]}
          open={true}
        >
          {attrsForCreate.has('name') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Name'][language]}
                </div>
                <input
                  type="text"
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['name'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['name'][0]]['ru']}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('storehouse_name') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Storehouse'][language]}
                </div>
                <Autocomplete
                  value={modelData.storehouse}
                  onChange={(e, newElement) => {
                    setModelData({
                      ...modelData,
                      storehouse: newElement,
                    });
                  }}
                  options={storehouseElementsForSelect}
                  getOptionLabel={(model) => model.name}
                  onInputChange={(e, value) => {
                    setStorehouseInputValue(value);
                    setStorehouseElementsForSelect([]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={interface_translate['Storehouse'][language]}
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  loading={storehouseElementsForSelect.length == 0}
                />
              </div>
              {errors['storehouse_id'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['storehouse_id'][0]]['ru']}
                </div>
              )}
            </div>
          )}
        </OpeningBlock>
      </div>
    </div>
  );
};

export default ShelvingCreatePage;
