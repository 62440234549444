import { useState, useEffect } from 'react';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import ModelView from '../../components/models_view/ModelView.jsx';
import ModelsControls from '../../components/models_view/ModelsControls.jsx';
import ModelFilters from '../../components/ModelFilters.jsx';
import TextFilter from '../../components/filters/TextFilter.jsx';
import SelectFilter from '../../components/filters/SelectFilter.jsx';
import AutocompleteFilter from '../../components/filters/AutocompleteFilter.jsx';
import unidentifiedCargoService from '../../services/unidentified_cargo.js';
import roleAccess from '../../roleAccess.js';
import userService from '../../services/user.js';
import useViewSettings from '../../hooks/useViewSettings.jsx';
import { statuses_translate } from '../../texts/statuses.js';
import { api_error_translate } from '../../texts/error.js';
import { interface_translate } from '../../texts/interface.js';
import '../../App.css';
import styles from './UnidentifiedCargoListPage.module.css';
import { useLocation } from 'react-router-dom';

async function get_unidentified_cargo_list_by_filters(
  filterData,
  paginationSettings
) {
  const unidentifiedCargoListPaginated =
    await unidentifiedCargoService.get_by_filters(
      filterData,
      paginationSettings
    );
  return unidentifiedCargoListPaginated.data;
}

async function delete_unidentified_cargo(id) {
  const result = await unidentifiedCargoService.delete(id);
  return result;
}

async function get_unidentified_cargo_statuses() {
  const statuses = await unidentifiedCargoService.get_statuses();
  return statuses;
}

async function get_users(filters = {}, size = 50) {
  const usersPaginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return usersPaginated.data;
}

const table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'unidentified cargo id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'comment',
    name_for_display: 'comment',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'invoice_number',
    name_for_display: 'invoice number',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'status_translate',
    name_for_display: 'status',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const UnidentifiedCargoListPage = () => {
  const { language, userRole } = useCommon();
  const location = useLocation();
  const { clearHistoryPages, pushPage } = usePageMovementHistory();
  const { allViewSettings, setAllViewSettings } = useViewSettings();
  const viewSettings = allViewSettings['unidentified_cargo'];
  const [filterData, setFiltersData] = useState(
    viewSettings['filterData'] || {}
  );
  const [showFilters, setShowFilters] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    size: viewSettings['size'],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [unidentified_cargo_list, setUnidentifiedCargoList] = useState([]);
  const [unidentifiedCargoStatuses, setUnidentifiedCargoStatuses] = useState(
    []
  );
  const [selectedModelIds, setSelectedModelIds] = useState([]);
  const [sortData, setSortData] = useState({});
  const [tableAttrs, setTableAttrs] = useState([]);

  useEffect(() => {
    const req = async () => {
      const unidentifiedCargoStatuses = await get_unidentified_cargo_statuses();
      setUnidentifiedCargoStatuses(unidentifiedCargoStatuses);
    };
    req();
    clearHistoryPages();
    pushPage(location.pathname);
    const table_attrs = table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions[
          'Unidentified cargo list'
        ].read_all.includes(attr_info.name)
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setTableAttrs(table_attrs);
  }, []);

  useEffect(() => {
    for (const unidentified_cargo of unidentified_cargo_list) {
      unidentified_cargo.status_translate =
        statuses_translate[unidentified_cargo.status][language];
    }
    setUnidentifiedCargoList([...unidentified_cargo_list]);
  }, [language]);

  useEffect(() => {
    setAllViewSettings({
      unidentified_cargo: {
        size: paginationSettings['size'],
        filterData: filterData,
      },
    });
  }, [paginationSettings, filterData]);

  useEffect(() => {
    if (sortData.by === 'status_translate') {
      sortData.by = 'status';
    }

    const newPaginationSettings = {
      ...paginationSettings,
      sort_by: sortData.by,
      sort_order: sortData.order,
    };
    setPaginationSettings(newPaginationSettings);
  }, [sortData]);

  const mapFilterData = () => {
    const data = { ...filterData };
    if (filterData.responsible)
      data['responsible_id'] = filterData.responsible.id;
    if (filterData.co_executors)
      data['co_executor_id'] = filterData.co_executors.id;
    delete data['client'];
    delete data['responsible'];
    delete data['co_executors'];
    return data;
  };

  const requestGetUnidentifiedCargoList = async () => {
    let new_unidentified_cargo_list = [];
    const filter = mapFilterData();
    new_unidentified_cargo_list = await get_unidentified_cargo_list_by_filters(
      filter,
      paginationSettings
    );
    if (!new_unidentified_cargo_list) return;

    for (const unidentified_cargo of new_unidentified_cargo_list) {
      unidentified_cargo.status_translate =
        statuses_translate[unidentified_cargo.status][language];
    }

    setUnidentifiedCargoList(new_unidentified_cargo_list);
    setCurrentPage(paginationSettings['page']);
  };

  const addFiltersData = (attr, value) => {
    if (value) {
      setFiltersData({ ...filterData, [attr]: value });
    } else if (filterData.hasOwnProperty(attr)) {
      delete filterData[attr];
      setFiltersData({ ...filterData });
    }
  };
  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSetPaginationSettings = (settings) => {
    setPaginationSettings({ ...paginationSettings, ...settings });
  };

  useEffect(() => {
    requestGetUnidentifiedCargoList();
  }, [paginationSettings]);

  const deleteModels = async () => {
    setUnidentifiedCargoList(
      unidentified_cargo_list.filter(
        (model) => !selectedModelIds.includes(model.id)
      )
    );
    for (const id of selectedModelIds) await delete_unidentified_cargo(id);
  };

  return (
    <div>
      <ModelsControls
        section="Unidentified cargo list"
        model_name={interface_translate['Unidentified cargo list'][language]}
        filtersActivated={Object.keys(filterData).length > 0}
        toggleShowFilters={toggleShowFilters}
        requestModels={requestGetUnidentifiedCargoList}
        deleteModels={deleteModels}
      />
      {showFilters && (
        <ModelFilters
          requestModels={requestGetUnidentifiedCargoList}
          resetFilterData={() => setFiltersData({})}
        >
          <TextFilter
            title_name={interface_translate['Comment'][language]}
            attr="comment"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['comment'] != undefined ? filterData['comment'] : ''
            }
          />
          <SelectFilter
            title_name={interface_translate['Status'][language]}
            attr="status"
            select_names={[
              '',
              ...unidentifiedCargoStatuses.map(
                (m) => statuses_translate[m][language]
              ),
            ]}
            select_attr={['', ...unidentifiedCargoStatuses]}
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['status'] != undefined ? filterData['status'] : ''
            }
          />
          <AutocompleteFilter
            title_name={interface_translate['Responsible'][language]}
            attr="responsible"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['responsible'] != undefined
                ? filterData['responsible']
                : null
            }
            getElementLabel={(option) => option.email}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['email'] = value;
              const responsible = await get_users(filters);
              return responsible;
            }}
          />
        </ModelFilters>
      )}
      <ModelView
        tableAttrs={tableAttrs}
        models={unidentified_cargo_list}
        setSelectedModels={setSelectedModelIds}
        sortData={sortData}
        setSortData={setSortData}
        setPaginationSettings={handleSetPaginationSettings}
        currentPage={currentPage}
        currentSize={paginationSettings['size']}
      />
    </div>
  );
};

export default UnidentifiedCargoListPage;
