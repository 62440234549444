import { useState } from 'react';
import { interface_translate } from '../texts/interface';
import useCommon from '../hooks/useCommon';
import styles from './ConfirmDialog.module.css';

function ConfirmDialog({
  message,
  onConfirm,
  onCancel,
  confirmMsg,
  cancelMsg,
}) {
  const { language } = useCommon();
  const [isOpen, setIsOpen] = useState(true);
  if (!confirmMsg) confirmMsg = interface_translate['OK'][language];
  if (!cancelMsg) cancelMsg = interface_translate['Cancel'][language];

  const handleConfirm = () => {
    setIsOpen(false);
    onConfirm();
  };

  const handleCancel = () => {
    setIsOpen(false);
    onCancel();
  };

  if (!isOpen) return null;
  return (
    <div className={styles.confirmDialog}>
      <div>
        <p className={styles.message}>{message}</p>
        <button onClick={handleConfirm}>{confirmMsg}</button>
        <button onClick={handleCancel}>{cancelMsg}</button>
        
      </div>
    </div>
  );
}

export default ConfirmDialog;
