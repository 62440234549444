import { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
import { CommonProvider } from './context/CommonProvider';
import { ViewSettingsProvider } from './context/ViewSettingsProvider';
import { PageMovementHistoryProvider } from './context/PageMovementHistoryProvider';
import Header from './components/header/Header';
import useRoutes from './routes/routes';
import GlobalError from './components/GlobalError';
import GlobalMsg from './components/GlobalMsg';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');
  const resetError = () => {
    setError('');
  };
  const resetMsg = () => {
    setMsg('');
  };
  const routes = useRoutes();
  return (
    <Router>
      <AuthProvider>
        <ViewSettingsProvider>
          <CommonProvider
            globalError={error}
            resetGlobalError={resetError}
            setGlobalError={setError}
            globalMsg={msg}
            resetGlobalMsg={resetMsg}
            setGlobalMsg={setMsg}
          >
            <PageMovementHistoryProvider>
              <div className="App">
                <Header />
                <GlobalError
                  error={error}
                  resetError={resetError}
                />
                <GlobalMsg
                  msg={msg}
                  resetMsg={resetMsg}
                />
                <div className="view">{routes}</div>
              </div>
            </PageMovementHistoryProvider>
          </CommonProvider>
        </ViewSettingsProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
