import { useState } from 'react';
import Filter from './Filter';

const TextFilter = (props) => {
  const { title_name, attr, addFiltersData, defaultValue } = props;

  return (
    <Filter title_name={title_name}>
      <input
        type="text"
        name={attr}
        value={defaultValue}
        onChange={(e) => {
          addFiltersData(attr, e.target.value);
        }}
      />
    </Filter>
  );
};

export default TextFilter;
