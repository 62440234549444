import { useState, useEffect } from 'react';
import { api_error_translate } from '../../texts/error';
import { model_names } from '../../texts/model_names';
import { useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import ModelView from '../../components/models_view/ModelView.jsx';
import ModelsControls from '../../components/models_view/ModelsControls.jsx';
import ModelFilters from '../../components/ModelFilters.jsx';
import TextFilter from '../../components/filters/TextFilter.jsx';
import AutocompleteFilter from '../../components/filters/AutocompleteFilter.jsx';
import apiRequester from '../../ApiRequester.js';
import roleAccess from '../../roleAccess.js';
import storehouseService from '../../services/storehouse.js';
import { interface_translate } from '../../texts/interface.js';
import useViewSettings from '../../hooks/useViewSettings.jsx';
import '../../App.css';

async function get_storehouses_by_filters(filterData, paginationSettings) {
  const storehousePaginated = await storehouseService.get_by_filters(
    filterData,
    paginationSettings
  );
  return storehousePaginated.data;
}

async function delete_storehouse(id, setGlobalError, language) {
  const result = await storehouseService.delete(id, setGlobalError, language);
  return result;
}

const table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'storehouse id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'name',
    name_for_display: 'name',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'address',
    name_for_display: 'address',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const StorehousesPage = () => {
  const { setGlobalError, language, userRole } = useCommon();
  const { allViewSettings, setAllViewSettings } = useViewSettings();
  const viewSettings = allViewSettings['storehouses'];
  const location = useLocation();
  const { clearHistoryPages, pushPage } = usePageMovementHistory();
  const [filterData, setFiltersData] = useState(
    viewSettings['filterData'] || {}
  );
  const [showFilters, setShowFilters] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    size: viewSettings['size'],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [storehouses, setStorehouses] = useState([]);
  const [selectedModelIds, setSelectedModelIds] = useState([]);
  const [sortData, setSortData] = useState({});
  const [tableAttrs, setTableAttrs] = useState([]);

  useEffect(() => {
    clearHistoryPages();
    pushPage(location.pathname);
    const table_attrs = table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions.Storehouses.read_all.includes(
          attr_info.name
        )
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setTableAttrs(table_attrs);
  }, []);

  useEffect(() => {
    setAllViewSettings({
      storehouses: { size: paginationSettings['size'], filterData: filterData },
    });
  }, [paginationSettings, filterData]);

  useEffect(() => {
    const newPaginationSettings = {
      ...paginationSettings,
      sort_by: sortData.by,
      sort_order: sortData.order,
    };
    setPaginationSettings(newPaginationSettings);
  }, [sortData]);

  const mapFilterData = () => {
    const data = { ...filterData };
    if (filterData.name === '') delete data['name'];
    if (filterData.address === '') delete data['address'];
    return data;
  };

  const requestGetStorehouses = async () => {
    let new_storehouses = [];
    const filter = mapFilterData();
    new_storehouses = await get_storehouses_by_filters(
      filter,
      paginationSettings
    );
    if (!new_storehouses) return;

    setStorehouses(new_storehouses);
    setCurrentPage(paginationSettings['page']);
  };

  const addFiltersData = (attr, value) => {
    if (value) {
      setFiltersData({ ...filterData, [attr]: value });
    } else if (filterData.hasOwnProperty(attr)) {
      delete filterData[attr];
      setFiltersData({ ...filterData });
    }
  };

  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSetPaginationSettings = (settings) => {
    setPaginationSettings({ ...paginationSettings, ...settings });
  };

  useEffect(() => {
    requestGetStorehouses();
  }, [paginationSettings]);

  const deleteModels = async () => {
    setStorehouses(
      storehouses.filter((model) => !selectedModelIds.includes(model.id))
    );
    for (const id of selectedModelIds) {
      const result = await delete_storehouse(id, setGlobalError, language);
      if (!result) {
        requestGetStorehouses();
      }
    }
  };
  return (
    <div>
      <ModelsControls
        section="Storehouses"
        model_name={interface_translate['Storehouses'][language]}
        filtersActivated={Object.keys(filterData).length > 0}
        toggleShowFilters={toggleShowFilters}
        requestModels={requestGetStorehouses}
        deleteModels={deleteModels}
      />
      {showFilters && (
        <ModelFilters
          requestModels={requestGetStorehouses}
          resetFilterData={() => setFiltersData({})}
        >
          <TextFilter
            title_name={interface_translate['Name'][language]}
            attr="name"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['name'] != undefined ? filterData['name'] : ''
            }
          />
          <TextFilter
            title_name={interface_translate['Address'][language]}
            attr="address"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['address'] != undefined ? filterData['address'] : ''
            }
          />
        </ModelFilters>
      )}
      <ModelView
        tableAttrs={tableAttrs}
        models={storehouses}
        setSelectedModels={setSelectedModelIds}
        sortData={sortData}
        setSortData={setSortData}
        setPaginationSettings={handleSetPaginationSettings}
        currentPage={currentPage}
        currentSize={paginationSettings['size']}
      />
    </div>
  );
};

export default StorehousesPage;
