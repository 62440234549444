export const statuses_translate = {
  supplier_sent_product: {
    ru: 'Поставщик отправил товар',
    en: 'Supplier sent product',
    ch: '供应商已发货',
  },
  product_received_in_china_storehouse: {
    ru: 'Товар получен на складе в Китае',
    en: 'Product received in china storehouse',
    ch: '产品存放到中国仓库',
  },
  product_is_checked: {
    ru: 'Товар проверен',
    en: 'Product is checked',
    ch: '产品已查过',
  },
  ordered: { ru: 'Заказано', en: 'ordered', ch: '' },
  received_in_chine_storehouse: {
    ru: 'Получено на складе в Китае',
    en: 'received_in_chine_storehouse',
    ch: '',
  },
  supplier_has_been_found: {
    ru: 'Поставщик найден',
    en: 'supplier_has_been_found',
    ch: '',
  },
  awaiting_approval: {
    ru: 'Ожидает подтверждения',
    en: 'awaiting approval',
    ch: '',
  },
  received_in_russia_storehouse: {
    ru: 'Получено на складе в России',
    en: 'received_in_russia_storehouse',
    ch: '',
  },
  return_to_supplier: {
    ru: 'Возврат поставщику',
    en: 'Return to supplier',
    ch: '退货供应商',
  },
  sent_to_supplier: {
    ru: 'Отправлено поставщику',
    en: 'Sent to supplier',
    ch: '发送供应商',
  },
  created: { ru: 'Создано', en: 'Created', ch: '创建' },
  return_to_transport_company: {
    ru: 'Возвращена транспортной компании',
    en: 'Return to transport company',
    ch: '退物流公司',
  },
  ready_to_ship: {
    ru: 'Готово к отправке',
    en: 'Ready to ship',
    ch: '准备装运',
  },
  shipped: { ru: 'Отправлено', en: 'Shipped', ch: '已装运' },
  on_way: { ru: 'В пути', en: 'On way', ch: '路上' },
  passed_border: { ru: 'Прошло границу', en: 'Passed border', ch: '过了边境' },
  received: { ru: 'Получено', en: 'Received', ch: '收到' },
  entered_in_storehouse: {
    ru: 'Поступил на склад',
    en: 'Entered in storehouse',
    ch: '收到仓库',
  },
  checked: { ru: 'Проверен', en: 'Checked', ch: '已核实' },
  documents_loaded: {
    ru: 'документы загружены',
    en: 'documents loaded',
    ch: '',
  },
  ready_to_issue: {
    ru: 'Готов к выдаче',
    en: 'Ready to issue',
    ch: '准备出发',
  },
  sent_for_storage: {
    ru: 'Отправлен на хранение',
    en: 'Sent for storage',
    ch: '发送存储',
  },
  sent_to_fullfilment: {
    ru: 'Отправлен в Фуллфилмент',
    en: 'Sent to fullfilment',
    ch: '发送履行',
  },
  client_refused_cargo: {
    ru: 'Клиент отказался от груза',
    en: 'Client refused cargo',
    ch: '客户拒绝装运',
  },
  issued: { ru: 'Выдан', en: 'Issued', ch: '已发出' },
  transaction_is_completed: {
    ru: 'Сделка завершена',
    en: 'Transaction is completed',
    ch: '交易完成',
  },
  wrong_product: {
    ru: 'Неправильный товар',
    en: 'Wrong product',
    ch: '错误产品',
  },
  not_identified: { ru: 'Не опознан', en: 'Not identified', ch: '未识别' },
  waiting_response_from_storehouse: {
    ru: 'Ожидает ответа склада',
    en: 'Waiting response from storehouse',
    ch: '等待仓库响应',
  },
  completed: { ru: 'Завершен', en: 'Completed', ch: '完成' },
};
