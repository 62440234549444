import React from 'react';
import useCommon from '../hooks/useCommon';
import { interface_translate } from '../texts/interface';

const Bad = () => {
  const { language } = useCommon();

  return (
    <div>
      {interface_translate['This page is not available to you'][language]}
    </div>
  );
};

export default Bad;
