import { useState, useEffect } from 'react';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import '../../App.css';
import styles from './SortHeader.module.css';

const SortHeader = (props) => {
  const { header_attr, btnShow, sortData, setSortData } = props;
  const [sortingDirection, setSortingDirection] = useState(null);

  useEffect(() => {
    if (sortData && sortData.by !== header_attr) setSortingDirection(null);
  }, [sortData]);

  const toggleBtn = () => {
    let direction = '';
    if (sortingDirection === null) direction = 'ASC';
    else if (sortingDirection === 'ASC') direction = 'DESC';
    else if (sortingDirection === 'DESC') direction = 'ASC';
    setSortingDirection(direction);
    setSortData({ by: header_attr, order: direction });
  };

  return (
    <div
      className={
        !btnShow && !sortingDirection
          ? `${styles.icons} transparent`
          : styles.icons
      }
      onClick={toggleBtn}
    >
      {(!sortingDirection || sortingDirection === 'DESC') && (
        <TiArrowSortedDown styles={{ heignt: '1rem' }} />
      )}
      {(!sortingDirection || sortingDirection === 'ASC') && (
        <TiArrowSortedUp styles={{ width: '1rem' }} />
      )}
    </div>
  );
};

export default SortHeader;
