import { createContext } from 'react';
const PageMovementHistoryContext = createContext(null);
let pageStack = [];

export const PageMovementHistoryProvider = ({ children }) => {
  const pushToStack = (page) => {
    if (pageStack[pageStack.length - 1] !== page) {
      pageStack.push(page);
    }
  };

  const popFromStack = () => {
    pageStack.pop();
  };

  const getCurrent = () => {
    if (pageStack.length > 0) {
      return pageStack[pageStack.length - 1];
    }
  };

  const getPrevious = () => {
    if (pageStack.length > 1) {
      return pageStack[pageStack.length - 2];
    }
  };

  const clearStack = () => {
    pageStack = [];
  };

  return (
    <PageMovementHistoryContext.Provider
      value={{
        pushPage: pushToStack,
        popPage: popFromStack,
        clearHistoryPages: clearStack,
        getCurrentPage: getCurrent,
      }}
    >
      {children}
    </PageMovementHistoryContext.Provider>
  );
};

export default PageMovementHistoryContext;
