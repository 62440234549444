import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { interface_translate } from '../texts/interface';
import useCommon from '../hooks/useCommon';
import { v4 as uuidv4 } from 'uuid';
import styles from './ListAttrAutocomplete.module.css';

const ListAttrMedia = (props) => {
  const { language } = useCommon()
  const {
    setSelectedElement,
    deleteSelectedElement,
    defaultElements,
  } = props;

  const [blocksId, setBlocksId] = useState([]);

  const removeElementByIndex = (id) => {
    deleteSelectedElement(id);
    setBlocksId((prevData) => {
      let index = prevData.indexOf(id);
      if (index !== -1) {
        prevData.splice(index, 1);
      }
      return [...prevData];
    });
  };

  const addElement = (def_id, def_element) => {
    let element = null;
    if (def_element) element = def_element;
    let id = uuidv4();
    if (def_id) id = def_id;
    setSelectedElement({ [id]: element });
    setBlocksId((prevData) => {
      const new_data = [...prevData, id];
      return new_data;
    });
  };

  useEffect(() => {
    if (!defaultElements) return;
    for (const element of defaultElements) {
      const id = uuidv4();
      addElement(id, element);
    }
  }, []);

  return (
    <div className={styles.listAttr}>
      {blocksId.map((id) => {
        return (
          <div
            className={styles.attr}
            id={id}
            key={id}
          >
            <input
              type="file"
              onChange={(e) => {setSelectedElement({[id]: e.target.files[0]})}}
            />
            <button
              onClick={() => {
                removeElementByIndex(id);
              }}
            >
              -
            </button>
          </div>
        );
      })}
      <button
        className={styles.addBtn}
        onClick={() => addElement()}
      >
        {interface_translate['Add'][language]}
      </button>
    </div>
  );
};

export default ListAttrMedia;
