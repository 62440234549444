import { useNavigate } from 'react-router-dom';
import styles from './TableHeader.module.css';

const TableRow = (props) => {
  const {
    attrs_value,
    index,
    useCheckbox,
    checked,
    checkboxValue,
    handleCheckbox,
    handleModelClick,
  } = props;
  return (
    <tr key={index}>
      {useCheckbox && (
        <td>
          <input
            type="checkbox"
            value={checkboxValue}
            checked={checked}
            onChange={handleCheckbox}
          />
        </td>
      )}
      {attrs_value.map((value, index) => (
        <td
          onClick={() => {
            handleModelClick();
          }}
          key={index}
        >
          {value}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
