import OpeningBlock from '../../components/OpeningBlock';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ModelView from '../../components/models_view/ModelView.jsx';
import DropDownMediaBlock from '../../components/DropDownMediaBlock';
import { getPrevViewImgAndMediaBlockByImageObj } from '../../utils/image.js';
import { types } from '../../texts/types.js';
import { interface_translate } from '../../texts/interface';
import roleAccess from '../../roleAccess.js';
import useCommon from '../../hooks/useCommon';
import styles from './ShipmentRead.module.css';

const product_table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'product id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'client_name',
    name_for_display: 'client',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'order_id',
    name_for_display: 'order',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'name',
    name_for_display: 'name',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'shipment_qty_with_unit',
    name_for_display: 'qty',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'price',
    name_for_display: 'price',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'currency',
    name_for_display: 'currency',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'supplier_name',
    name_for_display: 'supplier',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const ShipmentRead = (props) => {
  const { shipment, shipmentLogistics, viewProducts = true } = props;
  const { language, userRole } = useCommon();
  const navigate = useNavigate();
  const location = useLocation();
  const [attrsForRead, setAttrsForRead] = useState(new Set());
  const [productTableAttrs, setProductTableAttrs] = useState([]);

  useEffect(() => {
    const attrs_for_read = new Set(
      roleAccess[userRole].fieldRestrictions.Shipments?.read
    );
    setAttrsForRead(attrs_for_read);
    const product_table_attrs = product_table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions.Products.read_all.includes(
          attr_info.name
        )
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setProductTableAttrs(product_table_attrs);
  }, []);
  
  return (
    <div className="vertical align-start justify-start">
      <div
        className={`horizontal align-start justify-start ${styles.shipmentLogisticsData}`}
      >
        <div className="viewData">
          <OpeningBlock
            title={interface_translate['About shipment'][language]}
            open={true}
          >
            {attrsForRead.has('id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['shipment id'][language]}
                </div>
                <span>{shipment.id}</span>
              </div>
            )}
            {attrsForRead.has('order_id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Order'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(`/orders/${shipment.order_id}`, {
                      state: { from: location },
                    })
                  }
                >
                  {shipment.order_id}
                </span>
              </div>
            )}
            {attrsForRead.has('type_logistics_translate') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Type logistics'][language]}
                </div>
                <span>
                  {shipment.type_logistics
                    ? types[shipment.type_logistics][language]
                    : ''}
                </span>
              </div>
            )}
            {attrsForRead.has('packing_translate') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Packing'][language]}
                </div>
                <span>{shipment.packing_translate}</span>
              </div>
            )}
            {attrsForRead.has('status_translate') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Status'][language]}
                </div>
                <span>{shipment.status_translate}</span>
              </div>
            )}
            {attrsForRead.has('total_amount') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Total amount ₽'][language]}
                  </div>
                  <span>{shipment.total_amount}</span>
                </div>
              </div>
            )}
            {attrsForRead.has('weight_in_kg') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Weight in kg'][language]}
                  </div>
                  <span>{shipment.weight_in_kg}</span>
                </div>
              </div>
            )}
            {attrsForRead.has('volume_in_m3') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Volume in м³'][language]}
                  </div>
                  <span>{shipment.volume_in_m3}</span>
                </div>
              </div>
            )}
            {attrsForRead.has('number_of_seats') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Number of seats'][language]}
                  </div>
                  <span>{shipment.number_of_seats}</span>
                </div>
              </div>
            )}
            {attrsForRead.has('storehouse_name') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Storehouse'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(`/storehouses/${shipment.storehouse_id}`, {
                      state: { from: location },
                    })
                  }
                >
                  {shipment.storehouse_name}
                </span>
              </div>
            )}
            {attrsForRead.has('storage_cell_name') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Storage cell'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(`/storage_cells/${shipment.storage_cell.id}`, {
                      state: { from: location },
                    })
                  }
                >
                  {shipment.storage_cell_name}
                </span>
              </div>
            )}
            {attrsForRead.has('responsible') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Responsible'][language]}
                </div>
                <div className="listModels">
                  {shipment.responsible.map((model) => {
                    return (
                      <div
                        className="modelBlock"
                        key={model.id}
                      >
                        {`${model.surname} ${model.name} ${model.patronymic}`}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {attrsForRead.has('co_executors') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Co executors'][language]}
                </div>
                <div className="listModels">
                  {shipment.co_executors.map((model) => {
                    return (
                      <div
                        className="modelBlock"
                        key={model.id}
                      >
                        {`${model.surname} ${model.name} ${model.patronymic}`}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {attrsForRead.has('qr_code') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Qr code'][language]}
                </div>
                {shipment.barcode_img &&
                  (() => {
                    let { mediaBlock, preViewImg } =
                      getPrevViewImgAndMediaBlockByImageObj(
                        shipment.barcode_img,
                        language
                      );
                    return (
                      <DropDownMediaBlock
                        preViewImg={preViewImg}
                        mediaBlock={mediaBlock}
                      />
                    );
                  })()}
              </div>
            )}
            {attrsForRead.has('logistics_documents') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Documents logístics'][language]}
                </div>
                <div className="listDropDomnMedia">
                  {shipment.logistics_documents.map((image, id) => {
                    let { mediaBlock, preViewImg } =
                      getPrevViewImgAndMediaBlockByImageObj(image, language);
                    return (
                      <DropDownMediaBlock
                        key={id}
                        preViewImg={preViewImg}
                        mediaBlock={mediaBlock}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {attrsForRead.has('media_content') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Media content'][language]}
                </div>
                <div className="listDropDomnMedia">
                  {shipment.media_content.map((image, id) => {
                    let { mediaBlock, preViewImg } =
                      getPrevViewImgAndMediaBlockByImageObj(image, language);
                    return (
                      <DropDownMediaBlock
                        key={id}
                        preViewImg={preViewImg}
                        mediaBlock={mediaBlock}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </OpeningBlock>
        </div>
        {shipment.type_logistics == 'cargo' && shipmentLogistics && (
          <div className={`${styles.viewLogisticsData}`}>
            <OpeningBlock
              title={interface_translate['Cargo delivery'][language]}
              open={true}
            >
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Type of transport'][language]}
                </div>
                <span>{types[shipmentLogistics.type_transport][language]}</span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Departure date'][language]}
                </div>
                <span>{shipmentLogistics.departure_date}</span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Departure date'][language]}
                </div>
                <span>
                  {shipmentLogistics.estimated_date_arrival_in_moscow}
                </span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Total shipping cost'][language]}
                </div>
                <span>{shipmentLogistics.shipping_cost}</span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Rate'][language]}
                </div>
                <span>{shipmentLogistics.rate}</span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['The cost of insurance'][language]}
                </div>
                <span>{shipmentLogistics.insurance_cost}</span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['The cost of packaging'][language]}
                </div>
                <span>{shipmentLogistics.packing_cost}</span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Actual total weight in kg'][language]}
                </div>
                <span>{shipmentLogistics.actual_gross_weight_in_kg}</span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Actual total volume in м³'][language]}
                </div>
                <span>{shipmentLogistics.actual_gross_volume_in_m3}</span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Invoice number'][language]}
                </div>
                <span>{shipmentLogistics.invoice_number}</span>
              </div>
            </OpeningBlock>
          </div>
        )}
        {shipment.type_logistics == 'white' && shipmentLogistics && (
          <div className={`${styles.viewLogisticsData}`}>
            <OpeningBlock
              title={interface_translate['White delivery'][language]}
              open={true}
            >
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Type of transport'][language]}
                </div>
                <span>{types[shipmentLogistics.type_transport][language]}</span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Departure date'][language]}
                </div>
                <span>{shipmentLogistics.departure_date}</span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Departure date'][language]}
                </div>
                <span>
                  {shipmentLogistics.estimated_date_arrival_in_moscow}
                </span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['The cost of delivery'][language]}
                </div>
                <span>{shipmentLogistics.shipping_cost}</span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['The cost of the FCS'][language]}
                </div>
                <span>{shipmentLogistics.fcs_cost}</span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Actual total weight in kg'][language]}
                </div>
                <span>{shipmentLogistics.actual_gross_weight_in_kg}</span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Actual total volume in м³'][language]}
                </div>
                <span>{shipmentLogistics.actual_gross_volume_in_m3}</span>
              </div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Invoice number'][language]}
                </div>
                <span>{shipmentLogistics.invoice_number}</span>
              </div>
            </OpeningBlock>
          </div>
        )}
      </div>
      {viewProducts && (
        <div>
          <div className="openingBlockAttrName">
            {interface_translate['Products'][language]}
          </div>
          <ModelView
            tableAttrs={productTableAttrs}
            models={shipment.shipment_products}
            useSelected={false}
            usePagination={false}
            useSettings={false}
            handleModelClick={(model) =>
              navigate(`/products/${model.product.id}`, {
                state: { from: location },
              })
            }
          />
        </div>
      )}
    </div>
  );
};
export default ShipmentRead;
