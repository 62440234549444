import { useState, React, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import { api_error_translate } from '../../texts/error';
import { types } from '../../texts/types';
import { interface_translate } from '../../texts/interface';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import roleAccess from '../../roleAccess';
import userService from '../../services/user';
import customerCargoService from '../../services/customer_cargo';
import shipmentService from '../../services/shipment';
import ResponsibleListInput from '../../components/input/ResponsibleListInput';
import styles from './CustomerCargoCreatePage.module.css';
import '../shared.css';
import CoExecutorsInput from '../../components/input/CoExecutorsInput';

async function create(
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await customerCargoService.create(
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_shipments(filters = {}, size = 50) {
  filters = {
    customer_cargo_id: null,
    ...filters,
  };
  const shipments_paginated = await shipmentService.get_by_filters(filters, {
    size: size,
  });
  return shipments_paginated.data;
}

async function get_users(filters = {}, size = 50) {
  const result = await userService.get_by_filters(filters, { size: size });
  return result.data;
}

const CustomerCargoCreatePage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
    user,
  } = useCommon();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [defUsers, setDefUsers] = useState([]);
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState({});
  const [shipmentInputValue, setShipmentInputValue] = useState(null);
  const [shipmentElementsForSelect, setShipmentElementsForSelect] = useState(
    []
  );
  const [responsible, setResponsible] = useState({});
  const [initialResponsible, setInitialResponsible] = useState([]);
  const [coExecutors, setCoExecutors] = useState({});
  const [attrsForCreate, setAttrsForCreate] = useState(new Set());

  useEffect(() => {
    const req = async () => {
      const users = await get_users();
      setDefUsers(users);
    };
    req();
    pushPage(location.pathname);
    const attrsForCreate = new Set(
      roleAccess[userRole].fieldRestrictions['Customer cargo list']?.create
    );
    setAttrsForCreate(attrsForCreate);
  }, []);

  useEffect(() => {
    setInitialResponsible([user]);
  }, [user]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (shipmentInputValue) filters['id'] = shipmentInputValue;
      const new_elements = await get_shipments(filters);
      setShipmentElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [shipmentInputValue]);

  const getDataFromAttr = () => {
    if (modelData.shipment == null) {
      setErrors({
        shipment_id: ['There are blank fields left'],
      });
      return;
    }
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    for (let id of responsible_ids) {
      if (id === null) {
        setErrors({
          responsible_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const co_executor_ids = Object.values(coExecutors).map((m) =>
      m ? m.id : null
    );
    for (let id of co_executor_ids) {
      if (id === null) {
        setErrors({
          co_executors_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const data = {
      ...modelData,
      shipment_id: modelData.shipment.id,
      responsible_ids: responsible_ids,
      co_executors_ids: co_executor_ids,
    };
    delete data['shipment'];

    return data;
  };

  if (!user) return;
  return (
    <div className="pageCreateModel">
      <div className={styles.saveAndExitBtns}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await create(
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(
                interface_translate['Customer cargo created'][language]
              );
              resetGlobalError();
              setErrors({});
              navigate('..', { relative: 'path' });
            }
          }}
        >
          {interface_translate['Create'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      <div className="inputData">
        <OpeningBlock
          title={interface_translate['About customer cargo'][language]}
          open={true}
        >
          {attrsForCreate.has('shipment_id') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Shipment'][language]}
                </div>
                <Autocomplete
                  value={modelData.shipment}
                  onChange={(e, newElement) => {
                    setModelData({
                      ...modelData,
                      shipment: newElement,
                    });
                  }}
                  options={shipmentElementsForSelect}
                  getOptionLabel={(model) => model.id.toString()}
                  onInputChange={(e, value) => {
                    setShipmentInputValue(value);
                    setShipmentElementsForSelect([]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={interface_translate['Shipment'][language]}
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={shipmentElementsForSelect.length == 0}
                />
              </div>
              {errors['shipment_id'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['shipment_id'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('responsible') && (
            <div>
              <ResponsibleListInput
                containerClass={'openingBlockAttr'}
                attrNameClass={'openingBlockAttrName'}
                responsible={responsible}
                setSelectedElement={(value) => {
                  setResponsible((prevData) => {
                    return { ...prevData, ...value };
                  });
                }}
                deleteSelectedElement={(id) => {
                  setResponsible((prevElements) => {
                    delete prevElements[id];
                    return { ...prevElements };
                  });
                }}
                initialElements={initialResponsible}
                defaultElements={defUsers}
              />
              {errors['responsible_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['responsible_ids'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('co_executors') && (
            <div>
              <CoExecutorsInput
                containerClass={'openingBlockAttr'}
                attrNameClass={'openingBlockAttrName'}
                coExecutors={coExecutors}
                setSelectedElement={(value) => {
                  setCoExecutors((prevData) => {
                    return { ...prevData, ...value };
                  });
                }}
                deleteSelectedElement={(id) => {
                  setCoExecutors((prevElements) => {
                    delete prevElements[id];
                    return { ...prevElements };
                  });
                }}
                defaultElements={defUsers}
              />
              {errors['co_executors_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['co_executors_ids'][0]][language]}
                </div>
              )}
            </div>
          )}
        </OpeningBlock>
      </div>
    </div>
  );
};

export default CustomerCargoCreatePage;
