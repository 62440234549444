import apiRequester from '../ApiRequester';
import { model_names } from '../texts/model_names.js';
import { api_error_translate } from '../texts/error.js';

class SharedService {

  async get_unit_names() {
    try {
      const response = await apiRequester.get(`/unit_names`);
      const unit_names = response.data;
      return unit_names;
    } catch (err) {
      if (err.response.status !== 200) {
        throw new Error(`HTTP error: ${err.response.status}`);
      }
      console.error(`Could not fetch data: ${err}`);
    }
  }
}

const sharedService = new SharedService();
export default sharedService;
