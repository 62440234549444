import Filter from './Filter';

const SelectFilter = (props) => {
  const {
    title_name,
    attr,
    select_names,
    select_attr,
    defaultValue,
    addFiltersData,
  } = props;

  return (
    <Filter title_name={title_name}>
      <select
        name={attr}
        value={defaultValue}
        onChange={(e) => {
          addFiltersData(attr, e.target.value);
        }}
      >
        {select_names.map((name, index) => (
          <option
            key={index}
            value={select_attr[index]}
          >
            {name}
          </option>
        ))}
      </select>
    </Filter>
  );
};

export default SelectFilter;
