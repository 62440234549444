import { useState, React, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { api_error_translate } from '../../texts/error';
import { interface_translate } from '../../texts/interface';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import OpeningBlock from '../../components/OpeningBlock';
import roleAccess from '../../roleAccess';
import storehouseService from '../../services/storehouse';
import styles from './StorehouseEditPage.module.css';
import '../shared.css';

async function get_storehouse(id) {
  const storehouse = await storehouseService.get(id);
  return storehouse;
}

async function update_storehouse(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await storehouseService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
}

const StorehouseEditPage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState();
  const [oldStorehouse, setOldStorehouse] = useState({});
  const [attrsForEdit, setAttrsForEdit] = useState(new Set());

  useEffect(() => {
    const req = async () => {
      const storehouse_resp = await get_storehouse(params.id);
      setOldStorehouse({ ...storehouse_resp });
      setModelData(storehouse_resp);
    };
    req();
    pushPage(location.pathname);
    const attrsForEdit = new Set(
      roleAccess[userRole].fieldRestrictions.Storehouses?.edit
    );
    setAttrsForEdit(attrsForEdit);
  }, []);

  const getDataFromAttr = () => {
    const data = {
      ...modelData,
      name: modelData.name,
      address: modelData.address,
    };
    return data;
  };

  if (!modelData) return;

  return (
    <div className="pageUpdateModel">
      <div className={styles.controls}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await update_storehouse(
              params.id,
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(interface_translate['Storehouse updated'][language]);
              resetGlobalError();
              setErrors({});
              popPage();
              const previousPage = getCurrentPage();
              navigate(previousPage ? previousPage : '..', {
                relative: 'path',
              });
            }
          }}
        >
          {interface_translate['Update'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('../..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      {
        <>
          <div className="inputData">
            <OpeningBlock
              title={interface_translate['About storehouse'][language]}
              open={true}
            >
              {attrsForEdit.has('name') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Name'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.name}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['name'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['name'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('address') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Address'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.address}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          address: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['address'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['address'][0]][language]}
                    </div>
                  )}
                </div>
              )}
            </OpeningBlock>
          </div>
        </>
      }
      {!modelData && <div>Пусто</div>}
    </div>
  );
};
export default StorehouseEditPage;
