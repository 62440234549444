import { useState, useEffect } from 'react';
import usePageMovementHistory from '../../hooks/usePageMovementHistory.jsx';
import useCommon from '../../hooks/useCommon.jsx';
import ModelView from '../../components/models_view/ModelView.jsx';
import ModelsControls from '../../components/models_view/ModelsControls.jsx';
import ModelFilters from '../../components/ModelFilters.jsx';
import TextFilter from '../../components/filters/TextFilter.jsx';
import SelectFilter from '../../components/filters/SelectFilter.jsx';
import AutocompleteFilter from '../../components/filters/AutocompleteFilter.jsx';
import productService from '../../services/product.js';
import orderService from '../../services/order.js';
import deliveryService from '../../services/delivery.js';
import userService from '../../services/user.js';
import shipmentService from '../../services/shipment.js';
import customerCargoService from '../../services/customer_cargo.js';
import counterpartyService from '../../services/counterparty.js';
import useViewSettings from '../../hooks/useViewSettings.jsx';
import roleAccess from '../../roleAccess.js';
import { statuses_translate } from '../../texts/statuses.js';
import { api_error_translate } from '../../texts/error.js';
import { model_names } from '../../texts/model_names.js';
import { interface_translate } from '../../texts/interface.js';
import styles from './ProductsPage.module.css';
import '../../App.css';
import { useLocation } from 'react-router-dom';

async function delete_product(id, setGlobalError, language) {
  const result = await productService.delete(id, setGlobalError, language);
  return result;
}

async function get_users(filters = {}, size = 50) {
  const userPaginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return userPaginated.data;
}

async function get_orders(filters = {}, size = 50) {
  const orderPaginated = await orderService.get_by_filters(filters, {
    size: size,
  });
  return orderPaginated.data;
}

async function get_counterparties(filters = {}, size = 50) {
  const counterpartiesPaginated = await counterpartyService.get_by_filters(
    filters,
    { size: size }
  );
  return counterpartiesPaginated.data;
}

async function get_deliverys(filters = {}, size = 50) {
  const deliverysPaginated = await deliveryService.get_by_filters(filters, {
    size: size,
  });
  return deliverysPaginated.data;
}

async function get_shipments(filters = {}, size = 50) {
  const shipmentsPaginated = await shipmentService.get_by_filters(filters, {
    size: size,
  });
  return shipmentsPaginated.data;
}

async function get_customer_cargo(filters = {}, size = 50) {
  const customerCargoPaginated = await customerCargoService.get_by_filters(
    filters,
    {
      size: size,
    }
  );
  return customerCargoPaginated.data;
}

async function get_product_statuses() {
  const statuses = await productService.get_statuses();
  return statuses;
}

async function get_product_for_display(product, language) {
  product.supplier_name = product.supplier?.name;
  product.status_translate = statuses_translate[product.status][language];
  product.storehouse_id = product.storage_cell
    ? product.storage_cell.shelving.storehouse.id
    : '';
  product.storehouse_name = product.storage_cell
    ? product.storage_cell.shelving.storehouse.name
    : '';
  product.storage_cell_name = product.storage_cell
    ? `${product.storage_cell.shelving.name}${product.storage_cell.number}`
    : '';
  const order = await orderService.get(product.order_id);
  product.client_name = order.client.name;
  return product;
}

const table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'product id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'client_name',
    name_for_display: 'client',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'order_id',
    name_for_display: 'order',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'name',
    name_for_display: 'name',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'status_translate',
    name_for_display: 'status',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'qty',
    name_for_display: 'qty',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'price',
    name_for_display: 'price',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'currency',
    name_for_display: 'currency',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'storehouse_name',
    name_for_display: 'storehouse',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'storage_cell_name',
    name_for_display: 'storage cell',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'supplier_name',
    name_for_display: 'supplier',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const ProductsPage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const location = useLocation();
  const { clearHistoryPages, pushPage } = usePageMovementHistory();
  const { allViewSettings, setAllViewSettings } = useViewSettings();
  const viewSettings = allViewSettings['products'];
  const [filterData, setFiltersData] = useState(
    viewSettings['filterData'] || {}
  );
  const [showFilters, setShowFilters] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    size: viewSettings['size'],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [products, setProducts] = useState([]);
  const [productStatuses, setProductStatuses] = useState([]);
  const [selectedModelIds, setSelectedModelIds] = useState([]);
  const [sortData, setSortData] = useState({});
  const [tableAttrs, setTableAttrs] = useState([]);

  useEffect(() => {
    const req = async () => {
      const productStatuses = await get_product_statuses();
      setProductStatuses(productStatuses);
    };
    req();
    clearHistoryPages();
    pushPage(location.pathname);
    const table_attrs = table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions.Products.read_all.includes(
          attr_info.name
        )
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setTableAttrs(table_attrs);
  }, []);

  useEffect(() => {}, [language]);

  useEffect(() => {
    setAllViewSettings({
      products: { size: paginationSettings['size'], filterData: filterData },
    });
  }, [paginationSettings, filterData]);

  useEffect(() => {
    if (sortData.by === 'status_translate') {
      sortData.by = 'status';
    } else if (sortData.by === 'storehouse_name') {
      sortData.by = 'storehouse_name';
    }
    const newPaginationSettings = {
      ...paginationSettings,
      sort_by: sortData.by,
      sort_order: sortData.order,
    };
    setPaginationSettings(newPaginationSettings);
  }, [sortData]);

  const mapFilterData = () => {
    const data = { ...filterData };
    if (filterData.order) data['order_id'] = filterData.order.id;
    if (filterData.supplier) data['supplier_id'] = filterData.supplier.id;
    if (filterData.delivery) data['delivery_id'] = filterData.delivery.id;
    if (filterData.shipment) data['shipment_id'] = filterData.shipment.id;
    if (filterData.customer_cargo)
      data['customer_cargo_id'] = filterData.customer_cargo.id;
    if (filterData.status) {
      data['statuses'] = [filterData.status];
      delete data['status'];
    }
    if (filterData.price === '') delete data['price'];
    if (filterData.currency === '') delete data['currency'];
    if (filterData.link === '') delete data['link'];
    if (filterData.responsible)
      data['responsible_id'] = filterData.responsible.id;
    if (filterData.co_executors)
      data['co_executor_id'] = filterData.co_executors.id;
    delete data['order'];
    delete data['shipment'];
    delete data['responsible'];
    delete data['co_executors'];
    return data;
  };

  const requestGetProducts = async () => {
    const filter = mapFilterData();
    const new_products_paginated = await productService.get_by_filters(
      filter,
      paginationSettings
    );
    const new_products = new_products_paginated.data;
    for (let product of new_products) {
      product = await get_product_for_display(product, language);
    }
    setTotalPages(new_products_paginated.total);
    setProducts(new_products);
    setCurrentPage(paginationSettings['page']);
  };

  const addFiltersData = (attr, value) => {
    if (value) {
      setFiltersData({ ...filterData, [attr]: value });
    } else if (filterData.hasOwnProperty(attr)) {
      delete filterData[attr];
      setFiltersData({ ...filterData });
    }
  };

  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSetPaginationSettings = (settings) => {
    setPaginationSettings({ ...paginationSettings, ...settings });
  };

  useEffect(() => {
    requestGetProducts();
  }, [paginationSettings]);

  const deleteModels = async () => {
    setProducts(
      products.filter((model) => !selectedModelIds.includes(model.id))
    );
    for (const id of selectedModelIds) {
      const result = await delete_product(id, setGlobalError, language);
      if (!result) {
        requestGetProducts();
      }
    }
  };

  return (
    <div>
      <ModelsControls
        section="Products"
        model_name={interface_translate['Products'][language]}
        filtersActivated={Object.keys(filterData).length > 0}
        toggleShowFilters={toggleShowFilters}
        requestModels={requestGetProducts}
        deleteModels={deleteModels}
      />
      {showFilters && (
        <ModelFilters
          requestModels={requestGetProducts}
          resetFilterData={() => setFiltersData({})}
        >
          <TextFilter
            title_name={interface_translate['Name'][language]}
            attr="name"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['name'] != undefined ? filterData['name'] : ''
            }
          />
          <AutocompleteFilter
            title_name={interface_translate['Order'][language]}
            attr="order"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['order'] != undefined ? filterData['order'] : null
            }
            getElementLabel={(option) => option.id.toString()}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['id'] = value;
              const orders = await get_orders(filters);
              return orders;
            }}
          />
          <AutocompleteFilter
            title_name={interface_translate['Supplier'][language]}
            attr="supplier"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['supplier'] != undefined
                ? filterData['supplier']
                : null
            }
            getElementLabel={(option) => option.name}
            searchElements={async (value) => {
              let filters = { group: 'supplier' };
              if (value) filters['name'] = value;
              const suppliers = await get_counterparties(filters);
              return suppliers;
            }}
          />
          <SelectFilter
            title_name={interface_translate['Status'][language]}
            attr="status"
            select_names={[
              '',
              ...productStatuses.map((m) => statuses_translate[m][language]),
            ]}
            select_attr={['', ...productStatuses]}
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['status'] != undefined ? filterData['status'] : ''
            }
          />
          <TextFilter
            title_name={interface_translate['Qty'][language]}
            attr="qty"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['qty'] != undefined ? filterData['qty'] : ''
            }
          />
          <TextFilter
            title_name={interface_translate['Price'][language]}
            attr="price"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['price'] != undefined ? filterData['price'] : ''
            }
          />
          <SelectFilter
            title_name={interface_translate['Currency'][language]}
            attr="currency"
            select_names={['', 'rub', 'cny', 'usd']}
            select_attr={['', 'rub', 'cny', 'usd']}
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['currency'] != undefined ? filterData['currency'] : ''
            }
          />
          <TextFilter
            title_name={interface_translate['Link'][language]}
            attr="link"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['link'] != undefined ? filterData['link'] : ''
            }
          />
          <AutocompleteFilter
            title_name={interface_translate['Delivery'][language]}
            attr="delivery"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['delivery'] != undefined
                ? filterData['delivery']
                : null
            }
            getElementLabel={(option) => option.id.toString()}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['id'] = value;
              const deliverys = await get_deliverys(filters);
              return deliverys;
            }}
          />
          <AutocompleteFilter
            title_name={interface_translate['Shipment'][language]}
            attr="shipment"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['shipment'] != undefined
                ? filterData['shipment']
                : null
            }
            getElementLabel={(option) => option.id.toString()}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['id'] = value;
              const shipments = await get_shipments(filters);
              return shipments;
            }}
          />
          <AutocompleteFilter
            title_name={interface_translate['Customer cargo'][language]}
            attr="customer_cargo"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['customer_cargo'] != undefined
                ? filterData['customer_cargo']
                : null
            }
            getElementLabel={(option) => option.id.toString()}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['id'] = value;
              const shipments = await get_customer_cargo(filters);
              return shipments;
            }}
          />
          <AutocompleteFilter
            title_name={interface_translate['Responsible'][language]}
            attr="responsible"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['responsible'] != undefined
                ? filterData['responsible']
                : null
            }
            getElementLabel={(option) => option.email}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['email'] = value;
              const responsible = await get_users(filters);
              return responsible;
            }}
          />
          <AutocompleteFilter
            title_name={interface_translate['Co executors'][language]}
            attr="co_executors"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['co_executors'] != undefined
                ? filterData['co_executors']
                : null
            }
            getElementLabel={(option) => option.email}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['email'] = value;
              const coExecutors = await get_users(filters);
              return coExecutors;
            }}
          />
        </ModelFilters>
      )}
      <ModelView
        tableAttrs={tableAttrs}
        models={products}
        setSelectedModels={setSelectedModelIds}
        sortData={sortData}
        setSortData={setSortData}
        setPaginationSettings={handleSetPaginationSettings}
        currentPage={currentPage}
        totalPages={totalPages}
        currentSize={paginationSettings['size']}
      />
    </div>
  );
};

export default ProductsPage;
