import styles from './LanguageSwitcher.module.css';

const LanguageSwitcher = (props) => {
  const { setLanguage } = props;
  return (
    <div className={styles.container}>
      <button onClick={() => setLanguage('ru')}>ru</button>
      {/* <button onClick={() => setLanguage('en')}>en</button> */}
      <button onClick={() => setLanguage('ch')}>ch</button>
    </div>
  );
};

export default LanguageSwitcher;
