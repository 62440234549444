import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { types } from '../../texts/types';
import { interface_translate } from '../../texts/interface';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import OpeningBlock from '../../components/OpeningBlock';
import counterpartyService from '../../services/counterparty';
import userService from '../../services/user';
import roleAccess from '../../roleAccess';
import styles from './CounterpartyPage.module.css';
import '../shared.css';

async function get_counterparty(id) {
  const result = await counterpartyService.get(id);
  return result;
}

async function get_user(id) {
  const result = await userService.get(id);
  return result;
}

const CounterpartyPage = () => {
  const { language, userRole } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [counterparty, setCounterparty] = useState();
  const [attrsForRead, setAttrsForRead] = useState(new Set());

  useEffect(() => {
    const req = async () => {
      const counterparty_resp = await get_counterparty(params.id);
      const users = [];
      for (const user_id of counterparty_resp.responsible_ids) {
        const user = await get_user(user_id);
        users.push(user);
      }
      counterparty_resp.responsible = users;
      counterparty_resp.group_translate =
        types[counterparty_resp.group][language];
      setCounterparty(counterparty_resp);
    };
    req();
    pushPage(location.pathname);

    const attrs_for_read = new Set(
      roleAccess[userRole].fieldRestrictions.Counterparties?.read
    );
    setAttrsForRead(attrs_for_read);
  }, []);

  useEffect(() => {
    if (!counterparty) return;
    counterparty.group_translate = types[counterparty.group][language];
    setCounterparty({ ...counterparty });
  }, [language]);

  if (!counterparty) return;

  return (
    <div className="pageViewModel">
      <div className="controls">
        {roleAccess[userRole].permissions.Counterparties.edit && (
          <button
            onClick={() => {
              navigate('edit');
            }}
          >
            {interface_translate['Edit'][language]}
          </button>
        )}
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>

      <div className="viewData">
        {attrsForRead.has('name') && (
          <div className="viewName">
            <div className="openingBlockAttrName">
              {interface_translate['Company'][language]}
            </div>
            <samp>{counterparty.name}</samp>
          </div>
        )}
        <OpeningBlock
          title={interface_translate['About сounterparty'][language]}
          open={true}
        >
          {attrsForRead.has('id') && (
            <div className="openingBlockAttr">
              <div className="openingBlockAttrName">
                {interface_translate['counterparty id'][language]}
              </div>
              <span>{counterparty.id}</span>
            </div>
          )}
          {attrsForRead.has('group_translate') && (
            <div className="openingBlockAttr">
              <div className="openingBlockAttrName">
                {interface_translate['Group'][language]}
              </div>
              <span>{counterparty.group_translate}</span>
            </div>
          )}
          {attrsForRead.has('phone_number') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Phone number'][language]}
                </div>
                <span>{counterparty.phone_number}</span>
              </div>
            </div>
          )}
          {attrsForRead.has('contact_person') && (
            <div className="openingBlockAttr">
              <div className="openingBlockAttrName">
                {interface_translate['Contact person'][language]}
              </div>
              <span>{counterparty.contact_person}</span>
            </div>
          )}
          {attrsForRead.has('link_to_messenger') && (
            <div className="openingBlockAttr">
              <div className="openingBlockAttrName">
                {interface_translate['Link to messenger'][language]}
              </div>
              <span>
                <a>{counterparty.link_to_messenger}</a>
              </span>
            </div>
          )}
          {attrsForRead.has('responsible') && (
            <div className="openingBlockAttr">
              <div className="openingBlockAttrName">
                {interface_translate['Responsible'][language]}
              </div>
              <div className="listModels">
                {counterparty.responsible.map((model) => {
                  return (
                    <div
                      className="modelBlock"
                      key={model.id}
                    >
                      {`${model.surname} ${model.name} ${model.patronymic}`}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </OpeningBlock>
      </div>
      {!counterparty && <div>Пусто</div>}
    </div>
  );
};

export default CounterpartyPage;
