import { createContext, useState } from 'react';
import apiRequester from '../ApiRequester';

const localIsAuth = localStorage.getItem('auth') == 'true';
const AuthContext = createContext({
  isAuth: localIsAuth,
  setIsAuth: () => {},
});

export const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuthInState] = useState(localIsAuth);

  const setIsAuth = (value) => {
    setIsAuthInState(value);
    localStorage.setItem('auth', value);
  };

  apiRequester.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        setIsAuth(false);
        return error.response;
      } else {
        throw error;
      }
    }
  );

  return (
    <AuthContext.Provider value={{ isAuth, setIsAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
