import { useState, useEffect } from 'react';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import ModelView from '../../components/models_view/ModelView.jsx';
import ModelsControls from '../../components/models_view/ModelsControls.jsx';
import ModelFilters from '../../components/ModelFilters.jsx';
import SelectFilter from '../../components/filters/SelectFilter.jsx';
import AutocompleteFilter from '../../components/filters/AutocompleteFilter.jsx';
import TextFilter from '../../components/filters/TextFilter.jsx';
import { model_names } from '../../texts/model_names.js';
import { api_error_translate } from '../../texts/error.js';
import { interface_translate } from '../../texts/interface.js';
import roleAccess from '../../roleAccess.js';
import orderService from '../../services/order.js';
import userService from '../../services/user.js';
import counterpartyService from '../../services/counterparty.js';
import useViewSettings from '../../hooks/useViewSettings.jsx';
import '../../App.css';
import { useLocation } from 'react-router-dom';

async function delete_order(id, setGlobalError, language) {
  const result = await orderService.delete(id, setGlobalError, language);
  return result;
}

async function get_users(filters = {}, size = 50) {
  const users_paginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return users_paginated.data;
}

async function get_clients(filters = {}, size = 50) {
  filters = { group: 'client', ...filters };
  const clients_paginated = await counterpartyService.get_by_filters(filters, {
    size: size,
  });
  return clients_paginated.data;
}

const table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'order id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'datetime_created',
    name_for_display: 'datetime created',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'client_name',
    name_for_display: 'client',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'estimated_cost_logistics',
    name_for_display: 'estimated cost logistics',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'final_cost_logistics',
    name_for_display: 'final cost logistics',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'completed',
    name_for_display: 'completed',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const OrdersPage = (props) => {
  const { setGlobalError, language, userRole } = useCommon();
  const { allViewSettings, setAllViewSettings } = useViewSettings();
  const viewSettings = allViewSettings['orders'];
  const location = useLocation();
  const { clearHistoryPages, pushPage } = usePageMovementHistory();
  const [filterData, setFiltersData] = useState(
    viewSettings['filterData'] || {}
  );
  const [showFilters, setShowFilters] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    size: viewSettings['size'],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [orders, setOrders] = useState([]);
  const [selectedModelIds, setSelectedModelIds] = useState([]);
  const [sortData, setSortData] = useState({});
  const [tableAttrs, setTableAttrs] = useState([]);
  const [attrsForFilters, setAttrsForFilters] = useState(new Set());

  useEffect(() => {
    clearHistoryPages();
    pushPage(location.pathname);
    const table_attrs = table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions.Orders.read_all.includes(
          attr_info.name
        )
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setTableAttrs(table_attrs);

    const attrs_for_filters = new Set(roleAccess[userRole].fieldFilters.Orders);
    setAttrsForFilters(attrs_for_filters);
  }, []);

  useEffect(() => {
    setAllViewSettings({
      orders: { size: paginationSettings['size'], filterData: filterData },
    });
  }, [paginationSettings, filterData]);

  useEffect(() => {
    const newPaginationSettings = {
      ...paginationSettings,
      sort_by: sortData.by,
      sort_order: sortData.order,
    };
    setPaginationSettings(newPaginationSettings);
  }, [sortData]);

  const mapFilterData = () => {
    const data = { ...filterData };
    if (filterData.responsible)
      data['responsible_id'] = filterData.responsible.id;
    if (filterData.co_executors)
      data['co_executor_id'] = filterData.co_executors.id;
    if (filterData.client) data['client_id'] = filterData.client.id;
    if (!filterData.completed || filterData.completed === '') {
      delete data['completed'];
    } else {
      data.completed = filterData.completed === 'true';
    }
    delete data['client'];
    delete data['responsible'];
    delete data['co_executors'];
    return data;
  };

  const requestGetOrders = async () => {
    const filter = mapFilterData();
    const new_orders_paginated = await orderService.get_by_filters(
      filter,
      paginationSettings
    );
    const new_orders = new_orders_paginated.data;
    for (const order of new_orders) {
      order.client_name = order.client.name;
      order.completed = order.completed ? '+' : '-';
      const date = new Date(order.datetime_created);
      const formattedDate =
        date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
      order.datetime_created = formattedDate;
    }
    setTotalPages(new_orders_paginated.total);
    setOrders(new_orders);
    setCurrentPage(paginationSettings['page']);
  };

  const addFiltersData = (attr, value) => {
    if (value) {
      setFiltersData({ ...filterData, [attr]: value });
    } else if (filterData.hasOwnProperty(attr)) {
      delete filterData[attr];
      setFiltersData({ ...filterData });
    }
  };

  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSetPaginationSettings = (settings) => {
    setPaginationSettings({ ...paginationSettings, ...settings });
  };

  useEffect(() => {
    requestGetOrders();
  }, [paginationSettings]);

  const deleteModels = async () => {
    setOrders(orders.filter((model) => !selectedModelIds.includes(model.id)));
    for (const id of selectedModelIds) {
      const result = await delete_order(id, setGlobalError, language);
      if (!result) {
        requestGetOrders();
      }
    }
  };
  return (
    <div>
      <ModelsControls
        model_name={interface_translate['Orders'][language]}
        filtersActivated={Object.keys(filterData).length > 0}
        toggleShowFilters={toggleShowFilters}
        requestModels={requestGetOrders}
        deleteModels={deleteModels}
        section="Orders"
      />
      {showFilters && (
        <ModelFilters
          requestModels={requestGetOrders}
          resetFilterData={() => setFiltersData({})}
        >
          {attrsForFilters.has('client_name') && (
            <AutocompleteFilter
              title_name={interface_translate['Client'][language]}
              attr="client"
              addFiltersData={addFiltersData}
              defaultValue={
                filterData['client'] != undefined ? filterData['client'] : null
              }
              getElementLabel={(option) => option.name}
              searchElements={async (value) => {
                let filters = {};
                if (value) filters['name'] = value;
                const client = await get_clients(filters);
                return client;
              }}
            />
          )}
          {attrsForFilters.has('estimated_cost_logistics') && (
            <TextFilter
              title_name={
                interface_translate['Estimated cost logistics'][language]
              }
              attr="estimated_cost_logistics"
              addFiltersData={addFiltersData}
              defaultValue={
                filterData['estimated_cost_logistics'] != undefined
                  ? filterData['estimated_cost_logistics']
                  : ''
              }
            />
          )}
          {attrsForFilters.has('final_cost_logistics') && (
            <TextFilter
              title_name={interface_translate['Final cost logistics'][language]}
              attr="final_cost_logistics"
              addFiltersData={addFiltersData}
              defaultValue={
                filterData['final_cost_logistics'] != undefined
                  ? filterData['final_cost_logistics']
                  : ''
              }
            />
          )}
          {attrsForFilters.has('rate_cny_to_rub') && (
            <TextFilter
              title_name={interface_translate['Rate cny to rub'][language]}
              attr="rate_cny_to_rub"
              addFiltersData={addFiltersData}
              defaultValue={
                filterData['rate_cny_to_rub'] != undefined
                  ? filterData['rate_cny_to_rub']
                  : ''
              }
            />
          )}
          {attrsForFilters.has('rate_usd_to_rub') && (
            <TextFilter
              title_name={interface_translate['Rate usd to rub'][language]}
              attr="rate_usd_to_rub"
              addFiltersData={addFiltersData}
              defaultValue={
                filterData['rate_usd_to_rub'] != undefined
                  ? filterData['rate_usd_to_rub']
                  : ''
              }
            />
          )}
          {attrsForFilters.has('completed') && (
            <SelectFilter
              title_name={interface_translate['Completed'][language]}
              attr="completed"
              select_names={[
                '',
                interface_translate['yes'][language],
                interface_translate['no'][language],
              ]}
              select_attr={['', true, false]}
              addFiltersData={addFiltersData}
              defaultValue={
                filterData['completed'] != undefined
                  ? filterData['completed']
                  : ''
              }
            />
          )}
          {attrsForFilters.has('responsible') && (
            <AutocompleteFilter
              title_name={interface_translate['Responsible'][language]}
              attr="responsible"
              addFiltersData={addFiltersData}
              defaultValue={
                filterData['responsible'] != undefined
                  ? filterData['responsible']
                  : null
              }
              getElementLabel={(option) => option.email}
              searchElements={async (value) => {
                let filters = {};
                if (value) filters['email'] = value;
                const responsible = await get_users(filters);
                return responsible;
              }}
            />
          )}
          {attrsForFilters.has('co_executors') && (
            <AutocompleteFilter
              title_name={interface_translate['Co executors'][language]}
              attr="co_executors"
              addFiltersData={addFiltersData}
              defaultValue={
                filterData['co_executors'] != undefined
                  ? filterData['co_executors']
                  : null
              }
              getElementLabel={(option) => option.email}
              searchElements={async (value) => {
                let filters = {};
                if (value) filters['email'] = value;
                const coExecutors = await get_users(filters);
                return coExecutors;
              }}
            />
          )}
        </ModelFilters>
      )}
      <ModelView
        tableAttrs={tableAttrs}
        models={orders}
        setSelectedModels={setSelectedModelIds}
        sortData={sortData}
        setSortData={setSortData}
        setPaginationSettings={handleSetPaginationSettings}
        currentPage={currentPage}
        totalPages={totalPages}
        currentSize={paginationSettings['size']}
      />
    </div>
  );
};

export default OrdersPage;
