import apiRequester from '../ApiRequester';
import { model_names } from '../texts/model_names.js';
import { api_error_translate } from '../texts/error.js';

class OrderService {
  async create(data, setErrors) {
    const jsonData = JSON.stringify(data);
    const co_executor_ids_already_in_responsible_ids_pattern =
      /ids:.*already in responsible_ids/;
    try {
      await apiRequester.post('/orders', jsonData);
      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const err_msg = err.response.data.errors;
          if (
            err_msg.hasOwnProperty('co_executors_ids') &&
            co_executor_ids_already_in_responsible_ids_pattern.test(
              err_msg['co_executors_ids']
            )
          ) {
            setErrors({
              co_executors_ids: ['co_executors_ids already in responsible_ids'],
            });
            return;
          }
          setErrors({ ...err_msg });
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
      console.error(`Could not fetch data: ${err}`);
    }
  }

  async get(id) {
    try {
      const response = await apiRequester.get(`/orders/${id}`);
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }

  async get_by_filters(filterData, paginationSettings = { size: 20 }) {
    const jsonData = JSON.stringify(filterData);
    try {
      const response = await apiRequester.post(`/orders/filters`, jsonData, {
        params: paginationSettings,
      });
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }

  async update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  ) {
    const jsonData = JSON.stringify(data);
    const co_executor_ids_already_in_responsible_ids_pattern =
      /ids: .* already in responsible_ids/;
    try {
      await apiRequester.patch(`/orders/${id}`, jsonData);
      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const err_msg = err.response.data.errors;
          if (
            err_msg.hasOwnProperty('co_executors_ids') &&
            co_executor_ids_already_in_responsible_ids_pattern.test(
              err_msg['co_executors_ids']
            )
          ) {
            setErrors({
              co_executors_ids: ['co_executors_ids already in responsible_ids'],
            });
            return;
          } else {
            setErrors({ ...err_msg });
            return;
          }
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
      console.error(`Could not fetch data: ${err}`);
    }
  }

  async delete(id, setGlobalError, language) {
    try {
      const response = await apiRequester.delete(`/orders/${id}`);
      return response.data;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (err_msg === 'foreign connection') {
            let msg = `${api_error_translate['linked to another model'][language]}: ${model_names[detail_msg][language]}`;
            setGlobalError(msg);
          }
        }
        return;
      }
      console.error(`Could not fetch data: ${err}`);
      throw err;
    }
  }
}

const orderService = new OrderService();
export default orderService;
