import useCommon from '../../hooks/useCommon';
import { interface_translate } from '../../texts/interface';
import ListAttrAutocomplete from '../ListAttrAutocomplete';
import userService from '../../services/user';
import TextField from '@mui/material/TextField';
import '../../pages/shared.css';

async function get_users(filters = {}, size = 50) {
  const result = await userService.get_by_filters(filters, { size: size });
  return result.data;
}

const ResponsibleListInput = (props) => {
  const { language } = useCommon();
  const {
    responsible,
    setSelectedElement,
    deleteSelectedElement,
    initialElements = [],
    defaultElements = [],
    containerClass,
    attrNameClass,
  } = props;
  return (
    <div className={containerClass}>
      <div className={attrNameClass}>
        {interface_translate['Responsible'][language]}
      </div>
      <ListAttrAutocomplete
        selectedElements={responsible}
        setSelectedElement={setSelectedElement}
        deleteSelectedElement={deleteSelectedElement}
        defaultElements={initialElements}
        getElementLabel={(model) =>
          `${model.surname} ${model.name} ${model.patronymic}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={interface_translate['Responsible'][language]}
            size="small"
          />
        )}
        searchElements={async (value) => {
          const users = await get_users({ full_name: value });
          return users;
        }}
        defElements={defaultElements}
      />
    </div>
  );
};

export default ResponsibleListInput;
