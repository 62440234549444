import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCommon from '../../hooks/useCommon';
import ConfirmDialog from '../ConfirmDialog';
import ModelsViewBox from '../models_view/ModelsViewBox';
import { interface_translate } from '../../texts/interface';
import roleAccess from '../../roleAccess.js';
import styles from './RelatedModelsCreate.module.css';

const RelatedModelsCreate = (props) => {
  const { language, userRole } = useCommon();
  const navigate = useNavigate();
  const {
    section,
    tableAttrs,
    models,
    setModels,
    setSelectedModels,
    addModel,
    deleteModels,
    handleModelClick = (model) => {
      navigate(`${model.id}`);
    },
  } = props;
  const [viewDeleteConfirm, setViewDeleteConfirm] = useState(false);

  return (
    <div className={styles.relatedModelsCreate}>
      {viewDeleteConfirm && (
        <ConfirmDialog
          message={`${interface_translate['Delete'][language]}?`}
          onConfirm={() => {
            setViewDeleteConfirm(false);
            deleteModels();
          }}
          onCancel={() => setViewDeleteConfirm(false)}
        />
      )}
      <div className={styles.relatedModelsContoller}>
        {roleAccess[userRole].permissions?.[section]?.create && (
          <button onClick={addModel}>
            {interface_translate['Add'][language]}
          </button>
        )}
        {roleAccess[userRole].permissions?.[section]?.delete && (
          <button onClick={() => setViewDeleteConfirm(true)}>
            {interface_translate['Delete'][language]}
          </button>
        )}
      </div>
      <ModelsViewBox
        tableAttrs={tableAttrs}
        models={models}
        setModels={setModels}
        setSelectedModels={setSelectedModels}
        handleModelClick={handleModelClick}
      />
    </div>
  );
};

export default RelatedModelsCreate;
