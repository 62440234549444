import userService from '../services/user';
import { createContext, useState, useEffect } from 'react';

const CommonContext = createContext(null);

let LSlanguage = localStorage.getItem('language');
if (!LSlanguage) LSlanguage = 'ru';
const LSUserRole = localStorage.getItem('userRole');
const LSUserID = localStorage.getItem('userID');

export const CommonProvider = ({ children, ...props }) => {
  const [language, setLanguageState] = useState(LSlanguage);
  const [userRole, setUserRoleInState] = useState(LSUserRole);
  const [userID, setUserIDInState] = useState(LSUserID);
  const [user, setUserInState] = useState();

  useEffect(() => {
    if (userID) {
      const getUser = async () => {
        const user = await userService.get(userID);
        if (user) {
          setUser(user);
        }
      };

      getUser(); // Первый вызов для получения текущего состояния пользователя
      const intervalId = setInterval(getUser, 3600000); // Далее будет вызываться каждые 60 минут

      // Очищаем интервал при размонтировании компонента
      return () => clearInterval(intervalId);
    }
  }, []);

  const setLanguage = (v) => {
    setLanguageState(v);
    localStorage.setItem('language', v);
  };

  const setUserRole = (value) => {
    setUserRoleInState(value);
    localStorage.setItem('userRole', value);
  };

  const setUserID = (user_id) => {
    setUserIDInState(user_id);
    localStorage.setItem('userID', user_id);
  };

  const setUser = (user) => {
    setUserRole(user.role);
    setUserID(user.id);
    setUserInState(user);
  };

  return (
    <CommonContext.Provider
      value={{
        ...props,
        language,
        setLanguage,
        userRole,
        setUserRole,
        userID,
        setUserID,
        user,
        setUser,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export default CommonContext;
