import React, { useState, useEffect, useRef } from 'react';
import { ImageCapture } from 'image-capture';
import styles from './BarcodeScanner.module.css';

function BarcodeScanner(props) {
  const { scanning, setScanning, setBarcodeValue, scanTimeout = 2000 } = props;
  const videoRef = useRef();

  useEffect(() => {
    const constraints = {
      width: { ideal: 1000 },
      height: { ideal: 1000 },
      facingMode: { ideal: 'environment' },
      video: {
        facingMode: getFacingMode(),
      },
      audio: false,
    };

    let stream = null;
    const startCamera = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }

        if (isIOS()) {
          // wait 2 seconds to guarantee the camera has already started to apply the focus mode and zoom...
          setTimeout(() => {
            stream.value.applyVideoConstraints({
              focusMode: 'continuous',
              advanced: [{ zoom: 2.0 }],
            });
          }, 2000);
        }
      } catch (error) {
        console.error('Error accessing the camera:', error);
      }
    };

    startCamera();

    return () => {
      stopGetVideo(stream);
    };
  }, []);

  const getFacingMode = () => {
    if (isMobileDevice()) {
      return { exact: 'environment' };
    } else {
      return 'user';
    }
  };

  const isIOS = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const stopGetVideo = (stream) => {
    if (stream) {
      const tracks = stream.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    }
  };

  useEffect(() => {
    const handleCanPlay = () => {
      videoRef.current.play();
      requestAnimationFrame(handleBarcodeDetection);
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('canplay', handleCanPlay);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('canplay', handleCanPlay);
      }
    };
  }, []);

  const handleBarcodeDetection = async () => {
    if (!scanning) return;

    if (!videoRef.current) return;

    const video = videoRef.current;
    const track = video.srcObject.getVideoTracks()[0];
    const imageCapture = new ImageCapture(track);

    try {
      const frame = await imageCapture.grabFrame();
      const barcodeDetector = new window.BarcodeDetector();

      const barcodes = await barcodeDetector.detect(frame);
      if (barcodes.length > 0) {
        setBarcodeValue(barcodes[0].rawValue);
        setTimeout(() => setScanning(false), scanTimeout);
      }
    } catch (error) {
      console.error('Barcode detection failed:', error);
    }

    if (scanning) {
      requestAnimationFrame(handleBarcodeDetection);
    }
  };

  return (
    <div>
      <video
        className={styles.barcodeVideo}
        ref={videoRef}
        autoPlay
        playsInline
      />
    </div>
  );
}

export default BarcodeScanner;
