import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useCommon from '../../hooks/useCommon';
import { api_error_translate } from '../../texts/error';
import { interface_translate } from '../../texts/interface';
import apiRequester from '../../ApiRequester';
import userService from '../../services/user';
import styles from './shered.module.css';

async function login(data, setGlobalError) {
  const result = await userService.login(data, setGlobalError);
  return result;
}

const LoginForm = (props) => {
  const { language, setGlobalError, setUser } = useCommon();
  const { setIsAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');

  const onClickLogin = async (event) => {
    event.preventDefault();

    const data = {
      email: email,
      password: pass,
    };
    const user = await login(data, setGlobalError);
    if (user) {
      setIsAuth(true);
      setUser(user);
      navigate(from, { replace: true });
    }
  };

  return (
    <form
      id="loginForm"
      className={styles.form}
      onSubmit={onClickLogin}
    >
      <h2>{interface_translate['Login'][language]}</h2>
      <div className={styles.formFields}>
        <div className={styles.formField}>
          <label htmlFor="email">
            {interface_translate['Email'][language]}:
          </label>
          <input
            type="text"
            id="email"
            name="email"
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className={styles.formField}>
          <label htmlFor="password">
            {interface_translate['Password'][language]}:
          </label>
          <input
            type="password"
            id="password"
            name="password"
            required
            value={pass}
            onChange={(e) => {
              setPass(e.target.value);
            }}
          />
        </div>
      </div>
      <button type="submit">{interface_translate['Login'][language]}</button>
    </form>
  );
};

export default LoginForm;
