import React, { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import styles from './UserActions.module.css';
import ConfirmDialog from '../ConfirmDialog';

const UserActions = () => {
  const { setIsAuth } = useAuth();
  const [viewExitConfirmDialog, setViewExitConfirmDialog] = useState(false);
  return (
    <div className={styles.container}>
      <button onClick={() => setViewExitConfirmDialog(true)}>Выйти</button>
      {viewExitConfirmDialog && (
        <ConfirmDialog
          message="Вы хотите выйти из аккаунта?"
          confirmMsg="Да"
          cancelMsg="Нет"
          onConfirm={async () => {
            setIsAuth(false);
            setViewExitConfirmDialog(false);
          }}
          onCancel={() => setViewExitConfirmDialog(false)}
        />
      )}
    </div>
  );
};

export default UserActions;
