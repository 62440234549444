import { useState, useEffect } from 'react';
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { api_error_translate } from '../../texts/error.js';
import { statuses_translate } from '../../texts/statuses.js';
import { types } from '../../texts/types.js';
import { interface_translate } from '../../texts/interface';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import OpeningBlock from '../../components/OpeningBlock';
import DropDownMediaBlock from '../../components/DropDownMediaBlock';
import ModelView from '../../components/models_view/ModelView.jsx';
import roleAccess from '../../roleAccess.js';
import shipmentService from '../../services/shipment.js';
import orderService from '../../services/order.js';
import userService from '../../services/user.js';
import { getPrevViewImgAndMediaBlockByImageObj } from '../../utils/image.js';
import styles from './ShipmentPage.module.css';
import '../shared.css';

async function shipment_scan_barcode(id) {
  const result = await shipmentService.scan_barcode(id);
  return result;
}

async function get_shipment(id) {
  const shipment = await shipmentService.get(id);
  return shipment;
}

async function get_shipment_logistics(id) {
  const logistic = await shipmentService.get_logistics(id);
  return logistic;
}

async function get_order(id) {
  const order = await orderService.get(id);
  return order;
}

async function get_user(id) {
  const user = await userService.get(id);
  return user;
}

function get_product_for_display(shipment_product, order, language) {
  const product = shipment_product.product;
  if (!product.link) product.link = '';
  product.qty_with_unit = `${product.qty} ${
    types[product.measurement_unit][language]
  }`;
  product.total_cost = product.price * product.qty;
  product.supplier_name = product.supplier.name;
  product.client_name = order.client.name;

  shipment_product.shipment_qty_with_unit = `${shipment_product.qty} ${
    types[shipment_product.product.measurement_unit][language]
  }`;

  return { ...product, ...shipment_product };
}

const product_table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'product id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'client_name',
    name_for_display: 'client',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'order_id',
    name_for_display: 'order',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'name',
    name_for_display: 'name',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'status_translate',
    name_for_display: 'status',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'qty',
    name_for_display: 'qty',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'price',
    name_for_display: 'price',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'currency',
    name_for_display: 'currency',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'storehouse_name',
    name_for_display: 'storehouse',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'storage_cell_name',
    name_for_display: 'storage cell',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'supplier_name',
    name_for_display: 'supplier',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const ShipmentPage = () => {
  const { resetGlobalError, setGlobalMsg, resetGlobalMsg, language, userRole } =
    useCommon();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [shipment, setShipment] = useState();
  const [products, setProducts] = useState();
  const [shipmentLogistics, setShipmentLogistics] = useState();
  const shipmentId = searchParams.get('shipment_id');
  const [attrsForRead, setAttrsForRead] = useState(new Set());
  const [productTableAttrs, setProductTableAttrs] = useState([]);
  
  useEffect(() => {
    const req = async () => {
      shipment_scan_barcode(shipmentId);
      requestGetShipment();
      await requestGetShipmentLogistics();
    };
    req();
    pushPage(`${location.pathname}${location.search}`);
    const attrs_for_read = new Set(
      roleAccess[userRole].fieldRestrictions.Shipments?.read
    );
    setAttrsForRead(attrs_for_read);
    const product_table_attrs = product_table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions.Products.read_all.includes(
          attr_info.name
        )
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setProductTableAttrs(product_table_attrs);
  }, []);

  useEffect(() => {
    if (!shipment) return;
    shipment.status_tranclate = statuses_translate[shipment.status][language];
    shipment.packing_translate = types[shipment.packing][language];
    setShipment({ ...shipment });
  }, [language]);

  const requestGetShipment = async () => {
    const shipment_resp = await get_shipment(shipmentId);
    let order = await get_order(shipment_resp.order_id);
    shipment_resp.order = order;
    const responsible = [];
    for (const user_id of shipment_resp.responsible_ids) {
      const user = await get_user(user_id);
      responsible.push(user);
    }
    shipment_resp.responsible = responsible;
    const co_executors = [];
    for (const user_id of shipment_resp.co_executors_ids) {
      const user = await get_user(user_id);
      co_executors.push(user);
    }
    shipment_resp.co_executors = co_executors;
    shipment_resp.status_tranclate =
      statuses_translate[shipment_resp.status][language];
    shipment_resp.packing_translate = types[shipment_resp.packing][language];
    shipment_resp.storehouse_id = shipment_resp.storage_cell
      ? shipment_resp.storage_cell.shelving.storehouse.id
      : '';
    shipment_resp.storehouse_name = shipment_resp.storage_cell
      ? shipment_resp.storage_cell.shelving.storehouse.name
      : '';
    shipment_resp.storage_cell_name = shipment_resp.storage_cell
      ? `${shipment_resp.storage_cell.shelving.name}${shipment_resp.storage_cell.number}`
      : '';
    let total_amount = 0;
    const shipment_products = [];
    for (let shipment_product of shipment_resp.shipment_products) {
      shipment_product = get_product_for_display(
        shipment_product,
        order,
        language
      );
      if (shipment_product.currency == 'cny') {
        total_amount += shipment_product.total_cost * order.rate_cny_to_rub;
      } else if (shipment_product.currency == 'usd') {
        total_amount += shipment_product.total_cost * order.rate_dollar_to_rub;
      } else if (shipment_product.currency == 'rub') {
        total_amount += shipment_product.total_cost;
      }
      shipment_products.push(shipment_product);
    }
    shipment_resp.shipment_products = shipment_products;
    shipment_resp.total_amount = total_amount;
    setProducts(shipment_resp.shipment_products);
    setShipment(shipment_resp);
  };

  const requestGetShipmentLogistics = async () => {
    const shipmentLogistics = await get_shipment_logistics(shipmentId);
    setShipmentLogistics(shipmentLogistics);
  };

  if (shipment == null) return;

  return (
    <div className="pageViewModel">
      {shipment && (
        <div className={styles.ViewDataBlocks}>
          <div className="viewData">
            <OpeningBlock
              title={interface_translate['About shipment'][language]}
              open={true}
            >
              {attrsForRead.has('id') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['shipment id'][language]}
                  </div>
                  <span>{shipment.id}</span>
                </div>
              )}
              {attrsForRead.has('order_id') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Order'][language]}
                  </div>
                  <span
                    className="linkToModel"
                    onClick={() =>
                      navigate(`/orders/${shipment.order_id}`, {
                        state: { from: location },
                      })
                    }
                  >
                    {shipment.order_id}
                  </span>
                </div>
              )}
              {attrsForRead.has('type_logistics_translate') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Type logistics'][language]}
                  </div>
                  <span>
                    {shipment.type_logistics
                      ? types[shipment.type_logistics][language]
                      : ''}
                  </span>
                </div>
              )}
              {attrsForRead.has('packing_translate') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Packing'][language]}
                  </div>
                  <span>{shipment.packing_translate}</span>
                </div>
              )}
              {attrsForRead.has('status_tranclate') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Status'][language]}
                  </div>
                  <span>{shipment.status_tranclate}</span>
                </div>
              )}
              {attrsForRead.has('total_amount') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Total amount ₽'][language]}
                    </div>
                    <span>{shipment.total_amount}</span>
                  </div>
                </div>
              )}
              {attrsForRead.has('storehouse_name') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Storehouse'][language]}
                  </div>
                  <span
                    className="linkToModel"
                    onClick={() =>
                      navigate(`/storehouses/${shipment.storehouse_id}`, {
                        state: { from: location },
                      })
                    }
                  >
                    {shipment.storehouse_name}
                  </span>
                </div>
              )}
              {attrsForRead.has('storage_cell_name') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Storage cell'][language]}
                  </div>
                  <span
                    className="linkToModel"
                    onClick={() =>
                      navigate(`/storage_cells/${shipment.storage_cell.id}`, {
                        state: { from: location },
                      })
                    }
                  >
                    {shipment.storage_cell_name}
                  </span>
                </div>
              )}
              {attrsForRead.has('responsible') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Responsible'][language]}
                  </div>
                  <div className="listModels">
                    {shipment.responsible.map((model) => {
                      return (
                        <div
                          className="modelBlock"
                          key={model.id}
                        >
                          {model.email}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {attrsForRead.has('co_executors') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Co executors'][language]}
                  </div>
                  <div className="listModels">
                    {shipment.co_executors.map((model) => {
                      return (
                        <div
                          className="modelBlock"
                          key={model.id}
                        >
                          {model.email}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {attrsForRead.has('qr_code') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Qr code'][language]}
                  </div>
                  {shipment.barcode_img &&
                    (() => {
                      let { mediaBlock, preViewImg } =
                        getPrevViewImgAndMediaBlockByImageObj(
                          shipment.barcode_img,
                          language
                        );
                      return (
                        <DropDownMediaBlock
                          preViewImg={preViewImg}
                          mediaBlock={mediaBlock}
                        />
                      );
                    })()}
                </div>
              )}
              {attrsForRead.has('logistics_documents') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Documents logístics'][language]}
                  </div>
                  <div className="listDropDomnMedia">
                    {shipment.logistics_documents.map((image, id) => {
                      let { mediaBlock, preViewImg } =
                        getPrevViewImgAndMediaBlockByImageObj(image, language);
                      return (
                        <DropDownMediaBlock
                          key={id}
                          preViewImg={preViewImg}
                          mediaBlock={mediaBlock}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {attrsForRead.has('media_content') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Media content'][language]}
                  </div>
                  <div className="listDropDomnMedia">
                    {shipment.media_content.map((image, id) => {
                      let { mediaBlock, preViewImg } =
                        getPrevViewImgAndMediaBlockByImageObj(image, language);
                      return (
                        <DropDownMediaBlock
                          key={id}
                          preViewImg={preViewImg}
                          mediaBlock={mediaBlock}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </OpeningBlock>
          </div>
          {shipment.type_logistics == 'cargo' && shipmentLogistics && (
            <div className={`${styles.viewLogisticsData}`}>
              <OpeningBlock
                title={interface_translate['Cargo delivery'][language]}
                open={true}
              >
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Type of transport'][language]}
                  </div>
                  <span>
                    {types[shipmentLogistics.type_transport][language]}
                  </span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Departure date'][language]}
                  </div>
                  <span>{shipmentLogistics.departure_date}</span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Departure date'][language]}
                  </div>
                  <span>
                    {shipmentLogistics.estimated_date_arrival_in_moscow}
                  </span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Total shipping cost'][language]}
                  </div>
                  <span>{shipmentLogistics.shipping_cost}</span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Rate'][language]}
                  </div>
                  <span>{shipmentLogistics.rate}</span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['The cost of insurance'][language]}
                  </div>
                  <span>{shipmentLogistics.insurance_cost}</span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['The cost of packaging'][language]}
                  </div>
                  <span>{shipmentLogistics.packing_cost}</span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Actual total weight in kg'][language]}
                  </div>
                  <span>{shipmentLogistics.actual_gross_weight_in_kg}</span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Actual total volume in м³'][language]}
                  </div>
                  <span>{shipmentLogistics.actual_gross_volume_in_m3}</span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Invoice number'][language]}
                  </div>
                  <span>{shipmentLogistics.invoice_number}</span>
                </div>
              </OpeningBlock>
            </div>
          )}
          {shipment.type_logistics == 'white' && shipmentLogistics && (
            <div className={`${styles.viewLogisticsData}`}>
              <OpeningBlock
                title={interface_translate['White delivery'][language]}
                open={true}
              >
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Type of transport'][language]}
                  </div>
                  <span>
                    {types[shipmentLogistics.type_transport][language]}
                  </span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Departure date'][language]}
                  </div>
                  <span>{shipmentLogistics.departure_date}</span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Departure date'][language]}
                  </div>
                  <span>
                    {shipmentLogistics.estimated_date_arrival_in_moscow}
                  </span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['The cost of delivery'][language]}
                  </div>
                  <span>{shipmentLogistics.shipping_cost}</span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['The cost of the FCS'][language]}
                  </div>
                  <span>{shipmentLogistics.fcs_cost}</span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Actual total weight in kg'][language]}
                  </div>
                  <span>{shipmentLogistics.actual_gross_weight_in_kg}</span>
                </div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Actual total volume in м³'][language]}
                  </div>
                  <span>{shipmentLogistics.actual_gross_volume_in_m3}</span>
                </div>
              </OpeningBlock>
            </div>
          )}
        </div>
      )}
      <div className={styles.childModels}>
        <div className="openingBlockAttrName">
          {interface_translate['Products'][language]}
        </div>
        <ModelView
          tableAttrs={productTableAttrs}
          models={products}
          useSelected={false}
          usePagination={false}
          useSettings={false}
          handleModelClick={(model) =>
            navigate(`/products/${model.id}`, {
              state: { from: location },
            })
          }
        />
      </div>
      {!shipment && <div>Пусто</div>}
    </div>
  );
};

export default ShipmentPage;
