import { useState, React, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import { api_error_translate } from '../../texts/error';
import { interface_translate } from '../../texts/interface';
import TextField from '@mui/material/TextField';
import DropDownMediaBlock from '../../components/DropDownMediaBlock';
import ListAttrMedia from '../../components/ListAttrMedia';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import roleAccess from '../../roleAccess';
import unidentifiedDeliveryService from '../../services/unidentified_delivery';
import userService from '../../services/user';
import storehouseService from '../../services/storehouse';
import storageCellService from '../../services/storage_cell';
import styles from './UnidentifiedDeliveryCreatePage.module.css';
import '../shared.css';
import ResponsibleListInput from '../../components/input/ResponsibleListInput';
import CoExecutorsInput from '../../components/input/CoExecutorsInput';

async function create(
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await unidentifiedDeliveryService.create(
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_users(filters = {}, size = 50) {
  const usersPaginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return usersPaginated.data;
}

async function get_storehouses(filters = {}, size = 50) {
  const storehousesPaginated = await storehouseService.get_by_filters(filters, {
    size: size,
  });
  return storehousesPaginated.data;
}

async function get_storage_cells(filters = {}, size = 50) {
  const storageCellsPaginated = await storageCellService.get_by_filters(
    filters,
    {
      size: size,
    }
  );
  return storageCellsPaginated.data;
}

const UnidentifiedDeliveryCreatePage = (props) => {
  const { trackNumber, handleExit } = props;
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
    user,
  } = useCommon();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState({
    track_number: trackNumber ? trackNumber : '',
  });
  const [defUsers, setDefUsers] = useState([]);
  const [storehouse, setStorehouse] = useState(null);
  const [storehouseInputValue, setStorehouseInputValue] = useState('');
  const [storehouseElementsForSelect, setStorehouseElementsForSelect] =
    useState([]);
  const [storageCellInputValue, setStorageCellInputValue] = useState('');
  const [storageCellElementsForSelect, setStorageCellElementsForSelect] =
    useState([]);
  const [responsible, setResponsible] = useState({});
  const [initialResponsible, setInitialResponsible] = useState([]);
  const [coExecutors, setCoExecutors] = useState({});
  const [mediaContentAcceptance, setMediaContentAcceptance] = useState({});
  const [attrsForCreate, setAttrsForCreate] = useState(new Set());

  useEffect(() => {
    const req = async () => {
      const users = await get_users();
      setDefUsers(users);
    };
    req();
    pushPage(location.pathname);
    const attrsForCreate = new Set(
      roleAccess[userRole].fieldRestrictions['Unidentified deliveries']?.create
    );
    setAttrsForCreate(attrsForCreate);
  }, []);

  useEffect(() => {
    setInitialResponsible([user]);
  }, [user]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (storehouseInputValue) filters['name'] = storehouseInputValue;
      const new_elements = await get_storehouses(filters);
      setStorehouseElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storehouseInputValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (!storehouse) return;
      let filters = { storehouse_id: storehouse.id };
      if (storageCellInputValue)
        filters['storage_cell_name'] = storageCellInputValue;
      const new_elements = await get_storage_cells(filters);
      setStorageCellElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storageCellInputValue]);

  const getDataFromAttr = () => {
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    for (let id of responsible_ids) {
      if (id === null) {
        setErrors({
          responsible_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const co_executor_ids = Object.values(coExecutors).map((m) =>
      m ? m.id : null
    );
    for (let id of co_executor_ids) {
      if (id === null) {
        setErrors({
          co_executors_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const data = {
      ...modelData,
      responsible_ids: responsible_ids,
      co_executors_ids: co_executor_ids,
      media_content_acceptance: Object.values(mediaContentAcceptance),
    };
    data.storage_cell_id = modelData.storage_cell
      ? modelData.storage_cell.id
      : null;
    delete data['storage_cell'];

    return data;
  };
  if (!user) return;
  return (
    <div className="pageCreateModel">
      <div className={styles.saveAndExitBtns}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await create(
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(
                interface_translate['Unidentified delivery created'][language]
              );
              resetGlobalError();
              setErrors({});
              if (handleExit) {
                handleExit();
                return;
              }
              navigate('..', { relative: 'path' });
            }
          }}
        >
          {interface_translate['Create'][language]}
        </button>
        <button
          onClick={() => {
            if (handleExit) {
              handleExit();
              return;
            }
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', {
              relative: 'path',
            });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            if (handleExit) {
              handleExit();
              return;
            }
            navigate('..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      <div className="inputData">
        <OpeningBlock
          title={interface_translate['About unidentified delivery'][language]}
          open={true}
        >
          {attrsForCreate.has('track_number') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Track number'][language]}
                </div>
                <input
                  type="text"
                  value={modelData.track_number}
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      track_number: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['track_number'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['track_number'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('total_weight_in_kg') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Total weight in kg'][language]}
                </div>
                <input
                  type="text"
                  value={modelData.total_weight_in_kg}
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      total_weight_in_kg: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['total_weight_in_kg'] && (
                <div className="openingBlockErrorAttr">
                  {
                    api_error_translate[errors['total_weight_in_kg'][0]][
                      language
                    ]
                  }
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('total_volume_in_m3') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Total volume in м³'][language]}
                </div>
                <input
                  type="text"
                  value={modelData.total_volume_in_m3}
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      total_volume_in_m3: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['total_volume_in_m3'] && (
                <div className="openingBlockErrorAttr">
                  {
                    api_error_translate[errors['total_volume_in_m3'][0]][
                      language
                    ]
                  }
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('actual_content') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Actual content'][language]}
                </div>
                <input
                  type="text"
                  value={modelData.actual_content}
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      actual_content: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['actual_content'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['actual_content'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('storehouse_name') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Storehouse'][language]}
                </div>
                <Autocomplete
                  value={storehouse}
                  onChange={async (e, newElement) => {
                    setStorehouse(newElement);
                    setModelData({ ...modelData, storage_cell: null });
                    const filters = { storehouse_id: newElement.id };
                    const new_storage_cells = await get_storage_cells(filters);
                    setStorageCellElementsForSelect(new_storage_cells);
                  }}
                  options={storehouseElementsForSelect}
                  getOptionLabel={(model) => model.name}
                  onInputChange={(e, value) => {
                    setStorehouseInputValue(value);
                    setStorehouseElementsForSelect([]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={interface_translate['Storehouse'][language]}
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={storageCellElementsForSelect.length == 0}
                />
              </div>
            </div>
          )}
          {attrsForCreate.has('storage_cell_name') && (
            <div>
              {storehouse && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Storage cell'][language]}
                  </div>
                  <Autocomplete
                    value={modelData.storage_cell}
                    disabled={!storehouse}
                    onChange={(e, newElement) => {
                      setModelData({
                        ...modelData,
                        storage_cell: newElement,
                      });
                    }}
                    options={storageCellElementsForSelect}
                    getOptionLabel={(model) =>
                      `${model.shelving.name}${model.number}`
                    }
                    onInputChange={(e, value) => {
                      setStorageCellInputValue(value);
                      setStorageCellElementsForSelect([]);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={interface_translate['Storage cell'][language]}
                        size="small"
                      />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    loading={storageCellElementsForSelect.length == 0}
                  />
                </div>
              )}
              {errors['storage_cell_id'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['storage_cell_id'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('responsible') && (
            <div>
              <ResponsibleListInput
                containerClass={'openingBlockAttr'}
                attrNameClass={'openingBlockAttrName'}
                responsible={responsible}
                setSelectedElement={(value) => {
                  setResponsible((prevData) => {
                    return { ...prevData, ...value };
                  });
                }}
                deleteSelectedElement={(id) => {
                  setResponsible((prevElements) => {
                    delete prevElements[id];
                    return { ...prevElements };
                  });
                }}
                initialElements={initialResponsible}
                defaultElements={defUsers}
              />
              {errors['responsible_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['responsible_ids'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('co_executors') && (
            <div>
              <CoExecutorsInput
                containerClass={'openingBlockAttr'}
                attrNameClass={'openingBlockAttrName'}
                coExecutors={coExecutors}
                setSelectedElement={(value) => {
                  setCoExecutors((prevData) => {
                    return { ...prevData, ...value };
                  });
                }}
                deleteSelectedElement={(id) => {
                  setCoExecutors((prevElements) => {
                    delete prevElements[id];
                    return { ...prevElements };
                  });
                }}
                defaultElements={defUsers}
              />
              {errors['co_executors_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['co_executors_ids'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('media_content_acceptance') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Media content of acceptance'][language]}
                  <p>{`${interface_translate['Allowed formats'][language]}: "jpg", "png", "webp"`}</p>
                  <p>{`"heic", "mp4", "mov", "pdf", "docx", "xlsx"`}</p>
                </div>
                <ListAttrMedia
                  setSelectedElement={(value) => {
                    setMediaContentAcceptance((prevData) => {
                      return { ...prevData, ...value };
                    });
                  }}
                  deleteSelectedElement={(id) => {
                    setMediaContentAcceptance((prevElements) => {
                      delete prevElements[id];
                      return { ...prevElements };
                    });
                  }}
                />
              </div>
              {errors['media_content_acceptance'] && (
                <div className="openingBlockErrorAttr">
                  {
                    api_error_translate[errors['media_content_acceptance'][0]][
                      language
                    ]
                  }
                </div>
              )}
            </div>
          )}
        </OpeningBlock>
      </div>
    </div>
  );
};

export default UnidentifiedDeliveryCreatePage;
