import { useState, React, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import { api_error_translate } from '../../texts/error';
import { statuses_translate } from '../../texts/statuses.js';
import { interface_translate } from '../../texts/interface';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import ConfirmDialog from '../../components/ConfirmDialog';
import ListAttrMedia from '../../components/ListAttrMedia';
import DropDownMediaBlock from '../../components/DropDownMediaBlock';
import unidentifiedDeliveryService from '../../services/unidentified_delivery';
import roleAccess from '../../roleAccess.js';
import userService from '../../services/user';
import storehouseService from '../../services/storehouse';
import storageCellService from '../../services/storage_cell';
import styles from './UnidentifiedDeliveryEditPage.module.css';
import '../shared.css';
import ResponsibleListInput from '../../components/input/ResponsibleListInput.jsx';
import CoExecutorsInput from '../../components/input/CoExecutorsInput.jsx';

async function get_unidentified_delivery(id) {
  const unidentifiedDelivery = await unidentifiedDeliveryService.get(id);
  return unidentifiedDelivery;
}

async function get_unidentified_delivery_statuses() {
  const statuses = await unidentifiedDeliveryService.get_statuses();
  return statuses;
}

async function update_unidentified_delivery(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await unidentifiedDeliveryService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_users(filters = {}, size = 50) {
  const usersPaginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return usersPaginated.data;
}

async function get_user(id) {
  const user = await userService.get(id);
  return user;
}

async function get_storehouses(filters = {}, size = 50) {
  const storehousesPaginated = await storehouseService.get_by_filters(filters, {
    size: size,
  });
  return storehousesPaginated.data;
}

async function get_storage_cells(filters = {}, size = 50) {
  const storageCellsPaginated = await storageCellService.get_by_filters(
    filters,
    {
      size: size,
    }
  );
  return storageCellsPaginated.data;
}

const UnidentifiedDeliveryEditPage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState();
  const [oldUnidentifiedDelivery, setOldUnidentifiedDelivery] = useState();
  const [attrsForEdit, setAttrsForEdit] = useState(new Set());
  const [defUsers, setDefUsers] = useState(null);
  const [unidentifiedDeliveryStatuses, setUnidentifiedDeliveryStatuses] = useState(
    []
  );
  const [mediaContentAcceptanceForCreate, setMediaContentAcceptanceForCreate] =
    useState({});
  const [
    selectedMediaContentAcceptanceIdToDelete,
    setSelectedMediaContentAcceptanceIdToDelete,
  ] = useState(null);
  const [
    viewDeleteMediaContentAcceptanceConfirmDialog,
    setViewDeleteMediaContentAcceptanceConfirmDialog,
  ] = useState(false);
  const [
    mediaContentAcceptanceIdForDelete,
    setMediaContentAcceptanceIdForDelete,
  ] = useState([]);
  const [storehouse, setStorehouse] = useState(null);
  const [storehouseInputValue, setStorehouseInputValue] = useState('');
  const [storehouseElementsForSelect, setStorehouseElementsForSelect] =
    useState([]);
  const [storageCellInputValue, setStorageCellInputValue] = useState('');
  const [storageCellElementsForSelect, setStorageCellElementsForSelect] =
    useState([]);
  const [responsible, setResponsible] = useState({});
  const [coExecutors, setCoExecutors] = useState({});

  useEffect(() => {
    const req = async () => {
      const unidentified_delivery_resp = await get_unidentified_delivery(params.id);
      const responsible = [];
      for (const user_id of unidentified_delivery_resp.responsible_ids) {
        const user = await get_user(user_id);
        responsible.push(user);
      }
      unidentified_delivery_resp.responsible = responsible;
      const co_executors = [];
      for (const user_id of unidentified_delivery_resp.co_executors_ids) {
        const user = await get_user(user_id);
        co_executors.push(user);
      }
      unidentified_delivery_resp.coExecutors = co_executors;

      if (!unidentified_delivery_resp.total_weight_in_kg)
        unidentified_delivery_resp.total_weight_in_kg = '';
      if (!unidentified_delivery_resp.total_volume_in_m3)
        unidentified_delivery_resp.total_volume_in_m3 = '';
      if (!unidentified_delivery_resp.actual_content)
        unidentified_delivery_resp.actual_content = '';
      setModelData(unidentified_delivery_resp);
      setOldUnidentifiedDelivery({ ...unidentified_delivery_resp });

      if (unidentified_delivery_resp.storage_cell) {
        setStorehouse(
          unidentified_delivery_resp.storage_cell.shelving.storehouse
        );
      }
      const unidentified_deliveryStatuses =
        await get_unidentified_delivery_statuses();
      setUnidentifiedDeliveryStatuses(unidentified_deliveryStatuses);

      const users = await get_users();
      setDefUsers(users);
    };
    req();
    pushPage(location.pathname);
    const attrsForEdit = new Set(
      roleAccess[userRole].fieldRestrictions['Unidentified deliveries']?.edit
    );
    setAttrsForEdit(attrsForEdit);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (storehouseInputValue) filters['name'] = storehouseInputValue;
      const new_elements = await get_storehouses(filters);
      setStorehouseElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storehouseInputValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (!storehouse) return;
      let filters = { storehouse_id: storehouse.id };
      if (storageCellInputValue)
        filters['storage_cell_name'] = storageCellInputValue;
      const new_elements = await get_storage_cells(filters);
      setStorageCellElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storageCellInputValue]);

  const getDataFromAttr = () => {
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    for (let id of responsible_ids) {
      if (id === null) {
        setErrors({
          responsible_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const co_executor_ids = Object.values(coExecutors).map((m) =>
      m ? m.id : null
    );
    for (let id of co_executor_ids) {
      if (id === null) {
        setErrors({
          co_executors_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const data = {
      track_number: modelData.track_number,
      status: modelData.status,
      responsible_ids: responsible_ids,
      co_executors_ids: co_executor_ids,
      media_content_acceptance_for_create: Object.values(
        mediaContentAcceptanceForCreate
      ),
      media_content_acceptance_id_for_delete: mediaContentAcceptanceIdForDelete,
    };

    if (modelData.total_weight_in_kg) {
      data.total_weight_in_kg = modelData.total_weight_in_kg;
    }
    if (modelData.total_volume_in_m3) {
      data.total_volume_in_m3 = modelData.total_volume_in_m3;
    }
    if (modelData.actual_content) {
      data.actual_content = modelData.actual_content;
    }
    if (modelData.storage_cell !== oldUnidentifiedDelivery.storage_cell) {
      data.storage_cell_id = modelData.storage_cell
        ? modelData.storage_cell.id
        : null;
    }
    return data;
  };

  if (!modelData) return;

  return (
    <div className="pageUpdateModel">
      {viewDeleteMediaContentAcceptanceConfirmDialog && (
        <ConfirmDialog
          message={interface_translate['Delete?'][language]}
          onConfirm={() => {
            setViewDeleteMediaContentAcceptanceConfirmDialog(false);
            setMediaContentAcceptanceIdForDelete((prevData) => {
              const new_data = [
                ...prevData,
                selectedMediaContentAcceptanceIdToDelete,
              ];
              return new_data;
            });
            setSelectedMediaContentAcceptanceIdToDelete(null);
            setModelData({
              ...modelData,
              media_content_acceptance:
                modelData.media_content_acceptance.filter(
                  (model) =>
                    model.id !== selectedMediaContentAcceptanceIdToDelete
                ),
            });
          }}
          onCancel={() =>
            setViewDeleteMediaContentAcceptanceConfirmDialog(false)
          }
        />
      )}
      <div className={styles.controls}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await update_unidentified_delivery(
              params.id,
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(
                interface_translate['Unidentified delivery updated'][language]
              );
              resetGlobalError();
              setErrors({});
              popPage();
              const previousPage = getCurrentPage();
              navigate(previousPage ? previousPage : '..', {
                relative: 'path',
              });
            }
          }}
        >
          {interface_translate['Update'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('../..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      {modelData.hasOwnProperty('responsible') && (
        <>
          <div className="inputData">
            <OpeningBlock
              title={
                interface_translate['About unidentified delivery'][language]
              }
              open={true}
            >
              {attrsForEdit.has('track_number') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Track number'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.track_number}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          track_number: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['track_number'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['track_number'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('status_translate') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Status'][language]}
                    </div>
                    <select
                      value={modelData.status}
                      onChange={(e) => {
                        setModelData({ ...modelData, status: e.target.value });
                      }}
                    >
                      {unidentifiedDeliveryStatuses.map((status, id) => (
                        <option
                          key={id}
                          value={status}
                        >
                          {statuses_translate[status][language]}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors['status'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['status'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('total_weight_in_kg') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Total weight in kg'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.total_weight_in_kg}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          total_weight_in_kg: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['total_weight_in_kg'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['total_weight_in_kg'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('total_volume_in_m3') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Total volume in м³'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.total_volume_in_m3}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          total_volume_in_m3: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['total_volume_in_m3'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['total_volume_in_m3'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('actual_content') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Actual content'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.actual_content}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          actual_content: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['actual_content'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['actual_content'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('storehouse_name') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Storehouse'][language]}
                    </div>
                    <Autocomplete
                      value={storehouse}
                      onChange={async (e, newElement) => {
                        setStorehouse(newElement);
                        if (!newElement) {
                          setModelData({ ...modelData, storage_cell: null });
                          return;
                        }
                        const filters = { storehouse_id: newElement.id };
                        const new_storage_cells = await get_storage_cells(
                          filters
                        );
                        setStorageCellElementsForSelect(new_storage_cells);
                      }}
                      options={storehouseElementsForSelect}
                      getOptionLabel={(model) => model.name}
                      onInputChange={(e, value) => {
                        setStorehouseInputValue(value);
                        setStorehouseElementsForSelect([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={interface_translate['Storehouse'][language]}
                          size="small"
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      loading={storageCellElementsForSelect.length == 0}
                    />
                  </div>
                </div>
              )}
              {attrsForEdit.has('storage_cell_name') && (
                <div>
                  {storehouse && (
                    <div className="openingBlockAttr">
                      <div className="openingBlockAttrName">
                        {interface_translate['Storage cell'][language]}
                      </div>
                      <Autocomplete
                        value={modelData.storage_cell}
                        onChange={(e, newElement) => {
                          setModelData({
                            ...modelData,
                            storage_cell: newElement,
                          });
                        }}
                        options={storageCellElementsForSelect}
                        getOptionLabel={(model) =>
                          `${model.shelving.name}${model.number}`
                        }
                        onInputChange={(e, value) => {
                          setStorageCellInputValue(value);
                          setStorageCellElementsForSelect([]);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={
                              interface_translate['Storage cell'][language]
                            }
                            size="small"
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        loading={storageCellElementsForSelect.length == 0}
                      />
                    </div>
                  )}
                  {errors['storage_cell_id'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['storage_cell_id'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('responsible') && (
                <div>
                  <ResponsibleListInput
                    containerClass={'openingBlockAttr'}
                    attrNameClass={'openingBlockAttrName'}
                    responsible={responsible}
                    setSelectedElement={(value) => {
                      setResponsible((prevData) => {
                        return { ...prevData, ...value };
                      });
                    }}
                    deleteSelectedElement={(id) => {
                      setResponsible((prevElements) => {
                        delete prevElements[id];
                        return { ...prevElements };
                      });
                    }}
                    initialElements={modelData.responsible}
                    defaultElements={defUsers}
                  />
                  {errors['responsible_ids'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['responsible_ids'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('co_executors') && (
                <div>
                  <CoExecutorsInput
                    containerClass={'openingBlockAttr'}
                    attrNameClass={'openingBlockAttrName'}
                    coExecutors={coExecutors}
                    setSelectedElement={(value) => {
                      setCoExecutors((prevData) => {
                        return { ...prevData, ...value };
                      });
                    }}
                    deleteSelectedElement={(id) => {
                      setCoExecutors((prevElements) => {
                        delete prevElements[id];
                        return { ...prevElements };
                      });
                    }}
                    initialElements={modelData.coExecutors}
                    defaultElements={defUsers}
                  />
                  {errors['co_executors_ids'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['co_executors_ids'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('media_content_acceptance') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {
                        interface_translate['Media content of acceptance'][
                          language
                        ]
                      }
                      <p>{`${interface_translate['Allowed formats'][language]}: "jpg", "png", "webp"`}</p>
                      <p>{`"heic", "mp4", "mov", "pdf", "docx", "xlsx"`}</p>
                    </div>
                    <div>
                      <div className="listDropDomnMedia">
                        {modelData.media_content_acceptance.map((model, id) => {
                          let mediaBlock;
                          let preViewImg;
                          if (model.file_type === 'photo') {
                            mediaBlock = (
                              <img
                                src={model.url}
                                alt="image"
                              />
                            );
                            preViewImg = mediaBlock;
                          } else if (model.file_type === 'video') {
                            mediaBlock = (
                              <video
                                src={model.url}
                                alt="video"
                                controls
                                style={{ maxWidth: '100%' }}
                              />
                            );
                            preViewImg = (
                              <p>{interface_translate['Video'][language]}</p>
                            );
                          }
                          return (
                            <div className="DropDownMediaBlockDelete">
                              <DropDownMediaBlock
                                key={id}
                                preViewImg={preViewImg}
                                mediaBlock={mediaBlock}
                              />
                              <button
                                onClick={() => {
                                  setSelectedMediaContentAcceptanceIdToDelete(
                                    model.id
                                  );
                                  setViewDeleteMediaContentAcceptanceConfirmDialog(
                                    true
                                  );
                                }}
                              >
                                Удалить
                              </button>
                            </div>
                          );
                        })}
                      </div>
                      <ListAttrMedia
                        setSelectedElement={(value) => {
                          setMediaContentAcceptanceForCreate((prevData) => {
                            return { ...prevData, ...value };
                          });
                        }}
                        deleteSelectedElement={(id) => {
                          setMediaContentAcceptanceForCreate((prevElements) => {
                            delete prevElements[id];
                            return { ...prevElements };
                          });
                        }}
                      />
                    </div>
                  </div>
                  {errors['media_content_acceptance'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[
                          errors['media_content_acceptance'][0]
                        ][language]
                      }
                    </div>
                  )}
                </div>
              )}
            </OpeningBlock>
          </div>
        </>
      )}
      {!modelData && <div>Пусто</div>}
    </div>
  );
};

export default UnidentifiedDeliveryEditPage;
