import styles from './Filter.module.css';

const Filter = (props) => {
  const { title_name, children } = props;
  return (
    <div className={styles.filter}>
      <p>{title_name}</p>
      {children}
    </div>
  );
};

export default Filter;
