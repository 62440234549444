import { useState, React, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { api_error_translate } from '../../texts/error';
import { types } from '../../texts/types';
import { interface_translate } from '../../texts/interface';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import TextField from '@mui/material/TextField';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import roleAccess from '../../roleAccess';
import counterpartyService from '../../services/counterparty';
import userService from '../../services/user';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styles from './CounterpartyCreatePage.module.css';
import '../shared.css';
import {
  admin,
  client_realtions_manager,
  supplier_realtions_manager,
  roman_assistant,
} from '../../roles';
import ResponsibleListInput from '../../components/input/ResponsibleListInput';

async function create(
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await counterpartyService.create(
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_users(filters = {}, size = 50) {
  const result = await userService.get_by_filters(filters, { size: size });
  return result.data;
}

const CounterpartyCreatePage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
    user,
  } = useCommon();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState({});
  const [responsible, setResponsible] = useState({});
  const [initialResponsible, setInitialResponsible] = useState([]);
  const [defUsers, setDefUsers] = useState(null);
  const [attrsForCreate, setAttrsForCreate] = useState(new Set());

  useEffect(() => {
    const req = async () => {
      const users = await get_users({});
      setDefUsers(users);

      if (
        [client_realtions_manager, admin, roman_assistant].includes(userRole)
      ) {
        setModelData({ group: 'client' });
      } else if (userRole === supplier_realtions_manager) {
        setModelData({ group: 'supplier' });
      }
    };
    req();
    pushPage(location.pathname);
    const attrsForCreate = new Set(
      roleAccess[userRole].fieldRestrictions.Counterparties?.create
    );
    setAttrsForCreate(attrsForCreate);
  }, []);

  useEffect(() => {
    setInitialResponsible([user]);
  }, [user]);

  const getDataFromAttr = () => {
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    for (let id of responsible_ids) {
      if (id === null) {
        setErrors({
          responsible_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const data = {
      contact_person: '',
      link_to_messenger: null,
      ...modelData,
      responsible_ids: responsible_ids,
    };
    return data;
  };

  if (!user) return;
  return (
    <div className="pageCreateModel">
      <div>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await create(
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(
                interface_translate['Counterpaty created'][language]
              );
              resetGlobalError();
              setErrors({});
              navigate('..', { relative: 'path' });
            }
          }}
        >
          {interface_translate['Create'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      <div className="inputData">
        {attrsForCreate.has('name') && (
          <div className="inputName">
            <p>{interface_translate['Company'][language]}</p>
            <input
              type="text"
              name="name"
              onChange={(e) => {
                setModelData({ ...modelData, name: e.target.value });
              }}
            />
            {errors['name'] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[errors['name'][0]][language]}
              </div>
            )}
          </div>
        )}
        <OpeningBlock
          title={interface_translate['About сounterparty'][language]}
          open={true}
        >
          {attrsForCreate.has('group_translate') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Group'][language]}
                </div>
                {userRole == client_realtions_manager && (
                  <div>{types['client'][language]}</div>
                )}
                {userRole == supplier_realtions_manager && (
                  <div>{types['supplier'][language]}</div>
                )}
                {[admin, roman_assistant].includes(userRole) && (
                  <select
                    onChange={(e) => {
                      setModelData({ ...modelData, group: e.target.value });
                    }}
                  >
                    <option value="client">{types['client'][language]}</option>
                    <option value="supplier">
                      {types['supplier'][language]}
                    </option>
                  </select>
                )}
              </div>
              {errors['group'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['group'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('phone_number') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Phone number'][language]}
                </div>
                <PhoneInput
                  countryCodeEditable={false}
                  country={'ru'}
                  onlyCountries={['ru', 'cn']}
                  value={modelData.phone_number}
                  onChange={(v) => {
                    setModelData({ ...modelData, phone_number: `+${v}` });
                  }}
                />
              </div>
              {errors['phone_number'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['phone_number'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('contact_person') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Contact person'][language]}
                </div>
                <input
                  type="text"
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      contact_person: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['contact_person'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['contact_person'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('link_to_messenger') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Link to messenger'][language]}
                </div>
                <input
                  type="text"
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      link_to_messenger: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['link_to_messenger'] && (
                <div className="openingBlockErrorAttr">
                  {
                    api_error_translate[errors['link_to_messenger'][0]][
                      language
                    ]
                  }
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('responsible') && (
            <div>
              <ResponsibleListInput
                containerClass={'openingBlockAttr'}
                attrNameClass={'openingBlockAttrName'}
                responsible={responsible}
                setSelectedElement={(value) => {
                  setResponsible((prevData) => {
                    return { ...prevData, ...value };
                  });
                }}
                deleteSelectedElement={(id) => {
                  setResponsible((prevElements) => {
                    delete prevElements[id];
                    return { ...prevElements };
                  });
                }}
                initialElements={initialResponsible}
                defaultElements={defUsers}
              />
              {errors['responsible_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['responsible_ids'][0]][language]}
                </div>
              )}
            </div>
          )}
        </OpeningBlock>
      </div>
    </div>
  );
};

export default CounterpartyCreatePage;
