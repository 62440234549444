import { useState, React, useEffect } from 'react';
import { useNavigate, useLocation, defer } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import { api_error_translate } from '../../texts/error';
import { interface_translate } from '../../texts/interface';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import roleAccess from '../../roleAccess';
import deliveryService from '../../services/delivery';
import orderService from '../../services/order';
import userService from '../../services/user';
import productService from '../../services/product';
import styles from './DeliveryCreatePage.module.css';
import '../shared.css';
import ResponsibleListInput from '../../components/input/ResponsibleListInput';
import CoExecutorsInput from '../../components/input/CoExecutorsInput';
import ProductsWithQtyInput from '../../components/input/ProductsWithQtyInput';

async function create(
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await deliveryService.create(
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_order(id) {
  const order = await orderService.get(id);
  return order;
}

async function get_orders(filters = {}, size = 50) {
  const orders_paginated = await orderService.get_by_filters(filters, {
    size: size,
  });
  return orders_paginated.data;
}

async function get_products(order_id, name, supplier_id, size = 50) {
  const filters = {
    name: name,
    order_id: order_id,
    supplier_id: supplier_id,
    delivery_id: null,
  };

  try {
    const products_paginated = await productService.get_by_filters(filters, {
      size: size,
    });
    return products_paginated.data;
  } catch (err) {
    console.error(`Could not fetch data: ${err}`);
  }
}

async function get_users(filters = {}, size = 50) {
  const user_paginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return user_paginated.data;
}

async function get_user(id) {
  const user = await userService.get(id);
  return user;
}

const DeliveryCreatePage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
    user,
  } = useCommon();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const order_id = location.state?.order_id || null;
  const [defUsers, setDefUsers] = useState([]);
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState({});
  const [orderInputValue, setOrderInputValue] = useState(null);
  const [orderElementsForSelect, setOrderElementsForSelect] = useState([]);
  const [supplier, setSupplier] = useState(null);
  const [suppliersForSelect, setSuppliersForSelect] = useState([]);
  const [productsForSelect, setProductsForSelect] = useState([]);
  const [products, setProducts] = useState({});
  const [responsible, setResponsible] = useState({});
  const [initialResponsible, setInitialResponsible] = useState([]);
  const [coExecutors, setCoExecutors] = useState({});
  const [responsibleFromOrder, setResponsibleFromOrder] = useState([]);
  const [
    clientRealtionsManagersFromOrder,
    setClientRealtionsManagersFromOrder,
  ] = useState([]);
  const [attrsForCreate, setAttrsForCreate] = useState(new Set());

  useEffect(() => {
    const req = async () => {
      if (order_id != null) {
        const order = await get_order(order_id);
        setOrder(order);
        setModelData({ ...modelData, order: order });
      }
      const users = await get_users();
      setDefUsers(users);
    };
    req();
    pushPage(location.pathname);
    const attrsForCreate = new Set(
      roleAccess[userRole].fieldRestrictions.Deliveries?.create
    );
    setAttrsForCreate(attrsForCreate);
  }, []);

  console.log(clientRealtionsManagersFromOrder);

  useEffect(() => {
    setInitialResponsible([user]);
  }, [user]);

  const setOrder = async (newOrder) => {
    const supplier_id_to_supplier = {};
    if (newOrder) {
      for (const product of newOrder.products) {
        if (product.delivery_id == null && product.supplier) {
          supplier_id_to_supplier[product.supplier.id] = product.supplier;
        }
      }
      const responsible = [];
      const newClientRealtionsManagersFromOrder = [];
      for (const user_id of newOrder.responsible_ids) {
        const user = await get_user(user_id);
        responsible.push(user);
        if (('client_realtions_manager', 'admin').includes(user.role)) {
          newClientRealtionsManagersFromOrder.push(user);
        }
      }
      newOrder.responsible = responsible;
      setResponsibleFromOrder(responsible);
      setClientRealtionsManagersFromOrder(newClientRealtionsManagersFromOrder);
    } else {
      setClientRealtionsManagersFromOrder([]);
    }
    const newSuppliersForSelect = [
      ...new Set(Object.values(supplier_id_to_supplier)),
    ];
    setSupplier(null);
    setProductsForSelect([]);
    setSuppliersForSelect(newSuppliersForSelect);
    modelData.products = [];
    setModelData({
      ...modelData,
      order: newOrder,
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (orderInputValue) filters['id'] = orderInputValue;
      const new_elements = await get_orders(filters);
      setOrderElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [orderInputValue]);

  const getDataFromAttr = () => {
    if (modelData.order == null) {
      setErrors({
        order_id: ['There are blank fields left'],
      });
      return;
    }
    const delivery_products = Object.values(products).map((m) => {
      return { product_id: m.product.id, qty: m.qty };
    });
    for (let delivery_product of delivery_products) {
      if (!delivery_product.product_id || !delivery_product.qty) {
        setErrors({
          products_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    for (let id of responsible_ids) {
      if (id === null) {
        setErrors({
          responsible_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const co_executor_ids = Object.values(coExecutors).map((m) =>
      m ? m.id : null
    );
    for (let id of co_executor_ids) {
      if (id === null) {
        setErrors({
          co_executors_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const data = {
      ...modelData,
      order_id: modelData.order.id,
      delivery_products: delivery_products,
      responsible_ids: responsible_ids,
      co_executors_ids: co_executor_ids,
    };
    delete data['order'];
    return data;
  };
  if (order_id && !modelData.hasOwnProperty('order')) return;
  if (!user) return;

  return (
    <div className="pageCreateModel">
      <div className={styles.saveAndExitBtns}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await create(
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(interface_translate['Delivery create'][language]);
              resetGlobalError();
              setErrors({});
              navigate('..', { relative: 'path' });
            }
          }}
        >
          {interface_translate['Create'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      <div className={`inputData ${styles.inputData}`}>
        <OpeningBlock
          title={interface_translate['About delivery'][language]}
          open={true}
        >
          {attrsForCreate.has('order_id') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Order'][language]}
                </div>
                <Autocomplete
                  value={modelData.order}
                  onChange={(e, newElement) => {
                    setOrder(newElement);
                  }}
                  options={orderElementsForSelect}
                  getOptionLabel={(model) => model.id.toString()}
                  onInputChange={(e, value) => {
                    setOrderInputValue(value);
                    setOrderElementsForSelect([]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={interface_translate['Order'][language]}
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={orderElementsForSelect.length == 0}
                />
              </div>
              {errors['order_id'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['order_id'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('supplier') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Supplier'][language]}
                </div>
                {modelData.order !== null && modelData.order !== undefined && (
                  <Autocomplete
                    value={supplier}
                    onChange={async (e, newElement) => {
                      setSupplier(newElement);
                      setProducts([]);
                      let new_products = [];
                      if (newElement) {
                        new_products = await get_products(
                          modelData.order.id,
                          '',
                          newElement.id
                        );
                      } else {
                        new_products = [];
                      }
                      setProductsForSelect(new_products);
                      setModelData({
                        ...modelData,
                        products: [],
                      });
                    }}
                    options={suppliersForSelect}
                    getOptionLabel={(model) => model.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={interface_translate['Supplier'][language]}
                        size="small"
                      />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    loading={orderElementsForSelect.length == 0}
                  />
                )}
              </div>
            </div>
          )}
          {attrsForCreate.has('products') && (
            <div>
              {supplier !== null && (
                <ProductsWithQtyInput
                  containerClass={'openingBlockAttr'}
                  attrNameClass={'openingBlockAttrName'}
                  products={products}
                  setSelectedElement={(value) => {
                    setProducts((prevData) => {
                      return { ...prevData, ...value };
                    });
                  }}
                  deleteSelectedElement={(id) => {
                    setProducts((prevElements) => {
                      delete prevElements[id];
                      return { ...prevElements };
                    });
                  }}
                  supplier={supplier}
                  order={modelData.order}
                  defaultElements={productsForSelect}
                  product_ids_errors={(() => {
                    const err_data = errors['delivery_product.qty'];
                    if (!err_data) return {};
                    return {
                      [err_data.productId]: `Превышает допустимый лимит ${err_data.availableStock}`,
                    };
                  })()}
                  errorContainerClass={'openingBlockErrorAttr'}
                />
              )}
              {errors['products_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['products_ids'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('track_number') && (
            <div>
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Track number'][language]}
                  </div>
                  <input
                    type="text"
                    onChange={(e) => {
                      setModelData({
                        ...modelData,
                        track_number: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              {errors['track_number'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['track_number'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('responsible') && (
            <div>
              <ResponsibleListInput
                containerClass={'openingBlockAttr'}
                attrNameClass={'openingBlockAttrName'}
                responsible={responsible}
                setSelectedElement={(value) => {
                  setResponsible((prevData) => {
                    return { ...prevData, ...value };
                  });
                }}
                deleteSelectedElement={(id) => {
                  setResponsible((prevElements) => {
                    delete prevElements[id];
                    return { ...prevElements };
                  });
                }}
                initialElements={initialResponsible}
                defaultElements={defUsers}
              />
              {errors['responsible_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['responsible_ids'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('co_executors') && (
            <div>
              <CoExecutorsInput
                containerClass={'openingBlockAttr'}
                attrNameClass={'openingBlockAttrName'}
                coExecutors={coExecutors}
                setSelectedElement={(value) => {
                  setCoExecutors((prevData) => {
                    return { ...prevData, ...value };
                  });
                }}
                deleteSelectedElement={(id) => {
                  setCoExecutors((prevElements) => {
                    delete prevElements[id];
                    return { ...prevElements };
                  });
                }}
                defaultElements={defUsers}
                initialElements={clientRealtionsManagersFromOrder}
              />
              {errors['co_executors_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['co_executors_ids'][0]][language]}
                </div>
              )}
            </div>
          )}
        </OpeningBlock>
      </div>
    </div>
  );
};

export default DeliveryCreatePage;
