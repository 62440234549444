import apiRequester from '../ApiRequester';
import { model_names } from '../texts/model_names.js';
import { api_error_translate } from '../texts/error.js';
import unidentifiedDeliveryService from './unidentified_delivery.js';

class ProductService {
  async create(data, setErrors, setGlobalError, resetGlobalMsg, language) {
    const co_executor_ids_already_in_responsible_ids_pattern =
      /ids:.*already in responsible_ids/;
    try {
      const formData = new FormData();
      if (data.name) formData.append('name', data.name);
      if (data.description) formData.append('description', data.description);
      if (data.link) formData.append('link', data.link);
      if (data.measurement_unit)
        formData.append('measurement_unit', data.measurement_unit);
      if (data.qty) formData.append('qty', data.qty);
      if (data.price) formData.append('price', data.price);
      if (data.currency) formData.append('currency', data.currency);
      if (data.supplier_id) formData.append('supplier_id', data.supplier_id);
      formData.append('order_id', data.order_id);
      data.responsible_ids?.forEach((m) => {
        formData.append(`responsible_ids`, m);
      });
      data.co_executors_ids?.forEach((m) => {
        formData.append(`co_executors_ids`, m);
      });
      data.images?.forEach((m) => {
        formData.append(`images`, m);
      });
      const response = await apiRequester.post('/products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (detail_msg === 'product already exists') {
            setErrors({});
            setGlobalError(api_error_translate[detail_msg][language]);
            resetGlobalMsg();
          } else if (
            err_msg.hasOwnProperty('co_executors_ids') &&
            co_executor_ids_already_in_responsible_ids_pattern.test(
              err_msg['co_executors_ids']
            )
          ) {
            setErrors({
              co_executors_ids: ['co_executors_ids already in responsible_ids'],
            });
            return;
          } else if (
            err_msg['responsible_ids'] &&
            err_msg['responsible_ids'][0] ===
              'Input should be a valid integer, unable to parse string as an integer'
          ) {
            err_msg['responsible_ids'] = ['Least one'];
            delete err_msg['co_executors_ids'];
            return;
          }
          setErrors({ ...err_msg });
          resetGlobalMsg();
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
    }
  }

  async get(id) {
    try {
      const response = await apiRequester.get(`/products/${id}`);
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }

  async get_by_filters(filterData, paginationSettings = { size: 20 }) {
    const jsonData = JSON.stringify(filterData);
    try {
      const response = await apiRequester.post(`/products/filters`, jsonData, {
        params: paginationSettings,
      });
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }

  async get_all_received_products(paginationSettings = { size: 20 }) {
    try {
      const response = await apiRequester.get(`/products/received_products`, {
        params: paginationSettings,
      });
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }

  async get_statuses() {
    try {
      const response = await apiRequester.get(`/products/statuses`);
      const statuses = response.data;
      return statuses;
    } catch (err) {
      if (err.response.status !== 200) {
        throw new Error(`HTTP error: ${err.response.status}`);
      }
      console.error(`Could not fetch data: ${err}`);
    }
  }

  async update(id, data, setErrors, setGlobalError, resetGlobalMsg, language) {
    const co_executor_ids_already_in_responsible_ids_pattern =
      /ids: .* already in responsible_ids/;
    try {
      const formData = new FormData();
      if (data.name !== undefined) {
        formData.append('name', data.name ? data.name : JSON.stringify(''));
      }
      if (data.description !== undefined) {
        formData.append(
          'description',
          data.description ? data.description : JSON.stringify('')
        );
      }
      if (data.order_id !== undefined) {
        formData.append('order_id', data.order_id);
      }
      if (data.supplier_id !== undefined) {
        formData.append('supplier_id', data.supplier_id);
      }
      if (data.status !== undefined) {
        formData.append('status', data.status);
      }
      if (data.measurement_unit !== undefined) {
        formData.append(
          'measurement_unit',
          data.measurement_unit ? data.measurement_unit : null
        );
      }
      if (data.qty !== undefined) {
        formData.append('qty', data.qty ? data.qty : null);
      }
      if (data.price !== undefined) {
        formData.append('price', data.price ? data.price : null);
      }
      if (data.currency !== undefined) {
        formData.append('currency', data.currency);
      }
      if (data.link !== undefined) {
        formData.append('link', data.link ? data.link : null);
      }
      if (data.delivery_id !== undefined) {
        formData.append('delivery_id', data.delivery_id);
      }
      if (data.shipment_id !== undefined) {
        formData.append('shipment_id', data.shipment_id);
      }
      if (data.customer_cargo_id !== undefined) {
        formData.append('customer_cargo_id', data.customer_cargo_id);
      }
      if (data.responsible_ids !== undefined) {
        if (data.responsible_ids.length > 0) {
          data.responsible_ids.forEach((m) => {
            formData.append(`responsible_ids`, m);
          });
        } else {
          formData.append('responsible_ids', -1);
        }
      }
      if (data.co_executors_ids !== undefined) {
        if (data.co_executors_ids.length > 0) {
          data.co_executors_ids?.forEach((m) => {
            formData.append(`co_executors_ids`, m);
          });
        } else {
          formData.append('co_executors_ids', -1);
        }
      }
      if (data.images_for_create !== undefined) {
        data.images_for_create?.forEach((m) => {
          formData.append(`images_for_create`, m);
        });
      }
      if (data.images_id_for_delete !== undefined) {
        if (data.images_id_for_delete?.length > 0) {
          data.images_id_for_delete?.forEach((m) => {
            formData.append(`images_id_for_delete`, m);
          });
        } else {
          formData.append('images_id_for_delete', -1);
        }
      }
      if (data.qty_ordered_products !== undefined) {
        formData.append('qty_ordered_products', data.qty_ordered_products)
      }
      if (data.qty_products_in_china !== undefined) {
        formData.append('qty_products_in_china', data.qty_products_in_china)
      }
      if (data.qty_shipped_products !== undefined) {
        formData.append('qty_shipped_products', data.qty_shipped_products)
      }
      if (data.qty_products_in_russia !== undefined) {
        formData.append('qty_products_in_russia', data.qty_products_in_russia)
      }
      if (data.qty_issued_products !== undefined) {
        formData.append('qty_issued_products', data.qty_issued_products)
      }
      await apiRequester.patch(`/products/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (detail_msg === 'product already exists') {
            setErrors({});
            setGlobalError(api_error_translate[detail_msg][language]);
            resetGlobalMsg();
          } else if (
            err_msg.hasOwnProperty('co_executors_ids') &&
            co_executor_ids_already_in_responsible_ids_pattern.test(
              err_msg['co_executors_ids']
            )
          ) {
            setErrors({
              co_executors_ids: ['co_executors_ids already in responsible_ids'],
            });
            return;
          } else if (
            err_msg['responsible_ids'] &&
            err_msg['responsible_ids'][0] ===
              'Input should be a valid integer, unable to parse string as an integer'
          ) {
            err_msg['responsible_ids'] = ['Least one'];
            delete err_msg['co_executors_ids'];
            return;
          }
          setErrors({ ...err_msg });
          resetGlobalMsg();
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
    }
  }

  async add_info_about_supplier(id, data, setErrors) {
    console.log(data);
    try {
      let result = await apiRequester.put(
        `/products/${id}/add_info_about_supplier`,
        data
      );
      result = result.data;
      return result;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          setErrors({ ...err_msg });
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
    }
  }

  async delete(id, setGlobalError, language) {
    try {
      const response = await apiRequester.delete(`/products/${id}`);
      return response.data;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (err_msg === 'foreign connection') {
            let msg = `${api_error_translate['linked to another model'][language]}: ${model_names[detail_msg][language]}`;
            setGlobalError(msg);
          }
        }
        return;
      }
      console.error(`Could not fetch data: ${err}`);
      throw err;
    }
  }
}

const productService = new ProductService();
export default productService;
