import { useState, useEffect } from 'react';
import useCommon from '../../hooks/useCommon';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import ModelView from '../../components/models_view/ModelView.jsx';
import ModelsControls from '../../components/models_view/ModelsControls.jsx';
import ModelFilters from '../../components/ModelFilters.jsx';
import TextFilter from '../../components/filters/TextFilter.jsx';
import SelectFilter from '../../components/filters/SelectFilter.jsx';
import AutocompleteFilter from '../../components/filters/AutocompleteFilter.jsx';
import { types } from '../../texts/types.js';
import { model_names } from '../../texts/model_names.js';
import { api_error_translate } from '../../texts/error.js';
import { interface_translate } from '../../texts/interface.js';
import useViewSettings from '../../hooks/useViewSettings.jsx';
import counterpartyService from '../../services/counterparty.js';
import userService from '../../services/user.js';
import '../../App.css';
import roleAccess from '../../roleAccess.js';
import {
  client_realtions_manager,
  supplier_realtions_manager,
} from '../../roles.js';
import { useLocation } from 'react-router-dom';

async function get_users(filters, size = 50) {
  const users_paginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return users_paginated.data;
}

const table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'counterparty id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'name',
    name_for_display: 'company',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'group_translate',
    name_for_display: 'group',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'phone_number',
    name_for_display: 'phone number',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'contact_person',
    name_for_display: 'contact person',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'link_to_messenger',
    name_for_display: 'link to messenger',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const CounterpartiesPage = () => {
  const { setGlobalError, language, userRole } = useCommon();
  const { allViewSettings, setAllViewSettings } = useViewSettings();
  const viewSettings = allViewSettings['counterparties'];
  const location = useLocation();
  const { clearHistoryPages, pushPage } = usePageMovementHistory();
  const [filterData, setFiltersData] = useState(
    viewSettings['filterData'] || {}
  );
  const [showFilters, setShowFilters] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    size: viewSettings['size'],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [counterparties, setCountrparties] = useState([]);
  const [selectedModelIds, setSelectedModelIds] = useState([]);
  const [sortData, setSortData] = useState({});
  const [tableAttrs, setTableAttrs] = useState([]);
  const [attrsForFilters, setAttrsForFilters] = useState(new Set());

  useEffect(() => {
    clearHistoryPages();
    pushPage(location.pathname);
    const table_attrs = table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions.Counterparties.read_all.includes(
          attr_info.name
        )
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setTableAttrs(table_attrs);

    const attrs_for_filters = new Set(
      roleAccess[userRole].fieldFilters.Counterparties
    );
    setAttrsForFilters(attrs_for_filters);
  }, []);

  useEffect(() => {
    setAllViewSettings({
      counterparties: {
        size: paginationSettings['size'],
        filterData: filterData,
      },
    });
  }, [paginationSettings, filterData]);

  useEffect(() => {
    if (sortData.by === 'group_translate') {
      sortData.by = 'group';
    }
    const newPaginationSettings = {
      ...paginationSettings,
      sort_by: sortData.by,
      sort_order: sortData.order,
    };
    setPaginationSettings(newPaginationSettings);
  }, [sortData]);

  const mapFilterData = () => {
    const data = { ...filterData };
    if (filterData.responsible)
      data['responsible_id'] = filterData.responsible.id;
    if (!filterData.group || filterData.group === '') {
      delete data['group'];
    }
    if (filterData.name === '') delete data['name'];
    if (filterData.contact_person === '') delete data['contact_person'];
    if (filterData.phone_number === '') delete data['phone_number'];
    if (filterData.link_to_messenger === '') delete data['link_to_messanger'];
    delete data['responsible'];
    return data;
  };

  const requestGetCounterparties = async () => {
    let new_counterparties = [];
    const filter = mapFilterData();
    if (userRole == client_realtions_manager) {
      filter.group = 'client';
    } else if (userRole == supplier_realtions_manager) {
      filter.group = 'supplier';
    }
    const new_counterparties_paginated =
      await counterpartyService.get_by_filters(filter, paginationSettings);
    new_counterparties = new_counterparties_paginated.data;
    for (const counterparty of new_counterparties) {
      counterparty.group_translate = types[counterparty.group][language];
    }
    setTotalPages(new_counterparties_paginated.total);
    setCountrparties(new_counterparties);
    setCurrentPage(paginationSettings['page']);
  };

  useEffect(() => {
    for (const counterparty of counterparties) {
      counterparty.group_translate = types[counterparty.group][language];
    }
    setCountrparties([...counterparties]);
  }, [language]);

  const addFiltersData = (attr, value) => {
    if (value) {
      setFiltersData({ ...filterData, [attr]: value });
    } else if (filterData.hasOwnProperty(attr)) {
      delete filterData[attr];
      setFiltersData({ ...filterData });
    }
  };

  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSetPaginationSettings = (settings) => {
    setPaginationSettings({ ...paginationSettings, ...settings });
  };

  useEffect(() => {
    requestGetCounterparties();
  }, [paginationSettings]);

  const deleteModels = async () => {
    setCountrparties(
      counterparties.filter((model) => !selectedModelIds.includes(model.id))
    );
    for (const id of selectedModelIds) {
      const result = await counterpartyService.delete(
        id,
        setGlobalError,
        language
      );
      if (!result) {
        requestGetCounterparties();
      }
    }
  };

  return (
    <div>
      <ModelsControls
        model_name={interface_translate['Counterparties'][language]}
        filtersActivated={Object.keys(filterData).length > 0}
        toggleShowFilters={toggleShowFilters}
        requestModels={requestGetCounterparties}
        deleteModels={deleteModels}
        section="Counterparties"
      />
      {showFilters && (
        <ModelFilters
          requestModels={requestGetCounterparties}
          resetFilterData={() => setFiltersData({})}
        >
          {attrsForFilters.has('name') && (
            <TextFilter
              title_name={interface_translate['Company'][language]}
              attr="name"
              addFiltersData={addFiltersData}
              defaultValue={
                filterData['name'] != undefined ? filterData['name'] : ''
              }
            />
          )}
          {attrsForFilters.has('group') && (
            <SelectFilter
              title_name={interface_translate['Group'][language]}
              attr="group"
              select_names={['', 'клиент', 'поставщик']}
              select_attr={['', 'client', 'supplier']}
              addFiltersData={addFiltersData}
              defaultValue={
                filterData['group'] != undefined ? filterData['group'] : ''
              }
            />
          )}
          {attrsForFilters.has('phone_number') && (
            <TextFilter
              title_name={interface_translate['Phone number'][language]}
              attr="phone_number"
              addFiltersData={addFiltersData}
              defaultValue={
                filterData['phone_number'] != undefined
                  ? filterData['phone_number']
                  : ''
              }
            />
          )}
          {attrsForFilters.has('contact_person') && (
            <TextFilter
              title_name={interface_translate['Contact person'][language]}
              attr="contact_person"
              addFiltersData={addFiltersData}
              defaultValue={
                filterData['contact_person'] != undefined
                  ? filterData['contact_person']
                  : ''
              }
            />
          )}
          {attrsForFilters.has('link_to_messanger') && (
            <TextFilter
              title_name={interface_translate['Link to messenger'][language]}
              attr="link_to_messenger"
              addFiltersData={addFiltersData}
              defaultValue={
                filterData['link_to_messenger'] != undefined
                  ? filterData['link_to_messenger']
                  : ''
              }
            />
          )}
          {attrsForFilters.has('responsible') && (
            <AutocompleteFilter
              title_name={interface_translate['Responsible'][language]}
              attr="responsible"
              addFiltersData={addFiltersData}
              defaultValue={
                filterData['responsible'] != undefined
                  ? filterData['responsible']
                  : null
              }
              getElementLabel={(option) => option.email}
              searchElements={async (value) => {
                let filters = {};
                if (value) filters['email'] = value;
                const responsible = await get_users(filters);
                return responsible;
              }}
            />
          )}
        </ModelFilters>
      )}
      <ModelView
        tableAttrs={tableAttrs}
        models={counterparties}
        setSelectedModels={setSelectedModelIds}
        sortData={sortData}
        setSortData={setSortData}
        setPaginationSettings={handleSetPaginationSettings}
        currentPage={currentPage}
        totalPages={totalPages}
        currentSize={paginationSettings['size']}
      />
    </div>
  );
};

export default CounterpartiesPage;
