import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useCommon from '../hooks/useCommon';
import { interface_translate } from '../texts/interface';
import styles from './ListAttrAutocomplete.module.css';

const ListAttrAutocomplete = (props) => {
  const { language } = useCommon();
  const {
    defElement = null,
    defElements = [],
    selectedElements,
    setSelectedElement,
    deleteSelectedElement,
    defaultElements = [],
    getElementLabel,
    renderInput,
    searchElementsDelay = 500,
    searchElements,
  } = props;

  const [blocksId, setBlocksId] = useState([]);
  const [blocksElements, setBlocksElements] = useState({});
  const [blocksInputValue, setBlocksInputValue] = useState({});
  const [blockIdInFocus, setBlockIdInFocus] = useState(null);
  const [isLoadData, setIsLoadData] = useState(false);
  const [idsDefaultElements, setIdsDefaultElements] = useState([]);

  useEffect(() => {
    if (!blockIdInFocus) return;
    const delayDebounceFn = setTimeout(async () => {
      const new_elements = await searchElements(
        blocksInputValue[blockIdInFocus]
      );
      setIsLoadData(false);
      setBlocksElements((prevElements) => ({
        ...prevElements,
        [blockIdInFocus]: [...new_elements],
      }));
    }, searchElementsDelay);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [blockIdInFocus, blocksInputValue]);

  const removeElementByIndex = (id) => {
    deleteSelectedElement(id);
    setBlocksId((prevData) => {
      let index = prevData.indexOf(id);
      if (index !== -1) {
        prevData.splice(index, 1);
      }
      return [...prevData];
    });
    setBlocksElements((prevData) => {
      delete prevData[id];
      return { ...prevData };
    });
  };

  const addElement = (def_id, def_element) => {
    console.log(1111)
    let element = defElement;
    if (def_element) element = def_element;
    let id = uuidv4();
    if (def_id) id = def_id;
    setSelectedElement({ [id]: element });
    setBlocksId((prevData) => {
      const new_data = [...prevData, id];
      return new_data;
    });
    setBlocksElements((prevData) => {
      const new_data = { ...prevData, [id]: defElements };
      return new_data;
    });
  };

  useEffect(() => {
    if (!defaultElements) return;
    for (const id of idsDefaultElements) {
      deleteSelectedElement(id);
      setBlocksId((prevData) => {
        const newPrevData = prevData.filter((m) => m.id !== id);
        return newPrevData;
      });
    }
    const newIdsDefaultElements = [];
    for (const element of defaultElements) {
      const id = uuidv4();
      newIdsDefaultElements.push(id);
      addElement(id, element);
    }
    setIdsDefaultElements(newIdsDefaultElements);
  }, [defaultElements]);
  console.log(blocksId)
  console.log(selectedElements);
  return (
    <div className={styles.listAttr}>
      {blocksId.map((id) => {
        // if (!selectedElements[id]) return;
        return (
          <div
            className={styles.attr}
            id={id}
            key={id}
          >
            <Autocomplete
              key={id}
              value={selectedElements[id]}
              onChange={(event, newElement) => {
                setSelectedElement({ [id]: newElement });
              }}
              disabled={idsDefaultElements.includes(id)}
              options={blocksElements[id] ? blocksElements[id] : []}
              getOptionLabel={getElementLabel}
              onInputChange={(e, value) => {
                setBlocksInputValue((prevInputValue) => ({
                  ...prevInputValue,
                  [id]: value,
                }));
                setBlockIdInFocus(id);
                setBlocksElements((prevData) => {
                  const new_data = { ...prevData, [id]: [] };
                  return new_data;
                });
                setIsLoadData(true);
              }}
              renderInput={renderInput}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              loading={isLoadData}
            />
            <button
              onClick={() => {
                removeElementByIndex(id);
              }}
            >
              -
            </button>
          </div>
        );
      })}
      <button
        className={styles.addBtn}
        onClick={() => addElement()}
      >
        {interface_translate['Add'][language]}
      </button>
    </div>
  );
};

export default ListAttrAutocomplete;
