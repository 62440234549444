import { useState, React, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { api_error_translate } from '../../texts/error';
import { interface_translate } from '../../texts/interface';
import useCommon from '../../hooks/useCommon';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import roleAccess from '../../roleAccess';
import shelvingService from '../../services/shelving';
import storehouseService from '../../services/storehouse';
import styles from './ShelvingEditPage.module.css';
import '../shared.css';

async function get_shelving(id) {
  const shelving = await shelvingService.get(id);
  return shelving;
}

async function update_shelving(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg
) {
  const result = await shelvingService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg
  );
  return result;
}

async function get_storehouses(filters = {}, size = 50) {
  const storehouse = await storehouseService.get_by_filters(filters, {
    size: size,
  });
  return storehouse;
}

const ShelvingEditPage = (props) => {
  const { addToPageMovementHistory } = props;
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState();
  const [oldShelving, setOldShelving] = useState({});
  const [attrsForEdit, setAttrsForEdit] = useState(new Set());
  const [storehouseInputValue, setStorehouseInputValue] = useState(null);
  const [storehouseElementsForSelect, setStorehouseElementsForSelect] =
    useState([]);

  useEffect(() => {
    const req = async () => {
      const shelving_resp = await get_shelving(params.id);
      setModelData({ ...shelving_resp });
      setModelData(shelving_resp);
    };
    req();
    pushPage(location.pathname);
    const attrsForEdit = new Set(
      roleAccess[userRole].fieldRestrictions.Shelvings?.edit
    );
    setAttrsForEdit(attrsForEdit);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (storehouseInputValue) filters['name'] = storehouseInputValue;
      const new_elements = await get_storehouses(filters);
      setStorehouseElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storehouseInputValue]);

  const getDataFromAttr = () => {
    const data = {
      ...modelData,
      name: modelData.name,
      storehouse_id: modelData.storehouse.id,
    };
    return data;
  };

  if (!modelData) return;

  return (
    <div className="pageUpdateModel">
      <div className={styles.controls}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await update_shelving(
              params.id,
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg
            );
            if (result) {
              setGlobalMsg(interface_translate['Shelving updated'][language]);
              resetGlobalError();
              setErrors({});
              popPage();
              const previousPage = getCurrentPage();
              navigate(previousPage ? previousPage : '..', {
                relative: 'path',
              });
            }
          }}
        >
          {interface_translate['Update'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
      </div>
      {
        <>
          <div className="inputData">
            <OpeningBlock
              title={interface_translate['About shelving'][language]}
              open={true}
            >
              {attrsForEdit.has('name') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Name'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.name}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['name'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['name'][0]]['ru']}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('storehouse_name') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Storehouse'][language]}
                    </div>
                    <Autocomplete
                      value={modelData.storehouse}
                      onChange={(e, newElement) => {
                        setModelData({
                          ...modelData,
                          storehouse: newElement,
                        });
                      }}
                      options={storehouseElementsForSelect}
                      getOptionLabel={(model) => model.name}
                      onInputChange={(e, value) => {
                        setStorehouseInputValue(value);
                        setStorehouseElementsForSelect([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={interface_translate['Storehouse'][language]}
                          size="small"
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      loading={storehouseElementsForSelect.length == 0}
                    />
                  </div>
                  {errors['storehouse_id'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['storehouse_id'][0]]['ru']}
                    </div>
                  )}
                </div>
              )}
            </OpeningBlock>
          </div>
        </>
      }
      {!modelData && <div>Пусто</div>}
    </div>
  );
};
export default ShelvingEditPage;
