import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import OpeningBlock from '../../components/OpeningBlock';
import roleAccess from '../../roleAccess';
import storageCellService from '../../services/storage_cell';
import styles from './StorageCellPage.module.css';
import { interface_translate } from '../../texts/interface';
import '../shared.css';

async function get_storage_cell(id) {
  const storage_cell = await storageCellService.get(id);
  return storage_cell;
}

const StorageCellPage = (props) => {
  const { language, userRole } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [storage_cell, setStorageCell] = useState();
  const [attrsForRead, setAttrsForRead] = useState(new Set());

  useEffect(() => {
    const req = async () => {
      const storage_cell_resp = await get_storage_cell(params.id);
      storage_cell_resp.free_name = storage_cell_resp.free
        ? interface_translate['free'][language]
        : interface_translate['occupied'][language];
      setStorageCell(storage_cell_resp);
    };
    req();
    pushPage(location.pathname);
    const attrs_for_read = new Set(
      roleAccess[userRole].fieldRestrictions['Storage cells']?.read
    );
    setAttrsForRead(attrs_for_read);
  }, []);

  return (
    <div className="pageViewModel">
      <div className="controls">
        {roleAccess[userRole].permissions['Storage cells'].edit && (
          <button
            onClick={() => {
              navigate('edit');
            }}
          >
            {interface_translate['Edit'][language]}
          </button>
        )}
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
      </div>
      {storage_cell && (
        <div className="viewData">
          <OpeningBlock
            title={interface_translate['About storage cell'][language]}
            open={true}
          >
            {attrsForRead.has('id') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['storage cell id'][language]}
                  </div>
                  <span>{storage_cell.id}</span>
                </div>
              </div>
            )}
            {attrsForRead.has('number') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Number'][language]}
                  </div>
                  <span>{storage_cell.number}</span>
                </div>
              </div>
            )}
            {attrsForRead.has('shelving_name') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Shelving'][language]}
                  </div>
                  <span
                    className="linkToModel"
                    onClick={() =>
                      navigate(`/shelvings/${storage_cell.shelving.id}`, {
                        state: { from: location },
                      })
                    }
                  >
                    {storage_cell.shelving.name}
                  </span>
                </div>
              </div>
            )}
            {attrsForRead.has('free_name') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Free'][language]}
                  </div>
                  <span>{storage_cell.free_name}</span>
                </div>
              </div>
            )}
          </OpeningBlock>
        </div>
      )}
      {!storage_cell && <div>Пусто</div>}
    </div>
  );
};

export default StorageCellPage;
