import { useState, useEffect } from 'react';
import usePageMovementHistory from '../../hooks/usePageMovementHistory.jsx';
import useCommon from '../../hooks/useCommon.jsx';
import ModelView from '../../components/models_view/ModelView.jsx';
import ModelsControls from '../../components/models_view/ModelsControls.jsx';
import ModelFilters from '../../components/ModelFilters.jsx';
import SelectFilter from '../../components/filters/SelectFilter.jsx';
import TextFilter from '../../components/filters/TextFilter.jsx';
import AutocompleteFilter from '../../components/filters/AutocompleteFilter.jsx';
import apiRequester from '../../ApiRequester.js';
import roleAccess from '../../roleAccess.js';
import unidentifiedDeliveryService from '../../services/unidentified_delivery.js';
import userService from '../../services/user.js';
import useViewSettings from '../../hooks/useViewSettings.jsx';
import { statuses_translate } from '../../texts/statuses.js';
import { api_error_translate } from '../../texts/error.js';
import { interface_translate } from '../../texts/interface.js';
import styles from './UnidentifiedDeliveriesPage.module.css';
import '../../App.css';
import { useLocation } from 'react-router-dom';

async function delete_unidentified_delivery(id) {
  const result = await unidentifiedDeliveryService.delete(id);
  return result;
}

async function get_users(filters = {}, size = 50) {
  const usersPaginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return usersPaginated.data;
}

async function get_unidentified_delivery_statuses() {
  const statuses = await unidentifiedDeliveryService.get_statuses();
  return statuses;
}

const table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'unidentified delivery id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'track_number',
    name_for_display: 'track number',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'status_translate',
    name_for_display: 'status',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'total_weight_in_kg',
    name_for_display: 'total weight in kg',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'total_volume_in_m3',
    name_for_display: 'total volume in м³',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'actual_content',
    name_for_display: 'actual content',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const UnidentifiedDeliveriesPage = (props) => {
  const { language, userRole } = useCommon();
  const location = useLocation();
  const { clearHistoryPages, pushPage } = usePageMovementHistory();
  const { allViewSettings, setAllViewSettings } = useViewSettings();
  const viewSettings = allViewSettings['unidentified_deliveries'];
  const [filterData, setFiltersData] = useState(
    viewSettings['filterData'] || {}
  );
  const [showFilters, setShowFilters] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    size: viewSettings['size'],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [unidentified_deliveries, setUnidentifiedDeliverys] = useState([]);
  const [unidentifiedDeliveryStatuses, setUnidentifiedDeliveryStatuses] =
    useState([]);
  const [selectedModelIds, setSelectedModelIds] = useState([]);
  const [sortData, setSortData] = useState({});
  const [tableAttrs, setTableAttrs] = useState([]);

  useEffect(() => {
    const req = async () => {
      const unidentifiedDeliveryStatuses =
        await get_unidentified_delivery_statuses();
      setUnidentifiedDeliveryStatuses(unidentifiedDeliveryStatuses);
    };
    req();
    clearHistoryPages();
    pushPage(location.pathname);
    const table_attrs = table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions[
          'Unidentified deliveries'
        ].read_all.includes(attr_info.name)
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setTableAttrs(table_attrs);
  }, []);

  useEffect(() => {
    for (let delivery of unidentified_deliveries) {
      delivery.status_translate = statuses_translate[delivery.status][language];
    }
    setUnidentifiedDeliverys([...unidentified_deliveries]);
  }, [language]);

  useEffect(() => {
    setAllViewSettings({
      unidentified_delivery: {
        size: paginationSettings['size'],
        filterData: filterData,
      },
    });
  }, [paginationSettings, filterData]);

  useEffect(() => {
    if (sortData.by === 'status_translate') {
      sortData.by = 'status';
    }
    const newPaginationSettings = {
      ...paginationSettings,
      sort_by: sortData.by,
      sort_order: sortData.order,
    };
    setPaginationSettings(newPaginationSettings);
  }, [sortData]);

  const mapFilterData = () => {
    const data = { ...filterData };
    if (filterData.responsible)
      data['responsible_id'] = filterData.responsible.id;
    if (filterData.co_executors)
      data['co_executor_id'] = filterData.co_executors.id;
    if (!filterData.status || filterData.status === '') {
      delete data['status'];
    }
    if (filterData.track_number === '') delete data['track_number'];
    if (filterData.total_weight_in_kg === '') delete data['total_weight_in_kg'];
    if (filterData.total_volume_in_m3 === '') delete data['total_volume_in_m3'];
    if (filterData.actual_content === '') delete data['actual_content'];
    delete data['responsible'];
    delete data['co_executors'];
    return data;
  };

  const requestGetUnidentifiedDeliverys = async () => {
    const filter = mapFilterData();
    const new_unidentified_delivery_paginated =
      await unidentifiedDeliveryService.get_by_filters(
        filter,
        paginationSettings
      );
    const new_unidentified_delivery = new_unidentified_delivery_paginated.data;
    for (let delivery of new_unidentified_delivery) {
      delivery.status_translate = statuses_translate[delivery.status][language];
    }
    setTotalPages(new_unidentified_delivery_paginated.total);
    setUnidentifiedDeliverys(new_unidentified_delivery);
    setCurrentPage(paginationSettings['page']);
  };

  const addFiltersData = (attr, value) => {
    if (value) {
      setFiltersData({ ...filterData, [attr]: value });
    } else if (filterData.hasOwnProperty(attr)) {
      delete filterData[attr];
      setFiltersData({ ...filterData });
    }
  };

  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSetPaginationSettings = (settings) => {
    setPaginationSettings({ ...paginationSettings, ...settings });
  };

  useEffect(() => {
    requestGetUnidentifiedDeliverys();
  }, [paginationSettings]);

  const deleteModels = async () => {
    setUnidentifiedDeliverys(
      unidentified_deliveries.filter(
        (model) => !selectedModelIds.includes(model.id)
      )
    );
    for (const id of selectedModelIds) await delete_unidentified_delivery(id);
  };

  return (
    <div>
      <ModelsControls
        section="Unidentified deliveries"
        model_name={interface_translate['Unidentified deliveries'][language]}
        filtersActivated={Object.keys(filterData).length > 0}
        toggleShowFilters={toggleShowFilters}
        requestModels={requestGetUnidentifiedDeliverys}
        deleteModels={deleteModels}
      />
      {showFilters && (
        <ModelFilters
          requestModels={requestGetUnidentifiedDeliverys}
          resetFilterData={() => setFiltersData({})}
        >
          <TextFilter
            title_name={interface_translate['Track number'][language]}
            attr="track_number"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['track_number'] != undefined
                ? filterData['track_number']
                : ''
            }
          />
          <SelectFilter
            title_name={interface_translate['Status'][language]}
            attr="status"
            select_names={[
              '',
              ...unidentifiedDeliveryStatuses.map(
                (m) => statuses_translate[m][language]
              ),
            ]}
            select_attr={['', ...unidentifiedDeliveryStatuses]}
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['status'] != undefined ? filterData['status'] : ''
            }
          />
          <TextFilter
            title_name={interface_translate['Total weight in kg'][language]}
            attr="total_weight_in_kg"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['total_weight_in_kg'] != undefined
                ? filterData['total_weight_in_kg']
                : ''
            }
          />
          <TextFilter
            title_name={interface_translate['Total volume in м³'][language]}
            attr="total_volume_in_m3"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['total_volume_in_m3'] != undefined
                ? filterData['total_volume_in_m3']
                : ''
            }
          />
          <TextFilter
            title_name={interface_translate['Actual content'][language]}
            attr="actual_content"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['actual_content'] != undefined
                ? filterData['actual_content']
                : ''
            }
          />
          <AutocompleteFilter
            title_name={interface_translate['Responsible'][language]}
            attr="responsible"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['responsible'] != undefined
                ? filterData['responsible']
                : null
            }
            getElementLabel={(option) => option.email}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['email'] = value;
              const responsible = await get_users(filters);
              return responsible;
            }}
          />
          <AutocompleteFilter
            title_name={interface_translate['Co executors'][language]}
            attr="co_executors"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['co_executors'] != undefined
                ? filterData['co_executors']
                : null
            }
            getElementLabel={(option) => option.email}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['email'] = value;
              const coExecutors = await get_users(filters);
              return coExecutors;
            }}
          />
        </ModelFilters>
      )}
      <ModelView
        tableAttrs={tableAttrs}
        models={unidentified_deliveries}
        setSelectedModels={setSelectedModelIds}
        sortData={sortData}
        setSortData={setSortData}
        setPaginationSettings={handleSetPaginationSettings}
        currentPage={currentPage}
        totalPages={totalPages}
        currentSize={paginationSettings['size']}
      />
    </div>
  );
};

export default UnidentifiedDeliveriesPage;
