import { useState, React, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { api_error_translate } from '../../texts/error';
import { statuses_translate } from '../../texts/statuses.js';
import { interface_translate } from '../../texts/interface';
import { types } from '../../texts/types.js';
import TextField from '@mui/material/TextField';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import ListAttrMedia from '../../components/ListAttrMedia';
import DropDownMediaBlock from '../../components/DropDownMediaBlock';
import ConfirmDialog from '../../components/ConfirmDialog';
import roleAccess from '../../roleAccess';
import productService from '../../services/product.js';
import userService from '../../services/user.js';
import customerCargoService from '../../services/customer_cargo.js';
import counterpartyService from '../../services/counterparty.js';
import shipmentService from '../../services/shipment.js';
import deliveryService from '../../services/delivery.js';
import storehouseService from '../../services/storehouse.js';
import storageCellService from '../../services/storage_cell.js';
import sharedService from '../../services/shared';
import styles from './ProductEditPage.module.css';
import '../shared.css';
import ResponsibleListInput from '../../components/input/ResponsibleListInput.jsx';
import CoExecutorsInput from '../../components/input/CoExecutorsInput.jsx';

async function update_product(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await productService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_product(id) {
  const product = await productService.get(id);
  return product;
}

async function get_product_statuses() {
  const statuses = await productService.get_statuses();
  return statuses;
}

async function get_users(filters = {}, size = 50) {
  const users_paginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return users_paginated.data;
}

async function get_user(id) {
  const user = await userService.get(id);
  return user;
}

async function get_suppliers(filters = {}, size = 50) {
  filters = { group: 'supplier', ...filters };
  const suppliers_paginated = await counterpartyService.get_by_filters(
    filters,
    { size: size }
  );
  return suppliers_paginated.data;
}

async function get_shipments(filters = {}, size = 50) {
  try {
    const shipment_paginated = await shipmentService.get_by_filters(filters, {
      size: size,
    });
    return shipment_paginated.data;
  } catch (err) {
    console.error(`Could not fetch data: ${err}`);
  }
}

async function get_customer_cargo_list(filters = {}, size = 50) {
  const customerCargoPaginated = await customerCargoService.get_by_filters(
    filters,
    {
      size: size,
    }
  );
  return customerCargoPaginated.data;
}

async function get_storehouses(filters = {}, size = 50) {
  const storehousePaginated = await storehouseService.get_by_filters(filters, {
    size: size,
  });
  return storehousePaginated.data;
}

async function get_storage_cells(filters = {}, size = 50) {
  const storageCellPaginated = await storageCellService.get_by_filters(
    filters,
    { size: size }
  );
  return storageCellPaginated.data;
}

async function get_unit_names() {
  const unit_names = await sharedService.get_unit_names();
  return unit_names;
}

const ProductEditPage = (props) => {
  const { handleExit } = props;
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState({});
  const [oldProduct, setOldProduct] = useState();
  const [defUsers, setDefUsers] = useState(null);
  const [productStatuses, setProductStatuses] = useState([]);
  const [unitNames, setUnitNames] = useState([]);
  const [attrsForEdit, setAttrsForEdit] = useState(new Set());
  const [supplierInputValue, setSupplierInputValue] = useState(null);
  const [supplierElementsForSelect, setSupplierElementsForSelect] = useState(
    []
  );
  const [deliveryInputValue, setDeliveryInputValue] = useState('');
  const [deliveryIdElementsForSelect, setDeliveryIdElementsForSelect] = useState(
    []
  );
  const [shipmentInputValue, setShipmentInputValue] = useState('');
  const [shipmentIdElementsForSelect, setShipmentIdElementsForSelect] =
    useState([]);
  const [customerCargoInputValue, setCustomerCargoInputValue] = useState('');
  const [
    customerCargoIdElementsForSelect,
    setCustomerCargoIdElementsForSelect,
  ] = useState([]);
  const [storehouse, setStorehouse] = useState(null);
  const [storehouseInputValue, setStorehouseInputValue] = useState('');
  const [storehouseElementsForSelect, setStorehouseElementsForSelect] =
    useState([]);
  const [storageCellInputValue, setStorageCellInputValue] = useState('');
  const [storageCellElementsForSelect, setStorageCellElementsForSelect] =
    useState([]);
  const [responsible, setResponsible] = useState({});
  const [coExecutors, setCoExecutors] = useState({});
  const [imagesForCreate, setImagesForCreate] = useState({});
  const [viewDeleteImageConfirmDialog, setViewDeleteImageConfirmDialog] =
    useState(false);
  const [selectedImageIdToDelete, setSelectedImageIdToDelete] = useState(null);
  const [imagesIdForDelete, setImagesIdForDelete] = useState([]);

  useEffect(() => {
    const req = async () => {
      const product_resp = await get_product(params.id);
      const responsible = [];
      for (const user_id of product_resp.responsible_ids) {
        const user = await get_user(user_id);
        responsible.push(user);
      }
      product_resp.responsible = responsible;
      const co_executors = [];
      for (const user_id of product_resp.co_executors_ids) {
        const user = await get_user(user_id);
        co_executors.push(user);
      }
      product_resp.coExecutors = co_executors;
      if (!product_resp.link) product_resp.link = '';
      if (product_resp.storage_cell) {
        setStorehouse(product_resp.storage_cell.shelving.storehouse);
      }

      const productStatuses = await get_product_statuses();
      setProductStatuses(productStatuses);
      setOldProduct({ ...product_resp });
      setModelData(product_resp);

      const users = await get_users();
      setDefUsers(users);

      const unitNames = await get_unit_names();
      setUnitNames(unitNames);
    };
    req();
    pushPage(location.pathname);
    const attrsForEdit = new Set(
      roleAccess[userRole].fieldRestrictions.Products?.edit
    );
    setAttrsForEdit(attrsForEdit);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (supplierInputValue) filters['name'] = supplierInputValue;
      const new_elements = await get_suppliers(filters);
      setSupplierElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [supplierInputValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (deliveryInputValue) filters['id'] = deliveryInputValue;
      const new_elements_paginated = await deliveryService.get_by_filters(
        filters
      );
      setDeliveryIdElementsForSelect(
        new_elements_paginated.data.map((m) => m.id)
      );
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [deliveryInputValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (shipmentInputValue) filters['id'] = shipmentInputValue;
      const new_elements = await get_shipments(filters);
      setShipmentIdElementsForSelect(new_elements.map((m) => m.id));
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [shipmentInputValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (customerCargoInputValue) filters['id'] = customerCargoInputValue;
      const new_elements = await get_customer_cargo_list(filters);
      setCustomerCargoIdElementsForSelect(new_elements.map((m) => m.id));
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [customerCargoInputValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (storehouseInputValue) filters['name'] = storehouseInputValue;
      const new_elements = await get_storehouses(filters);
      setStorehouseElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storehouseInputValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (!storehouse) return;
      let filters = { storehouse_id: storehouse.id };
      if (storageCellInputValue)
        filters['storage_cell_name'] = storageCellInputValue;
      const new_elements = await get_storage_cells(filters);
      setStorageCellElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storageCellInputValue]);

  const getDataFromAttr = () => {
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    for (let id of responsible_ids) {
      if (id === null) {
        setErrors({
          responsible_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const co_executor_ids = Object.values(coExecutors).map((m) =>
      m ? m.id : null
    );
    for (let id of co_executor_ids) {
      if (id === null) {
        setErrors({
          co_executors_ids: ['There are blank fields left'],
        });
        return;
      }
    }

    const preData = {
      ...modelData,
      supplier_id: modelData.supplier ? modelData.supplier.id : null,
      images_for_create: Object.values(imagesForCreate),
      images_id_for_delete: imagesIdForDelete,
      responsible_ids: responsible_ids,
      co_executors_ids: co_executor_ids,
    };
    if (!modelData.currency || modelData.currency === '') {
      delete preData['currency'];
    }
    if (modelData.storage_cell !== oldProduct.storage_cell) {
      preData.storage_cell_id = modelData.storage_cell
        ? modelData.storage_cell.id
        : null;
    }
    delete preData['supplier'];
    delete preData['images'];
    delete preData['storage_cell'];
    delete preData['responsible'];
    delete preData['coExecutors'];

    const modelAttrForEdit = new Set();
    for (const attr of attrsForEdit) {
      if (attr === 'responsible') {
        modelAttrForEdit.add('responsible_ids');
      } else if (attr === 'co_executors') {
        modelAttrForEdit.add('co_executors_ids');
      } else if (attr === "qty") {
        modelAttrForEdit.add('qty');
        modelAttrForEdit.add('measurement_unit');
      } else {
        modelAttrForEdit.add(attr);
      }
    }
    const filteredPreData = Object.keys(preData)
      .filter((key) => modelAttrForEdit.has(key))
      .reduce((acc, key) => {
        acc[key] = preData[key];
        return acc;
      }, {});
    return filteredPreData;
  };

  return (
    <div className="pageUpdateModel">
      {viewDeleteImageConfirmDialog && (
        <ConfirmDialog
          message={interface_translate['Delete?'][language]}
          onConfirm={() => {
            setViewDeleteImageConfirmDialog(false);
            setImagesIdForDelete((prevData) => {
              const new_data = [...prevData, selectedImageIdToDelete];
              return new_data;
            });
            setSelectedImageIdToDelete(null);
            setModelData({
              ...modelData,
              images: modelData.images.filter(
                (model) => model.id !== selectedImageIdToDelete
              ),
            });
          }}
          onCancel={() => setViewDeleteImageConfirmDialog(false)}
        />
      )}
      <div className={styles.controls}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await update_product(
              params.id,
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(interface_translate['Product updated'][language]);
              resetGlobalError();
              setErrors({});
              if (handleExit) {
                handleExit();
                return;
              }
              popPage();
              const previousPage = getCurrentPage();
              navigate(previousPage ? previousPage : '..', {
                relative: 'path',
              });
            }
          }}
        >
          {interface_translate['Update'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
      </div>
      {modelData.hasOwnProperty('name') && (
        <>
          <div className="inputData">
            {attrsForEdit.has('name') && (
              <div>
                <div className="inputName">
                  <div className="openingBlockAttrName">
                    {interface_translate['Name'][language]}
                  </div>
                  <input
                    type="text"
                    name="name"
                    value={modelData.name}
                    onChange={(e) => {
                      setModelData({ ...modelData, name: e.target.value });
                    }}
                  />
                  {errors['name'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['name'][0]][language]}
                    </div>
                  )}
                </div>
              </div>
            )}
            <OpeningBlock
              title={interface_translate['About product'][language]}
              open={true}
            >
              {attrsForEdit.has('description') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Description'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.description}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          description: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['description'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['description'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('status') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Status'][language]}
                    </div>
                    <select
                      value={modelData.status}
                      onChange={(e) => {
                        setModelData({ ...modelData, status: e.target.value });
                      }}
                    >
                      {productStatuses.map((status, id) => (
                        <option
                          key={id}
                          value={status}
                        >
                          {statuses_translate[status][language]}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors['status'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['status'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('link') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Link'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.link}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          link: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['link'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['link'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('qty') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Qty'][language]}
                    </div>
                    <div className={`openingBlockAttrAttrInput horizontal`}>
                      <input
                        type="text"
                        value={modelData.qty}
                        onChange={(e) => {
                          setModelData({
                            ...modelData,
                            qty: e.target.value,
                          });
                        }}
                      />
                      <select
                        value={modelData.measurement_unit}
                        onChange={(e) => {
                          setModelData({
                            ...modelData,
                            measurement_unit: e.target.value,
                          });
                        }}
                      >
                        {unitNames.map((measurement_unit, id) => (
                          <option
                            key={id}
                            value={measurement_unit}
                          >
                            {types[measurement_unit][language]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {errors['qty'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['qty'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('price') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Price'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.price}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          price: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['price'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['price'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('currency') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Currency'][language]}
                    </div>
                    <select
                      value={modelData.currency}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          currency: e.target.value,
                        });
                      }}
                    >
                      <option value=""></option>
                      <option value="rub">RUB</option>
                      <option value="usd">USD</option>
                      <option value="cny">CNY</option>
                    </select>
                  </div>
                  {errors['currency'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['currency'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('supplier_id') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Supplier'][language]}
                    </div>
                    <Autocomplete
                      value={modelData.supplier}
                      onChange={(e, newElement) => {
                        setModelData({
                          ...modelData,
                          supplier: newElement,
                        });
                      }}
                      options={supplierElementsForSelect}
                      getOptionLabel={(model) => model.name}
                      onInputChange={(e, value) => {
                        setSupplierInputValue(value);
                        setSupplierElementsForSelect([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={interface_translate['Supplier'][language]}
                          size="small"
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      loading={supplierElementsForSelect.length == 0}
                    />
                  </div>
                  {errors['supplier_id'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['supplier_id'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('delivery_id') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Delivery'][language]}
                    </div>
                    <Autocomplete
                      value={modelData.delivery_id}
                      onChange={(e, newElement) => {
                        setModelData({
                          ...modelData,
                          delivery_id: newElement,
                        });
                      }}
                      options={deliveryIdElementsForSelect}
                      getOptionLabel={(model) => model.toString()}
                      onInputChange={(e, value) => {
                        setDeliveryInputValue(value);
                        setDeliveryIdElementsForSelect([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={interface_translate['Delivery'][language]}
                          size="small"
                        />
                      )}
                      loading={deliveryIdElementsForSelect.length == 0}
                    />
                  </div>
                  {errors['delivery_id'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['delivery_id'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('shipment_id') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Shipment'][language]}
                    </div>
                    <Autocomplete
                      value={modelData.shipment_id}
                      onChange={(e, newElement) => {
                        setModelData({
                          ...modelData,
                          shipment_id: newElement,
                        });
                      }}
                      options={shipmentIdElementsForSelect}
                      getOptionLabel={(model) => model.toString()}
                      onInputChange={(e, value) => {
                        setShipmentInputValue(value);
                        setShipmentIdElementsForSelect([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={interface_translate['Shipment'][language]}
                          size="small"
                        />
                      )}
                      loading={shipmentIdElementsForSelect.length == 0}
                    />
                  </div>
                  {errors['shipment_id'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['shipment_id'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('customer_cargo_id') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Customer cargo'][language]}
                    </div>
                    <Autocomplete
                      value={modelData.customer_cargo_id}
                      onChange={(e, newElement) => {
                        setModelData({
                          ...modelData,
                          customer_cargo_id: newElement,
                        });
                      }}
                      options={customerCargoIdElementsForSelect}
                      getOptionLabel={(model) => model.toString()}
                      onInputChange={(e, value) => {
                        setCustomerCargoInputValue(value);
                        setCustomerCargoIdElementsForSelect([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={
                            interface_translate['Customer cargo'][language]
                          }
                          size="small"
                        />
                      )}
                      loading={shipmentIdElementsForSelect.length == 0}
                    />
                  </div>
                  {errors['customer_cargo_id'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['customer_cargo_id'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('storehouse_name') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Storehouse'][language]}
                    </div>
                    <Autocomplete
                      value={storehouse}
                      onChange={async (e, newElement) => {
                        setStorehouse(newElement);
                        if (!newElement) {
                          setModelData({ ...modelData, storage_cell: null });
                          return;
                        }
                        const filters = { storehouse_id: newElement.id };
                        const new_storage_cells = await get_storage_cells(
                          filters
                        );
                        setStorageCellElementsForSelect(new_storage_cells);
                      }}
                      options={storehouseElementsForSelect}
                      getOptionLabel={(model) => model.name}
                      onInputChange={(e, value) => {
                        setStorehouseInputValue(value);
                        setStorehouseElementsForSelect([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={interface_translate['Storehouse'][language]}
                          size="small"
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      loading={storageCellElementsForSelect.length == 0}
                    />
                  </div>
                  {errors['storehouse_id'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['storehouse_id'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('storage_cell_name') && (
                <div>
                  {storehouse && (
                    <div className="openingBlockAttr">
                      <div className="openingBlockAttrName">
                        {interface_translate['Storage cell'][language]}
                      </div>
                      <Autocomplete
                        value={modelData.storage_cell}
                        disabled={!storehouse}
                        onChange={(e, newElement) => {
                          setModelData({
                            ...modelData,
                            storage_cell: newElement,
                          });
                        }}
                        options={storageCellElementsForSelect}
                        getOptionLabel={(model) =>
                          `${model.shelving.name}${model.number}`
                        }
                        onInputChange={(e, value) => {
                          setStorageCellInputValue(value);
                          setStorageCellElementsForSelect([]);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={
                              interface_translate['Storage cell'][language]
                            }
                            size="small"
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        loading={storageCellElementsForSelect.length == 0}
                      />
                    </div>
                  )}
                  {errors['storage_cell_id'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['storage_cell_id'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('responsible') && (
                <div>
                  <ResponsibleListInput
                    containerClass={'openingBlockAttr'}
                    attrNameClass={'openingBlockAttrName'}
                    responsible={responsible}
                    setSelectedElement={(value) => {
                      setResponsible((prevData) => {
                        return { ...prevData, ...value };
                      });
                    }}
                    deleteSelectedElement={(id) => {
                      setResponsible((prevElements) => {
                        delete prevElements[id];
                        return { ...prevElements };
                      });
                    }}
                    initialElements={modelData.responsible}
                    defaultElements={defUsers}
                  />
                  {errors['responsible_ids'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['responsible_ids'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('co_executors') && (
                <div>
                  <CoExecutorsInput
                    containerClass={'openingBlockAttr'}
                    attrNameClass={'openingBlockAttrName'}
                    coExecutors={coExecutors}
                    setSelectedElement={(value) => {
                      setCoExecutors((prevData) => {
                        return { ...prevData, ...value };
                      });
                    }}
                    deleteSelectedElement={(id) => {
                      setCoExecutors((prevElements) => {
                        delete prevElements[id];
                        return { ...prevElements };
                      });
                    }}
                    initialElements={modelData.coExecutors}
                    defaultElements={defUsers}
                  />
                  {errors['co_executors_ids'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['co_executors_ids'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('images') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Media'][language]}
                      <p>{`${interface_translate['Allowed formats'][language]}: "jpg", "png", "webp"`}</p>
                      <p>{`"heic", "mp4", "mov"`}</p>
                    </div>
                    <div>
                      <div className="listDropDomnMedia">
                        {modelData.images.map((model, id) => {
                          let mediaBlock;
                          let preViewImg;
                          if (model.file_type === 'photo') {
                            mediaBlock = (
                              <img
                                src={model.url}
                                alt="image"
                              />
                            );
                            preViewImg = mediaBlock;
                          } else if (model.file_type === 'video') {
                            mediaBlock = (
                              <video
                                src={model.url}
                                alt="video"
                                controls
                                style={{ maxWidth: '100%' }}
                              />
                            );
                            preViewImg = (
                              <p>{interface_translate['Video'][language]}</p>
                            );
                          }
                          return (
                            <div className="DropDownMediaBlockDelete">
                              <DropDownMediaBlock
                                key={id}
                                preViewImg={preViewImg}
                                mediaBlock={mediaBlock}
                              />
                              <button
                                onClick={() => {
                                  setSelectedImageIdToDelete(model.id);
                                  setViewDeleteImageConfirmDialog(true);
                                }}
                              >
                                {interface_translate['Delete'][language]}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                      <ListAttrMedia
                        setSelectedElement={(value) => {
                          setImagesForCreate((prevData) => {
                            return { ...prevData, ...value };
                          });
                        }}
                        deleteSelectedElement={(id) => {
                          setImagesForCreate((prevElements) => {
                            delete prevElements[id];
                            return { ...prevElements };
                          });
                        }}
                      />
                    </div>
                  </div>
                  {errors['images'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['images'][0]][language]}
                    </div>
                  )}
                </div>
              )}
            </OpeningBlock>
          </div>
        </>
      )}
      {!modelData && <div>Пусто</div>}
    </div>
  );
};

export default ProductEditPage;

{
  /* <div className="openingBlockAttr">
  <div className="openingBlockAttrName">
    {interface_translate['Total weight in kg'][language]}
  </div>
  <input
    type="text"
    value={modelData.total_weight_in_kg}
    onChange={(e) => {
      setModelData({
        ...modelData,
        total_weight_in_kg: e.target.value,
      });
    }}
  />
</div>;
{
  errors['total_weight_in_kg'] && (
    <div className="openingBlockErrorAttr">
      {api_error_translate[errors['total_weight_in_kg'][0]][language]}
    </div>
  );
}
<div className="openingBlockAttr">
  <div className="openingBlockAttrName">
    {interface_translate['Total volume in м³'][language]}
  </div>
  <input
    type="text"
    value={modelData.total_volume_in_m3}
    onChange={(e) => {
      setModelData({
        ...modelData,
        total_volume_in_m3: e.target.value,
      });
    }}
  />
</div>;
{
  errors['total_volume_in_m3'] && (
    <div className="openingBlockErrorAttr">
      {api_error_translate[errors['total_volume_in_m3'][0]][language]}
    </div>
  );
}
<div className="openingBlockAttr">
  <div className="openingBlockAttrName">
    {interface_translate['Actual content'][language]}
  </div>
  <input
    type="text"
    value={modelData.actual_content}
    onChange={(e) => {
      setModelData({
        ...modelData,
        actual_content: e.target.value,
      });
    }}
  />
</div>;
{
  errors['actual_content'] && (
    <div className="openingBlockErrorAttr">
      {api_error_translate[errors['actual_content'][0]][language]}
    </div>
  );
} */
}
