import { Outlet, Navigate, useLocation } from 'react-router-dom';
import useCommon from '../hooks/useCommon';
import Bad from '../pages/Bad';
import roleAccess from '../roleAccess';
import useAuth from '../hooks/useAuth';

const PrivateRoute = (props) => {
  const { isAuth } = useAuth();
  const { userRole } = useCommon();
  const location = useLocation();
  const { section, permission } = props;
  if (!roleAccess[userRole]?.permissions[section]?.[permission]) {
    return (
      <Navigate
        to="/not_found"
        replace
      />
    );
  } else if (isAuth === false) {
    return (
      <Navigate
        to="/login"
        state={{ from: location }}
        replace
      />
    );
  }

  return <Outlet />;
};

export default PrivateRoute;
