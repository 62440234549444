import {
  FaAngleLeft,
  FaAnglesLeft,
  FaAngleRight,
  FaAnglesRight,
} from 'react-icons/fa6';
import styles from './PageSwitch.module.css';

const PageSwitch = (props) => {
  const { setPaginationSettings, currentPage, totalPages } = props;
  return (
    <div className={styles.pageSwitch}>
      <button
        className={styles.btn}
        onClick={() =>
          setPaginationSettings({ page: Math.max(1, currentPage - 2) })
        }
      >
        <FaAnglesLeft className={styles.btnIcon} />
      </button>
      <button
        className={styles.btn}
        onClick={() =>
          setPaginationSettings({ page: Math.max(1, currentPage - 1) })
        }
      >
        <FaAngleLeft className={styles.btnIcon} />
      </button>
      <p className={styles.pageNumber}>{currentPage}</p>
      <button
        className={styles.btn}
        onClick={() => setPaginationSettings({ page: currentPage + 1 })}
      >
        <FaAngleRight className={styles.btnIcon} />
      </button>
      <button
        className={styles.btn}
        onClick={() =>
          setPaginationSettings({ page: Math.max(1, currentPage + 2) })
        }
      >
        <FaAnglesRight className={styles.btnIcon} />
      </button>
      <p>{totalPages}</p>
    </div>
  );
};

export default PageSwitch;
