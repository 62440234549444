import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useCommon from '../../hooks/useCommon';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import OpeningBlock from '../../components/OpeningBlock';
import roleAccess from '../../roleAccess';
import shelvingService from '../../services/shelving';
import { interface_translate } from '../../texts/interface';
import styles from './ShelvingPage.module.css';
import '../shared.css';

async function get_shelving(id) {
  const shelving = await shelvingService.get(id);
  return shelving;
}

const ShelvingPage = (props) => {
  const { language, userRole } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [shelving, setShelving] = useState();
  const [attrsForRead, setAttrsForRead] = useState(new Set());

  useEffect(() => {
    const req = async () => {
      const shelving_resp = await get_shelving(params.id);
      setShelving(shelving_resp);
    };
    req();
    pushPage(location.pathname);
    const attrs_for_read = new Set(
      roleAccess[userRole].fieldRestrictions.Shelvings?.read
    );
    setAttrsForRead(attrs_for_read);
  }, []);

  return (
    <div className={`pageViewModel`}>
      <div className="controls">
        {roleAccess[userRole].permissions.Shelvings.edit && (
          <button
            onClick={() => {
              navigate('edit');
            }}
          >
            {interface_translate['Edit'][language]}
          </button>
        )}
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
      </div>
      {shelving && (
        <>
          <div className="viewData">
            <OpeningBlock
              title={interface_translate['About shelving'][language]}
              open={true}
            >
              {attrsForRead.has('id') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['shelving id'][language]}
                  </div>
                  <span>{shelving.id}</span>
                </div>
              )}
              {attrsForRead.has('name') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Name'][language]}
                  </div>
                  <span>{shelving.name}</span>
                </div>
              )}
              {attrsForRead.has('storehouse_name') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Storehouse'][language]}
                  </div>
                  <span
                    className="linkToModel"
                    onClick={() =>
                      navigate(`/storehouses/${shelving.storehouse.id}`, {
                        state: { from: location },
                      })
                    }
                  >
                    {shelving.storehouse.name}
                  </span>
                </div>
              )}
            </OpeningBlock>
          </div>
        </>
      )}
      {!shelving && <div>Пусто</div>}
    </div>
  );
};

export default ShelvingPage;
