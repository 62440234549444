import { useState, React, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { api_error_translate } from '../../texts/error';
import { interface_translate } from '../../texts/interface';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import roleAccess from '../../roleAccess';
import storageCellService from '../../services/storage_cell';
import shelvingService from '../../services/shelving';
import styles from './StorageCellEditPage.module.css';
import '../shared.css';

async function get_storage_cell(id) {
  const storageCell = await storageCellService.get(id);
  return storageCell;
}

async function update_storage_cell(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await storageCellService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_shelvings(filters = {}, size = 50) {
  const shelvingsPaginated = await shelvingService.get_by_filters(filters, {
    size: size,
  });
  return shelvingsPaginated.data;
}

const StorageCellEditPage = (props) => {
  const { handleExit } = props;
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState();
  const [oldStorageCell, setOldStorageCell] = useState({});
  const [attrsForEdit, setAttrsForEdit] = useState(new Set());
  const [shelvingInputValue, setStorehouseInputValue] = useState(null);
  const [shelvingElementsForSelect, setStorehouseElementsForSelect] = useState(
    []
  );

  useEffect(() => {
    const req = async () => {
      const storage_cell_resp = await get_storage_cell(params.id);
      setOldStorageCell({ ...storage_cell_resp });
      setModelData(storage_cell_resp);
    };
    req();
    pushPage(location.pathname);
    const attrsForEdit = new Set(
      roleAccess[userRole].fieldRestrictions['Storage cells']?.edit
    );
    setAttrsForEdit(attrsForEdit);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (shelvingInputValue) filters['name'] = shelvingInputValue;
      const new_elements = await get_shelvings(filters);
      setStorehouseElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [shelvingInputValue]);

  const getDataFromAttr = () => {
    const data = {
      ...modelData,
      number: modelData.number,
      shelving_id: modelData.shelving.id,
      free: modelData.free == 'true',
    };
    return data;
  };

  if (!modelData) return;

  return (
    <div className="pageUpdateModel">
      <div className={styles.controls}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await update_storage_cell(
              params.id,
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(
                interface_translate['Storage cell updated'][language]
              );
              resetGlobalError();
              setErrors({});
              if (handleExit) {
                handleExit();
                return;
              }
              popPage();
              const previousPage = getCurrentPage();
              navigate(previousPage ? previousPage : '..', {
                relative: 'path',
              });
            }
          }}
        >
          {interface_translate['Update'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
      </div>
      <div className="inputData">
        <OpeningBlock
          title={interface_translate['About storage cell'][language]}
          open={true}
        >
          {attrsForEdit.has('number') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Number'][language]}
                </div>
                <input
                  type="text"
                  value={modelData.number}
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      number: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['number'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['number'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForEdit.has('shelving_name') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Shelving'][language]}
                </div>
                <Autocomplete
                  value={modelData.shelving}
                  onChange={(e, newElement) => {
                    setModelData({
                      ...modelData,
                      shelving: newElement,
                    });
                  }}
                  options={shelvingElementsForSelect}
                  getOptionLabel={(model) => model.name}
                  onInputChange={(e, value) => {
                    setStorehouseInputValue(value);
                    setStorehouseElementsForSelect([]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={interface_translate['Shelving'][language]}
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={shelvingElementsForSelect.length == 0}
                />
              </div>
              {errors['shelving_id'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['shelving_id'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForEdit.has('free_name') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['free'][language]}
                </div>
                <select
                  value={modelData.free}
                  onChange={(e) => {
                    setModelData({ ...modelData, free: e.target.value });
                  }}
                >
                  <option value={true}>
                    {interface_translate['free'][language]}
                  </option>
                  <option value={false}>
                    {interface_translate['occupied'][language]}
                  </option>
                </select>
              </div>
              {errors['free'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['free'][0]][language]}
                </div>
              )}
            </div>
          )}
        </OpeningBlock>
      </div>
      {!modelData && <div>Пусто</div>}
    </div>
  );
};
export default StorageCellEditPage;
