import { useState, React, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { api_error_translate } from '../../texts/error';
import { interface_translate } from '../../texts/interface';
import useCommon from '../../hooks/useCommon';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import roleAccess from '../../roleAccess';
import orderService from '../../services/order.js';
import userService from '../../services/user.js';
import counterpartyService from '../../services/counterparty.js';
import styles from './OrderEditPage.module.css';
import '../shared.css';
import CoExecutorsInput from '../../components/input/CoExecutorsInput.jsx';
import ResponsibleListInput from '../../components/input/ResponsibleListInput.jsx';

async function get_order(id) {
  const order = await orderService.get(id);
  return order;
}

async function update_order(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await orderService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_users(filters = {}, size = 50) {
  const result = await userService.get_by_filters(filters, { size: size });
  return result.data;
}

async function get_user(id) {
  const result = await userService.get(id);
  return result;
}

async function get_clients(filters = {}, size = 50) {
  filters = { group: 'client', ...filters };
  const result = await counterpartyService.get_by_filters(filters, {
    size: size,
  });
  return result.data;
}

const OrderEditPage = () => {
  const { resetGlobalError, setGlobalMsg, resetGlobalMsg, language, userRole } =
    useCommon();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const params = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState({ products: [] });
  const [oldOrder, setOldOrder] = useState({});
  const [defUsers, setDefUsers] = useState(null);
  const [attrsForEdit, setAttrsForEdit] = useState(new Set());
  const [responsible, setResponsible] = useState({});
  const [coExecutors, setCoExecutors] = useState({});
  const [clientInputValue, setClientInputValue] = useState('');
  const [clientElementsForSelect, setClientElementsForSelect] = useState([]);

  useEffect(() => {
    const req = async () => {
      const order_resp = await get_order(params.id);
      const responsible = [];
      for (const user_id of order_resp.responsible_ids) {
        const user = await get_user(user_id);
        responsible.push(user);
      }
      order_resp.responsible = responsible;
      const co_executors = [];
      for (const user_id of order_resp.co_executors_ids) {
        const user = await get_user(user_id);
        co_executors.push(user);
      }
      order_resp.coExecutors = co_executors;
      for (const product of order_resp.products) {
        product.supplier_name = product.supplier?.name;
      }
      setOldOrder({ ...order_resp });
      setModelData(order_resp);
    };
    req();
    const attrsForEdit = new Set(
      roleAccess[userRole].fieldRestrictions.Orders?.edit
    );
    setAttrsForEdit(attrsForEdit);
  }, []);

  useEffect(() => {
    const req = async () => {
      const users = await get_users();
      setDefUsers(users);
    };
    req();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      const new_elements = await get_clients({ name: clientInputValue });
      setClientElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [clientInputValue]);

  const getDataFromAttr = () => {
    if (modelData.client == null) {
      setErrors({
        client_id: ['There are blank fields left'],
      });
      return;
    }
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    for (let id of responsible_ids) {
      if (id === null) {
        setErrors({
          responsible_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const co_executor_ids = Object.values(coExecutors).map((m) =>
      m ? m.id : null
    );
    for (let id of co_executor_ids) {
      if (id === null) {
        setErrors({
          co_executors_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const data = {
      ...modelData,
      client_id: modelData.client.id,
      responsible_ids: responsible_ids,
      co_executors_ids: co_executor_ids,
    };
    delete data['responsible'];
    delete data['co_executors'];
    delete data['client'];
    return data;
  };

  return (
    <div className="pageUpdateModel">
      <div className={styles.controls}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await update_order(
              params.id,
              data,
              setErrors,
              resetGlobalMsg
            );
            if (result) {
              setGlobalMsg(interface_translate['Order updated'][language]);
              resetGlobalError();
              setErrors({});
              popPage();
              const previousPage = getCurrentPage();
              navigate(previousPage ? previousPage : '..', {
                relative: 'path',
              });
            }
          }}
        >
          {interface_translate['Update'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('../..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      {modelData.hasOwnProperty('responsible') && (
        <>
          <div className="inputData">
            <OpeningBlock
              title={interface_translate['About order'][language]}
              open={true}
            >
              {attrsForEdit.has('client_name') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Client'][language]}
                    </div>
                    <Autocomplete
                      value={modelData.client}
                      onChange={(e, newElement) => {
                        setModelData({
                          ...modelData,
                          client: newElement,
                        });
                      }}
                      options={clientElementsForSelect}
                      getOptionLabel={(model) => model.name}
                      onInputChange={(e, value) => {
                        setClientInputValue(value);
                        setClientElementsForSelect([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={interface_translate['Client'][language]}
                          size="small"
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      loading={clientElementsForSelect.length == 0}
                    />
                  </div>
                  {errors['client_id'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['client_id'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('estimated_cost_logistics') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {
                        interface_translate['Estimated cost logistics'][
                          language
                        ]
                      }
                    </div>
                    <input
                      type="text"
                      value={modelData.estimated_cost_logistics}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          estimated_cost_logistics: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['estimated_cost_logistics'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[
                          errors['estimated_cost_logistics'][0]
                        ][language]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('rate_cny_to_rub') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Rate cny to rub'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.rate_cny_to_rub}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          rate_cny_to_rub: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['rate_cny_to_rub'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['rate_cny_to_rub'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('rate_usd_to_rub') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Rate usd to rub'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.rate_dollar_to_rub}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          rate_dollar_to_rub: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['rate_dollar_to_rub'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['rate_dollar_to_rub'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('responsible') && (
                <div>
                  <ResponsibleListInput
                    containerClass={'openingBlockAttr'}
                    attrNameClass={'openingBlockAttrName'}
                    responsible={responsible}
                    setSelectedElement={(value) => {
                      setResponsible((prevData) => {
                        return { ...prevData, ...value };
                      });
                    }}
                    deleteSelectedElement={(id) => {
                      setResponsible((prevElements) => {
                        delete prevElements[id];
                        return { ...prevElements };
                      });
                    }}
                    initialElements={modelData.responsible}
                    defaultElements={defUsers}
                  />
                  {errors['responsible_ids'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['responsible_ids'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('co_executors') && (
                <div>
                  <CoExecutorsInput
                    containerClass={'openingBlockAttr'}
                    attrNameClass={'openingBlockAttrName'}
                    coExecutors={coExecutors}
                    setSelectedElement={(value) => {
                      setCoExecutors((prevData) => {
                        return { ...prevData, ...value };
                      });
                    }}
                    deleteSelectedElement={(id) => {
                      setCoExecutors((prevElements) => {
                        delete prevElements[id];
                        return { ...prevElements };
                      });
                    }}
                    initialElements={modelData.coExecutors}
                    defaultElements={defUsers}
                  />
                  {errors['co_executors_ids'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['co_executors_ids'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
            </OpeningBlock>
          </div>
        </>
      )}
      {!modelData && <div>Пусто</div>}
    </div>
  );
};
export default OrderEditPage;
