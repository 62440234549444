import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCommon from '../../hooks/useCommon';
import { interface_translate } from '../../texts/interface';
import ModelsViewBox from './ModelsViewBox';
import PageSwitch from './PageSwitch';
import styles from './ModelView.module.css';

const ModelView = (props) => {
  const { language } = useCommon();
  const navigate = useNavigate();
  const {
    tableAttrs,
    models,
    useSelected = true,
    setSelectedModels,
    sortData,
    setSortData,
    usePagination = true,
    setPaginationSettings,
    currentPage,
    totalPages,
    currentSize,
    useSettings = true,
    handleModelClick = (model) => {
      navigate(`${model.id}`);
    },
  } = props;
  const [showSetting, setShowSettings] = useState(false);
  const [viewSettingData, setViewSettingData] = useState({ size: currentSize });
  const [modifiedTableAttrs, setModifiedTableAttrs] = useState(tableAttrs);

  const toggleShowSettings = () => {
    setPaginationSettings({ size: Math.max(1, viewSettingData.size) });
    setShowSettings(!showSetting);
  };

  const addViewSettingsData = (new_settings) => {
    setViewSettingData({ ...viewSettingData, ...new_settings });
  };

  useEffect(() => {
    const newModifiedTableAttrs = tableAttrs.map((attr_info) => {
      return {
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      };
    });
    setModifiedTableAttrs(newModifiedTableAttrs);
  }, [tableAttrs, language]);

  return (
    <div className={styles.modelView}>
      <ModelsViewBox
        tableAttrs={modifiedTableAttrs}
        models={models}
        useSelected={useSelected}
        setSelectedModels={setSelectedModels}
        sortData={sortData}
        setSortData={setSortData}
        settings={
          useSettings && (
            <div>
              <p>{interface_translate['Qty entries'][language]}</p>
              <input
                type="text"
                onChange={(e) => {
                  let value = e.target.value;
                  if (!value) value = '0';
                  if (/^\d+$/.test(value)) {
                    addViewSettingsData({ size: Number(value) });
                  }
                }}
                value={
                  viewSettingData['size'] === 0 ? '' : viewSettingData['size']
                }
              />
            </div>
          )
        }
        showSetting={showSetting}
        toggleShowSettings={toggleShowSettings}
        handleModelClick={handleModelClick}
      />
      {usePagination && (
        <PageSwitch
          currentPage={currentPage}
          totalPages={totalPages}
          setPaginationSettings={setPaginationSettings}
        />
      )}
    </div>
  );
};

export default ModelView;
