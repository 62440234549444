import { createContext, useState } from 'react';
import apiRequester from '../ApiRequester';

let old_settings = JSON.parse(localStorage.getItem('settings_view'));

if (
  old_settings === null ||
  (old_settings && !old_settings.hasOwnProperty('counterparties')) ||
  !old_settings.hasOwnProperty('orders') ||
  !old_settings.hasOwnProperty('products') ||
  !old_settings.hasOwnProperty('deliveries') ||
  !old_settings.hasOwnProperty('unidentified_deliveries') ||
  !old_settings.hasOwnProperty('shipments') ||
  !old_settings.hasOwnProperty('customer_cargo') ||
  !old_settings.hasOwnProperty('unidentified_cargo') ||
  !old_settings.hasOwnProperty('storehouses') ||
  !old_settings.hasOwnProperty('shelvings') ||
  !old_settings.hasOwnProperty('storage_cells')
) {
  old_settings = {
    counterparties: { size: 10, filterData: {} },
    orders: { size: 10, filterData: {} },
    products: { size: 10, filterData: {} },
    deliveries: { size: 10, filterData: {} },
    unidentified_deliveries: { size: 10, filterData: {} },
    shipments: { size: 10, filterData: {} },
    customer_cargo: { size: 10, filterData: {} },
    unidentified_cargo: { size: 10, filterData: {} },
    storehouses: { size: 10, filterData: {} },
    shelvings: { size: 10, filterData: {} },
    storage_cells: { size: 10, filterData: {} },
  };
  localStorage.setItem('settings_view', JSON.stringify(old_settings));
}
const ViewSettingsContext = createContext(old_settings);

export const ViewSettingsProvider = ({ children }) => {
  const [allViewSettings, setAllViewSettings] = useState(old_settings);

  const handleSetAllViewSettings = (settings) => {
    const new_settings = { ...allViewSettings, ...settings };
    setAllViewSettings(new_settings);
    localStorage.setItem('settings_view', JSON.stringify(new_settings));
  };

  return (
    <ViewSettingsContext.Provider
      value={{ allViewSettings, setAllViewSettings: handleSetAllViewSettings }}
    >
      {children}
    </ViewSettingsContext.Provider>
  );
};

export default ViewSettingsContext;
