import { useState, React, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import { api_error_translate } from '../../texts/error';
import { statuses_translate } from '../../texts/statuses.js';
import { interface_translate } from '../../texts/interface';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import ConfirmDialog from '../../components/ConfirmDialog';
import ListAttrMedia from '../../components/ListAttrMedia';
import DropDownMediaBlock from '../../components/DropDownMediaBlock';
import roleAccess from '../../roleAccess.js';
import deliveryService from '../../services/delivery.js';
import orderService from '../../services/order.js';
import userService from '../../services/user.js';
import productService from '../../services/product.js';
import styles from './DeliveryEditPage.module.css';
import '../shared.css';
import CoExecutorsInput from '../../components/input/CoExecutorsInput.jsx';
import ResponsibleListInput from '../../components/input/ResponsibleListInput.jsx';
import ProductsWithQtyInput from '../../components/input/ProductsWithQtyInput.jsx';

async function get_delivery(id) {
  const delivery = await deliveryService.get(id);
  return delivery;
}

async function get_delivery_statuses() {
  const response = await deliveryService.get_statuses();
  return response;
}

async function update_delivery(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await deliveryService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_orders(filters = {}, size = 50) {
  const orders_paginated = await orderService.get_by_filters(filters, {
    size: size,
  });
  return orders_paginated.data;
}

async function get_order(id) {
  const result = await orderService.get(id);
  return result;
}

async function get_products(order_id, name, supplier_id, size = 50) {
  const filters = {
    name: name,
    order_id: order_id,
    supplier_id: supplier_id,
    delivery_id: null,
  };

  const product_paginated = await productService.get_by_filters(filters, {
    size: size,
  });
  return product_paginated.data;
}

async function get_users(filters = {}, size = 50) {
  const users_paginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return users_paginated.data;
}

async function get_user(id) {
  const user = await userService.get(id);
  return user;
}

const DeliveryEditPage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState({});
  const [oldDelivery, setOldDelivery] = useState();
  const [oldSupplier, setOldSupplier] = useState();
  const [attrsForEdit, setAttrsForEdit] = useState(new Set());
  const [deliveryStatuses, setDeliveryStatuses] = useState([]);
  const [defUsers, setDefUsers] = useState(null);
  const [orderInputValue, setOrderInputValue] = useState('');
  const [orderElementsForSelect, setOrderElementsForSelect] = useState([]);
  const [suppliersForSelect, setSuppliersForSelect] = useState([]);
  const [supplier, setSupplier] = useState(null);
  const [productsForSelect, setProductsForSelect] = useState([]);
  const [products, setProducts] = useState({});
  const [mediaContentAcceptanceForCreate, setMediaContentAcceptanceForCreate] =
    useState({});
  const [
    selectedMediaContentAcceptanceIdToDelete,
    setSelectedMediaContentAcceptanceIdToDelete,
  ] = useState(null);
  const [
    viewDeleteMediaContentAcceptanceConfirmDialog,
    setViewDeleteMediaContentAcceptanceConfirmDialog,
  ] = useState(false);
  const [
    mediaContentAcceptanceIdForDelete,
    setMediaContentAcceptanceIdForDelete,
  ] = useState([]);
  const [responsible, setResponsible] = useState({});
  const [coExecutors, setCoExecutors] = useState({});

  useEffect(() => {
    const req = async () => {
      const delivery_resp = await get_delivery(params.id);
      const responsible = [];
      for (const user_id of delivery_resp.responsible_ids) {
        const user = await get_user(user_id);
        responsible.push(user);
      }
      delivery_resp.responsible = responsible;
      const co_executors = [];
      for (const user_id of delivery_resp.co_executors_ids) {
        const user = await get_user(user_id);
        co_executors.push(user);
      }
      delivery_resp.coExecutors = co_executors;

      const order = await get_order(delivery_resp.order_id);
      delivery_resp.order = order;
      const supplier = delivery_resp.delivery_products[0]?.product.supplier;
      change_order(order, supplier);

      if (!delivery_resp.total_weight_in_kg)
        delivery_resp.total_weight_in_kg = '';
      if (!delivery_resp.total_volume_in_m3)
        delivery_resp.total_volume_in_m3 = '';
      if (!delivery_resp.actual_content) delivery_resp.actual_content = '';
      delete delivery_resp['barcode_img'];
      const delivery = delivery_resp;
      setOldDelivery({ ...delivery });
      setModelData(delivery_resp);

      if (delivery_resp.delivery_products.length > 0) {
        setSupplier(supplier);
        setOldSupplier(supplier);
        let productsForSelect = await get_products(order.id, '', supplier.id);
        setProductsForSelect(productsForSelect);
      }

      const deliveryStatuses = await get_delivery_statuses();
      setDeliveryStatuses(deliveryStatuses);

      const users = await get_users();
      setDefUsers(users);
    };
    req();
    pushPage(location.pathname);
    const attrsForEdit = new Set(
      roleAccess[userRole].fieldRestrictions.Deliveries?.edit
    );
    setAttrsForEdit(attrsForEdit);
  }, []);

  const change_order = async (order, oldSupplier_ = null) => {
    const supplier_id_to_supplier = {};
    if (order) {
      for (const product of order.products) {
        if (product.delivery_id == null && product.supplier) {
          supplier_id_to_supplier[product.supplier.id] = product.supplier;
        }
      }
    }
    const newSuppliersForSelect = [
      ...new Set(Object.values(supplier_id_to_supplier)),
    ];
    if (oldSupplier) {
      newSuppliersForSelect.push(oldSupplier);
    }
    if (oldSupplier_) {
      newSuppliersForSelect.push(oldSupplier_);
    }
    setProductsForSelect([]);
    setSuppliersForSelect([...newSuppliersForSelect]);
    modelData.products = [];
    setModelData({
      ...modelData,
      order: order,
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = { complete: false };
      if (orderInputValue) filters['id'] = orderInputValue;
      const new_elements = await get_orders(filters);

      setOrderElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [orderInputValue]);

  const getDataFromAttr = () => {
    if (modelData.order == null) {
      setErrors({
        order_id: ['There are blank fields left'],
      });
      return;
    }

    const delivery_products = Object.values(products).map((m) => {
      return { id: m.id ? m.id : null, product_id: m.product.id, qty: m.qty };
    });
    for (let delivery_product of delivery_products) {
      if (!delivery_product.product_id || !delivery_product.qty) {
        setErrors({
          products_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    for (let id of responsible_ids) {
      if (id === null) {
        setErrors({
          responsible_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const co_executor_ids = Object.values(coExecutors).map((m) =>
      m ? m.id : null
    );
    for (let id of co_executor_ids) {
      if (id === null) {
        setErrors({
          co_executors_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const data = {
      order_id: modelData.order.id,
      delivery_products: delivery_products,
      status: modelData.status,
      shipment_id: modelData.shipment ? modelData.shipment.id : null,
      responsible_ids: responsible_ids,
      co_executors_ids: co_executor_ids,
      media_content_acceptance_for_create: Object.values(
        mediaContentAcceptanceForCreate
      ),
      media_content_acceptance_id_for_delete: mediaContentAcceptanceIdForDelete,
    };
    if (modelData.track_number != oldDelivery.track_number) {
      data.track_number = modelData.track_number;
    }
    if (modelData.total_weight_in_kg) {
      data.total_weight_in_kg = modelData.total_weight_in_kg;
    }
    if (modelData.total_volume_in_m3) {
      data.total_volume_in_m3 = modelData.total_volume_in_m3;
    }
    if (modelData.actual_content) {
      data.actual_content = modelData.actual_content;
    }
    return data;
  };

  if (!modelData) return;

  return (
    <div className="pageUpdateModel">
      {viewDeleteMediaContentAcceptanceConfirmDialog && (
        <ConfirmDialog
          message={interface_translate['Delete?'][language]}
          onConfirm={() => {
            setViewDeleteMediaContentAcceptanceConfirmDialog(false);
            setMediaContentAcceptanceIdForDelete((prevData) => {
              const new_data = [
                ...prevData,
                selectedMediaContentAcceptanceIdToDelete,
              ];
              return new_data;
            });
            setSelectedMediaContentAcceptanceIdToDelete(null);
            setModelData({
              ...modelData,
              media_content_acceptance:
                modelData.media_content_acceptance.filter(
                  (model) =>
                    model.id !== selectedMediaContentAcceptanceIdToDelete
                ),
            });
          }}
          onCancel={() =>
            setViewDeleteMediaContentAcceptanceConfirmDialog(false)
          }
        />
      )}
      <div className={styles.controls}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await update_delivery(
              params.id,
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(interface_translate['Delivery updated'][language]);
              resetGlobalError();
              setErrors({});
              popPage();
              const previousPage = getCurrentPage();
              navigate(previousPage ? previousPage : '..', {
                relative: 'path',
              });
            }
          }}
        >
          {interface_translate['Update'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('../..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      {modelData.hasOwnProperty('responsible') && (
        <>
          <div className="inputData">
            <OpeningBlock
              title={interface_translate['About delivery'][language]}
              open={true}
            >
              {attrsForEdit.has('order_id') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Order'][language]}
                    </div>
                    <Autocomplete
                      value={modelData.order}
                      onChange={(e, newElement) => {
                        change_order(newElement);
                        setSupplier(null);
                      }}
                      options={orderElementsForSelect}
                      getOptionLabel={(model) => model.id.toString()}
                      onInputChange={(e, value) => {
                        setOrderInputValue(value);
                        setOrderElementsForSelect([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={interface_translate['Order'][language]}
                          size="small"
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      loading={orderElementsForSelect.length == 0}
                    />
                  </div>
                  {errors['order_id'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['order_id'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('supplier') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Supplier'][language]}
                    </div>
                    {modelData.order !== null &&
                      modelData.order !== undefined && (
                        <Autocomplete
                          value={supplier}
                          onChange={async (e, newElement) => {
                            setSupplier(newElement);
                            setProducts([]);
                            let new_products = [];
                            const products_from_old_delivery =
                              oldDelivery.delivery_products.map(
                                (m) => m.product
                              );
                            if (
                              newElement &&
                              products_from_old_delivery.length > 0 &&
                              newElement.id ==
                                products_from_old_delivery[0].supplier.id
                            ) {
                              new_products = products_from_old_delivery;
                            }
                            if (newElement) {
                              const new_products_ = await get_products(
                                modelData.order.id,
                                '',
                                newElement.id
                              );
                              new_products = [
                                ...new_products,
                                ...new_products_,
                              ];
                            }
                            setProductsForSelect(new_products);
                            setModelData({
                              ...modelData,
                              products: [],
                            });
                          }}
                          options={suppliersForSelect}
                          getOptionLabel={(model) => model.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={interface_translate['Supplier'][language]}
                              size="small"
                            />
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          loading={orderElementsForSelect.length == 0}
                        />
                      )}
                  </div>
                </div>
              )}
              {attrsForEdit.has('products') && (
                <div>
                  {supplier !== null && (
                    <ProductsWithQtyInput
                      containerClass={'openingBlockAttr'}
                      attrNameClass={'openingBlockAttrName'}
                      products={products}
                      setSelectedElement={(value) => {
                        setProducts((prevData) => {
                          return { ...prevData, ...value };
                        });
                      }}
                      deleteSelectedElement={(id) => {
                        setProducts((prevElements) => {
                          delete prevElements[id];
                          return { ...prevElements };
                        });
                      }}
                      supplier={supplier}
                      order={modelData.order}
                      defaultElements={productsForSelect}
                      initialElements={modelData.delivery_products}
                      product_ids_errors={(() => {
                        const err_data = errors['delivery_product.qty'];
                        if (!err_data) return {};
                        return {
                          [err_data.productId]: `Превышает допустимый лимит ${err_data.availableStock}`,
                        };
                      })()}
                      errorContainerClass={'openingBlockErrorAttr'}
                    />
                  )}
                  {errors['products_ids'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['products_ids'][0]][language]}
                    </div>
                  )}
                </div>
                // <div>
                //   <div className="openingBlockAttr">
                //     <div className="openingBlockAttrName">
                //       {interface_translate['Products'][language]}
                //     </div>
                //     {supplier !== null && (
                //       <ListAttrAutocomplete
                //         selectedElements={products}
                //         setSelectedElement={(value) => {
                //           setProducts((prevData) => {
                //             return { ...prevData, ...value };
                //           });
                //         }}
                //         deleteSelectedElement={(id) => {
                //           setProducts((prevElements) => {
                //             delete prevElements[id];
                //             return { ...prevElements };
                //           });
                //         }}
                //         defElements={productsForSelect}
                //         defaultElements={modelData.products}
                //         getElementLabel={(model) => model.name}
                //         renderInput={(params) => (
                //           <TextField
                //             {...params}
                //             variant="outlined"
                //             label={interface_translate['Product'][language]}
                //             size="small"
                //           />
                //         )}
                //         searchElements={async (value) => {
                //           let products = await get_products(
                //             modelData.order.id,
                //             value,
                //             supplier.id
                //           );
                //           products = [...products, ...oldDelivery.products];
                //           return products;
                //         }}
                //       />
                //     )}
                //   </div>
                //   {errors['products_ids'] && (
                //     <div className="openingBlockErrorAttr">
                //       {api_error_translate[errors['products_ids'][0]][language]}
                //     </div>
                //   )}
                // </div>
              )}
              {attrsForEdit.has('track_number') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Track number'][language]}
                    </div>
                    <input
                      type="text"
                      value={modelData.track_number}
                      onChange={(e) => {
                        setModelData({
                          ...modelData,
                          track_number: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {errors['track_number'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['track_number'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('status_tranclate') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Status'][language]}
                    </div>
                    <select
                      value={modelData.status}
                      onChange={(e) => {
                        setModelData({ ...modelData, status: e.target.value });
                      }}
                    >
                      {deliveryStatuses.map((status, id) => (
                        <option
                          key={id}
                          value={status}
                        >
                          {statuses_translate[status][language]}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors['status'] && (
                    <div className="openingBlockErrorAttr">
                      {api_error_translate[errors['status'][0]][language]}
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('responsible') && (
                <div>
                  <ResponsibleListInput
                    containerClass={'openingBlockAttr'}
                    attrNameClass={'openingBlockAttrName'}
                    responsible={responsible}
                    setSelectedElement={(value) => {
                      setResponsible((prevData) => {
                        return { ...prevData, ...value };
                      });
                    }}
                    deleteSelectedElement={(id) => {
                      setResponsible((prevElements) => {
                        delete prevElements[id];
                        return { ...prevElements };
                      });
                    }}
                    initialElements={modelData.responsible}
                    defaultElements={defUsers}
                  />
                  {errors['responsible_ids'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['responsible_ids'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('co_executors') && (
                <div>
                  <CoExecutorsInput
                    containerClass={'openingBlockAttr'}
                    attrNameClass={'openingBlockAttrName'}
                    coExecutors={coExecutors}
                    setSelectedElement={(value) => {
                      setCoExecutors((prevData) => {
                        return { ...prevData, ...value };
                      });
                    }}
                    deleteSelectedElement={(id) => {
                      setCoExecutors((prevElements) => {
                        delete prevElements[id];
                        return { ...prevElements };
                      });
                    }}
                    initialElements={modelData.coExecutors}
                    defaultElements={defUsers}
                  />
                  {errors['co_executors_ids'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[errors['co_executors_ids'][0]][
                          language
                        ]
                      }
                    </div>
                  )}
                </div>
              )}
              {attrsForEdit.has('media_content_acceptance') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {
                        interface_translate['Media content of acceptance'][
                          language
                        ]
                      }
                      <p>{`${interface_translate['Allowed formats'][language]}: "jpg", "png", "webp"`}</p>
                      <p>{`"heic", "mp4", "mov", "pdf", "docx", "xlsx"`}</p>
                    </div>
                    <div>
                      <div className="listDropDomnMedia">
                        {modelData.media_content_acceptance.map((model, id) => {
                          let mediaBlock;
                          let preViewImg;
                          if (model.file_type === 'photo') {
                            mediaBlock = (
                              <img
                                src={model.url}
                                alt="image"
                              />
                            );
                            preViewImg = mediaBlock;
                          } else if (model.file_type === 'video') {
                            mediaBlock = (
                              <video
                                src={model.url}
                                alt="video"
                                controls
                                style={{ maxWidth: '100%' }}
                              />
                            );
                            preViewImg = (
                              <p>{interface_translate['Video'][language]}</p>
                            );
                          } else if (model.file_type === 'document') {
                            mediaBlock = (
                              <div className={styles.documentBlock}>
                                <a
                                  href={model.url}
                                  download={model.filename}
                                >
                                  {interface_translate['Dowload'][language]}
                                </a>
                              </div>
                            );
                            preViewImg = (
                              <p>{interface_translate['Document'][language]}</p>
                            );
                          }
                          return (
                            <div
                              className="DropDownMediaBlockDelete"
                              key={id}
                            >
                              <DropDownMediaBlock
                                key={id}
                                preViewImg={preViewImg}
                                mediaBlock={mediaBlock}
                              />
                              <button
                                onClick={() => {
                                  setSelectedMediaContentAcceptanceIdToDelete(
                                    model.id
                                  );
                                  setViewDeleteMediaContentAcceptanceConfirmDialog(
                                    true
                                  );
                                }}
                              >
                                {interface_translate['Delete'][language]}
                              </button>
                            </div>
                          );
                        })}
                      </div>
                      <ListAttrMedia
                        setSelectedElement={(value) => {
                          setMediaContentAcceptanceForCreate((prevData) => {
                            return { ...prevData, ...value };
                          });
                        }}
                        deleteSelectedElement={(id) => {
                          setMediaContentAcceptanceForCreate((prevElements) => {
                            delete prevElements[id];
                            return { ...prevElements };
                          });
                        }}
                      />
                    </div>
                  </div>
                  {errors['media_content_acceptance'] && (
                    <div className="openingBlockErrorAttr">
                      {
                        api_error_translate[
                          errors['media_content_acceptance'][0]
                        ][language]
                      }
                    </div>
                  )}
                </div>
              )}
            </OpeningBlock>
          </div>
        </>
      )}
      {!modelData && <div>Пусто</div>}
    </div>
  );
};

export default DeliveryEditPage;
