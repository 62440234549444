import { useState } from 'react';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import styles from './OpeningBlock.module.css';

const OpeningBlock = (props) => {
  const { children, title, open = true, containerClass = '' } = props;
  const [hideBlock, setHideBlock] = useState(!open);

  return (
    <div className={containerClass}>
      <div className={styles.openingBlock}>
        <div className={styles.openPart}>
          {<p className={styles.title}>{title}</p>}
          <button
            className={styles.button}
            onClick={() => setHideBlock(!hideBlock)}
          >
            {hideBlock ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
          </button>
        </div>
        <div className={`${styles.hidePart} ${hideBlock ? 'hide' : ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default OpeningBlock;
