import RegisterForm from '../components/auth/RegisterForm';

const RegisterPage = (props) => {
  const { setGlobalError, resetGlobalError, setGlobalMsg, resetGlobalMsg } =
    props;
  return (
    <div>
      <RegisterForm
        setGlobalError={setGlobalError}
        setGlobalMsg={setGlobalMsg}
      />
    </div>
  );
};

export default RegisterPage;
