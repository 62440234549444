import { useState, React, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { api_error_translate } from '../../texts/error';
import { interface_translate } from '../../texts/interface';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import roleAccess from '../../roleAccess';
import storageCellService from '../../services/storage_cell';
import shelvingService from '../../services/shelving';
import styles from './StorageCellCreatePage.module.css';
import '../shared.css';

async function create(
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await storageCellService.create(
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_shelvings(filters = {}, size = 50) {
  const shelvingsPaginated = await shelvingService.get_by_filters(filters, {
    size: size,
  });
  return shelvingsPaginated.data;
}

const StorageCellCreatePage = (props) => {
  const { storehouseId, addStorageCell, handleExit } = props;
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const location = useLocation();
  const navigate = useNavigate();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState({});
  const [shelvingInputValue, setStorehouseInputValue] = useState(null);
  const [shelvingElementsForSelect, setStorehouseElementsForSelect] = useState(
    []
  );
  const [attrsForCreate, setAttrsForCreate] = useState(new Set());

  useEffect(() => {
    pushPage(location.pathname);
    const attrsForCreate = new Set(
      roleAccess[userRole].fieldRestrictions['Storage cells']?.create
    );
    setAttrsForCreate(attrsForCreate);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = { storehouse_id: storehouseId };
      if (shelvingInputValue) filters['name'] = shelvingInputValue;
      const new_elements = await get_shelvings(filters);
      setStorehouseElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [shelvingInputValue]);

  const getDataFromAttr = () => {
    const data = {
      ...modelData,
      number: modelData.number,
    };
    if (modelData.shelving) data.shelving_id = modelData.shelving.id;
    return data;
  };

  return (
    <div className="pageCreateModel">
      <div className={styles.saveAndExitBtns}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await create(
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(
                interface_translate['Storage cell created'][language]
              );
              resetGlobalError();
              setErrors({});
              addStorageCell(result);
              if (handleExit) {
                handleExit();
                return;
              }
              navigate('..', { relative: 'path' });
            }
          }}
        >
          {interface_translate['Create'][language]}
        </button>
      </div>
      <div className="inputData">
        <OpeningBlock
          title={interface_translate['About storage cell'][language]}
          open={true}
        >
          {attrsForCreate.has('number') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Number'][language]}
                </div>
                <input
                  type="text"
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      number: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['number'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['number'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('shelving_name') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Shelving'][language]}
                </div>
                <Autocomplete
                  value={modelData.shelving}
                  onChange={(e, newElement) => {
                    setModelData({
                      ...modelData,
                      shelving: newElement,
                    });
                  }}
                  options={shelvingElementsForSelect}
                  getOptionLabel={(model) => model.name}
                  onInputChange={(e, value) => {
                    setStorehouseInputValue(value);
                    setStorehouseElementsForSelect([]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={interface_translate['Shelving'][language]}
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={shelvingElementsForSelect.length == 0}
                />
              </div>
              {errors['shelving_id'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['shelving_id'][0]][language]}
                </div>
              )}
            </div>
          )}
        </OpeningBlock>
      </div>
    </div>
  );
};

export default StorageCellCreatePage;
