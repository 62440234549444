import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { statuses_translate } from '../../texts/statuses.js';
import { interface_translate } from '../../texts/interface';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import OpeningBlock from '../../components/OpeningBlock';
import DropDownMediaBlock from '../../components/DropDownMediaBlock';
import apiRequester from '../../ApiRequester';
import unidentifiedCargoService from '../../services/unidentified_cargo';
import roleAccess from '../../roleAccess.js';
import userService from '../../services/user';
import SelectStorageCellBtn from '../../components/SelectStorageCellBtn.jsx';
import styles from './UnidentifiedCargoPage.module.css';
import '../shared.css';

async function get_unidentified_cargo(id) {
  const unidentifiedCargo = await unidentifiedCargoService.get(id);
  return unidentifiedCargo;
}

async function update_unidentified_cargo_storage_cell(
  id,
  storage_cell_id,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const data = { storage_cell_id: storage_cell_id };
  const result = await unidentifiedCargoService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_user(id) {
  const user = await userService.get(id);
  return user;
}

const UnidentifiedCargoPage = (props) => {
  const { language, setGlobalError, resetGlobalMsg, userRole } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [unidentified_cargo, setUnidentifiedCargo] = useState();
  const [selectStorageCellErrors, setSelectStorageCellErrors] = useState({});
  const [attrsForRead, setAttrsForRead] = useState(new Set());

  useEffect(() => {
    const req = async () => {
      await requestGetUnidentifiedCargo();
    };
    req();
    pushPage(location.pathname);
    const attrs_for_read = new Set(
      roleAccess[userRole].fieldRestrictions['Unidentified cargo list']?.read
    );
    setAttrsForRead(attrs_for_read);
  }, []);

  const updateStorageCell = async (storage_cell) => {
    if (!storage_cell) return;
    const result = await update_unidentified_cargo_storage_cell(
      unidentified_cargo.id,
      storage_cell.id,
      setSelectStorageCellErrors,
      setGlobalError,
      resetGlobalMsg,
      language
    );
    if (result) {
      requestGetUnidentifiedCargo();
    }
    return result;
  };

  const requestGetUnidentifiedCargo = async () => {
    const unidentified_cargo_resp = await get_unidentified_cargo(params.id);
    const responsible = [];
    for (const user_id of unidentified_cargo_resp.responsible_ids) {
      const user = await get_user(user_id);
      responsible.push(user);
    }
    unidentified_cargo_resp.responsible = responsible;
    unidentified_cargo_resp.storehouse_id = unidentified_cargo_resp.storage_cell
      ? unidentified_cargo_resp.storage_cell.shelving.storehouse.id
      : '';
    unidentified_cargo_resp.storehouse_name =
      unidentified_cargo_resp.storage_cell
        ? unidentified_cargo_resp.storage_cell.shelving.storehouse.name
        : '';
    unidentified_cargo_resp.storage_cell_name =
      unidentified_cargo_resp.storage_cell
        ? `${unidentified_cargo_resp.storage_cell.shelving.name}${unidentified_cargo_resp.storage_cell.number}`
        : '';

    setUnidentifiedCargo(unidentified_cargo_resp);
  };
  if (unidentified_cargo == null) return;

  return (
    <div className="pageViewModel">
      <div className="controls">
        {roleAccess[userRole].permissions['Unidentified cargo list'].edit && (
          <button
            onClick={() => {
              navigate('edit');
            }}
          >
            {interface_translate['Edit'][language]}
          </button>
        )}
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
        <SelectStorageCellBtn
          btnName={interface_translate['Assign storage cell'][language]}
          setSelectedStorageCell={async (storage_cell) => {
            const result = await updateStorageCell(storage_cell);
            return result;
          }}
          errors={selectStorageCellErrors}
        />
      </div>
      {unidentified_cargo && (
        <div className="viewData">
          <OpeningBlock
            title={interface_translate['About unidentified cargo'][language]}
            open={true}
          >
            {attrsForRead.has('id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['unidentified cargo id'][language]}
                </div>
                <span>{unidentified_cargo.id}</span>
              </div>
            )}
            {attrsForRead.has('comment') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Comment'][language]}
                </div>
                <span>{unidentified_cargo.comment}</span>
              </div>
            )}
            {attrsForRead.has('invoice_number') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Invoice number'][language]}
                </div>
                <span>{unidentified_cargo.invoice_number}</span>
              </div>
            )}
            {attrsForRead.has('status_translate') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Status'][language]}
                </div>
                <span>
                  {statuses_translate[unidentified_cargo.status][language]}
                </span>
              </div>
            )}
            {attrsForRead.has('storehouse_name') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Storehouse'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(
                      `/storehouses/${unidentified_cargo.storehouse_id}`,
                      {
                        state: { from: location },
                      }
                    )
                  }
                >
                  {unidentified_cargo.storehouse_name}
                </span>
              </div>
            )}
            {attrsForRead.has('storage_cell_name') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Storage cell'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(
                      `/storage_cells/${unidentified_cargo.storage_cell.id}`,
                      {
                        state: { from: location },
                      }
                    )
                  }
                >
                  {unidentified_cargo.storage_cell_name}
                </span>
              </div>
            )}
            {attrsForRead.has('responsible') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Responsible'][language]}
                </div>
                <div className="listModels">
                  {unidentified_cargo.responsible.map((model) => {
                    return (
                      <div
                        className="modelBlock"
                        key={model.id}
                      >
                        {`${model.surname} ${model.name} ${model.patronymic}`}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {attrsForRead.has('media_content_acceptance') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Media'][language]}
                </div>
                <div className="listDropDomnMedia">
                  {unidentified_cargo.media_content_acceptance.map(
                    (model, id) => {
                      let mediaBlock;
                      let preViewImg;
                      if (model.file_type === 'photo') {
                        mediaBlock = (
                          <img
                            src={model.url}
                            alt="image"
                          />
                        );
                        preViewImg = mediaBlock;
                      } else if (model.file_type === 'video') {
                        mediaBlock = (
                          <video
                            src={model.url}
                            alt="video"
                            controls
                            style={{ maxWidth: '100%' }}
                          />
                        );
                        preViewImg = (
                          <p>{interface_translate['Vido'][language]}</p>
                        );
                      }
                      return (
                        <DropDownMediaBlock
                          key={id}
                          preViewImg={preViewImg}
                          mediaBlock={mediaBlock}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </OpeningBlock>
        </div>
      )}
      {!unidentified_cargo && <div>Пусто</div>}
    </div>
  );
};

export default UnidentifiedCargoPage;
