import React from 'react';
import useCommon from '../../hooks/useCommon';
import OpeningBlock from '../OpeningBlock';
import { interface_translate } from '../../texts/interface';

const CustomerCargoCheckOpeningBlock = (props) => {
  const { language } = useCommon();
  const { id, customerCargoProduct} = props;
  return (
    <OpeningBlock
      id={id}
      title={`${interface_translate['Check the customer cargo'][language]} ${customerCargoProduct.customer_cargo_id}`}
      open={true}
    >
      <div className="openingBlockAttr">
        <div className="openingBlockAttrName">
          {interface_translate['Qty'][language]}
        </div>
        <span>{customerCargoProduct.check_qty}</span>
      </div>
      <div className="openingBlockAttr">
        <div className="openingBlockAttrName">
          {interface_translate['Was check qty'][language]}
        </div>
        <span>
          {customerCargoProduct.was_qty_check
            ? interface_translate['Yes'][language]
            : interface_translate['No'][language]}
        </span>
      </div>
    </OpeningBlock>
  );
};

export default CustomerCargoCheckOpeningBlock;
