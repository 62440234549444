import React from 'react';
import useCommon from '../../hooks/useCommon';
import OpeningBlock from '../OpeningBlock';
import { interface_translate } from '../../texts/interface';
import DropDownMediaBlock from '../DropDownMediaBlock';

const DeliveryCheckOpeningBlock = (props) => {
  const { language } = useCommon();
  const { id, deliveryProduct } = props;
  return (
    <OpeningBlock
      id={id}
      title={`${interface_translate['Check the delivery'][language]} ${deliveryProduct.delivery_id}`}
      open={true}
    >
      <div className="openingBlockAttr">
        <div className="openingBlockAttrName">
          {interface_translate['Weight in kg'][language]}
        </div>
        <span>{deliveryProduct.check_weight_in_kg}</span>
      </div>
      <div className="openingBlockAttr">
        <div className="openingBlockAttrName">
          {interface_translate['Volume in м³'][language]}
        </div>
        <span>{deliveryProduct.check_volume_in_m3}</span>
      </div>
      <div className="openingBlockAttr">
        <div className="openingBlockAttrName">
          {interface_translate['Content'][language]}
        </div>
        <span>{deliveryProduct.check_content}</span>
      </div>
      <div className="openingBlockAttr">
        <div className="openingBlockAttrName">
          {interface_translate['Qty'][language]}
        </div>
        <span>{deliveryProduct.check_qty}</span>
      </div>
      <div className="openingBlockAttr">
        <div className="openingBlockAttrName">
          {interface_translate['Was check qty'][language]}
        </div>
        <span>
          {deliveryProduct.was_qty_check
            ? interface_translate['Yes'][language]
            : interface_translate['No'][language]}
        </span>
      </div>
      <div className="openingBlockAttr">
        <div className="openingBlockAttrName">
          {interface_translate['Media'][language]}
        </div>
        <div className="listDropDomnMedia">
          {deliveryProduct.check_media_content.map((model, id) => {
            let mediaBlock;
            let preViewImg;
            if (model.file_type === 'photo') {
              mediaBlock = (
                <img
                  src={model.url}
                  alt="image"
                />
              );
              preViewImg = mediaBlock;
            } else if (model.file_type === 'video') {
              mediaBlock = (
                <video
                  src={model.url}
                  alt="video"
                  controls
                  style={{ maxWidth: '100%' }}
                />
              );
              preViewImg = <p>{interface_translate['Video'][language]}</p>;
            }
            return (
              <DropDownMediaBlock
                key={id}
                preViewImg={preViewImg}
                mediaBlock={mediaBlock}
              />
            );
          })}
        </div>
      </div>
    </OpeningBlock>
  );
};

export default DeliveryCheckOpeningBlock;
