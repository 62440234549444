import { useState, React, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { api_error_translate } from '../../texts/error';
import { types } from '../../texts/types';
import { interface_translate } from '../../texts/interface';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import TextField from '@mui/material/TextField';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import counterpartyService from '../../services/counterparty';
import userService from '../../services/user';
import roleAccess from '../../roleAccess';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styles from './CounterpartyEditPage.module.css';
import '../shared.css';
import { admin, client_realtions_manager, supplier_realtions_manager } from '../../roles';
import ResponsibleListInput from '../../components/input/ResponsibleListInput';

async function get_counterparty(id) {
  const result = await counterpartyService.get(id);
  return result;
}

async function update_counterparty(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await counterpartyService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_users(filters = {}, size = 50) {
  const result = await userService.get_by_filters(filters, { size: size });
  return result.data;
}

async function get_user(id) {
  const result = await userService.get(id);
  return result;
}

const CounterpartyEditPage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
    user,
  } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState();
  const [oldCounterparty, setOldCounterparty] = useState({});
  const [defUsers, setDefUsers] = useState([]);
  const [responsible, setResponsible] = useState({});
  const [attrsForEdit, setAttrsForEdit] = useState(new Set());
  console.log(user)
  useEffect(() => {
    const req = async () => {
      const counterparty = await get_counterparty(params.id);
      const responsible = [];
      for (const user_id of counterparty.responsible_ids) {
        const user = await get_user(user_id);
        responsible.push(user);
      }
      counterparty.responsible = responsible;
      setOldCounterparty({ ...counterparty });
      setModelData(counterparty);
      const users = await get_users();
      setDefUsers(users);
    };
    req();
    pushPage(location.pathname);
    const attrsForEdit = new Set(
      roleAccess[userRole].fieldRestrictions.Counterparties?.edit
    );
    setAttrsForEdit(attrsForEdit);
  }, []);

  const getDataFromAttr = () => {
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    for (let id of responsible_ids) {
      if (id === null) {
        setErrors({
          responsible_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const data = {
      ...modelData,
      responsible_ids: Object.values(responsible).map((m) => m.id),
    };
    delete data['responsible'];
    return data;
  };

  return (
    <div className="pageUpdateModel">
      <div className={styles.controls}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await update_counterparty(
              params.id,
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(
                interface_translate['Counterpaty updated'][language]
              );
              resetGlobalError();
              setErrors({});
              popPage();
              const previousPage = getCurrentPage();
              navigate(previousPage ? previousPage : '..', {
                relative: 'path',
              });
            }
          }}
        >
          {interface_translate['Update'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('../..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      {modelData && (
        <div className="inputData">
          {attrsForEdit.has('name') && (
            <div className="inputName">
              <div className="openingBlockAttrName">
                {interface_translate['Company'][language]}
              </div>
              <input
                type="text"
                name="name"
                value={modelData.name}
                onChange={(e) => {
                  setModelData({ ...modelData, name: e.target.value });
                }}
              />
              {errors['name'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['name'][0]][language]}
                </div>
              )}
            </div>
          )}
          <OpeningBlock
            title={interface_translate['About сounterparty'][language]}
            open={true}
          >
            {attrsForEdit.has('group_translate') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Group'][language]}
                  </div>
                  {userRole == client_realtions_manager && (
                    <div>{types['client'][language]}</div>
                  )}
                  {userRole == supplier_realtions_manager && (
                    <div>{types['supplier'][language]}</div>
                  )}
                  {userRole == admin && (
                    <select
                      value={modelData.group}
                      onChange={(e) => {
                        setModelData({ ...modelData, group: e.target.value });
                      }}
                    >
                      <option value="client">
                        {types['client'][language]}
                      </option>
                      <option value="supplier">
                        {types['supplier'][language]}
                      </option>
                    </select>
                  )}
                </div>
                {errors['group'] && (
                  <div className="openingBlockErrorAttr">
                    {api_error_translate[errors['group'][0]][language]}
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('phone_number') && (
              <div>
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Phone number'][language]}
                    </div>
                    <PhoneInput
                      countryCodeEditable={false}
                      country={'ru'}
                      onlyCountries={['ru', 'cn']}
                      value={modelData.phone_number}
                      onChange={(v) => {
                        setModelData({ ...modelData, phone_number: `+${v}` });
                      }}
                    />
                  </div>
                </div>
                {errors['phone_number'] && (
                  <div className="openingBlockErrorAttr">
                    {api_error_translate[errors['phone_number'][0]][language]}
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('contact_person') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Contact person'][language]}
                  </div>
                  <input
                    type="text"
                    value={modelData.contact_person}
                    onChange={(e) => {
                      setModelData({
                        ...modelData,
                        contact_person: e.target.value,
                      });
                    }}
                  />
                </div>
                {errors['contact_person'] && (
                  <div className="openingBlockErrorAttr">
                    {api_error_translate[errors['contact_person'][0]][language]}
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('link_to_messenger') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Link to messenger'][language]}
                  </div>
                  <input
                    type="text"
                    value={modelData.link_to_messenger}
                    onChange={(e) => {
                      setModelData({
                        ...modelData,
                        link_to_messenger: e.target.value,
                      });
                    }}
                  />
                </div>
                {errors['link_to_messenger'] && (
                  <div className="openingBlockErrorAttr">
                    {
                      api_error_translate[errors['link_to_messenger'][0]][
                        language
                      ]
                    }
                  </div>
                )}
              </div>
            )}
            {attrsForEdit.has('responsible') && (
              <div>
                <ResponsibleListInput
                  containerClass={'openingBlockAttr'}
                  attrNameClass={'openingBlockAttrName'}
                  responsible={responsible}
                  setSelectedElement={(value) => {
                    setResponsible((prevData) => {
                      return { ...prevData, ...value };
                    });
                  }}
                  deleteSelectedElement={(id) => {
                    setResponsible((prevElements) => {
                      delete prevElements[id];
                      return { ...prevElements };
                    });
                  }}
                  initialElements={modelData.responsible}
                  defaultElements={defUsers}
                />
                {errors['responsible_ids'] && (
                  <div className="openingBlockErrorAttr">
                    {
                      api_error_translate[errors['responsible_ids'][0]][
                        language
                      ]
                    }
                  </div>
                )}
              </div>
            )}
          </OpeningBlock>
        </div>
      )}
      {!modelData && <div>Пусто</div>}
    </div>
  );
};

export default CounterpartyEditPage;
