import { useState, useRef, useEffect, cloneElement } from 'react';
import ModalWindow from './ModalWindow';
import React from 'react';
import styles from './DropDownMediaBlock.module.css';

const DropDownMediaBlock = (props) => {
  let { preViewImg, mediaBlock } = props;
  const [isActive, setIsActive] = useState(false);
  const mediaIsVideo = mediaBlock.type == 'video';
  const videoRef = useRef();
  if (mediaIsVideo) mediaBlock = cloneElement(mediaBlock, { ref: videoRef });

  useEffect(() => {
    if (isActive === false && videoRef.current) {
      videoRef.current.pause();
    }
  }, [isActive]);
  return (
    <div className={styles.dropDownMediaBlock}>
      <div
        className={styles.mediaContent}
        onClick={() => setIsActive(true)}
      >
        {preViewImg}
      </div>
      <ModalWindow
        isActive={isActive}
        setIsActive={setIsActive}
      >
        <div className={styles.modelMedia}>{mediaBlock}</div>
      </ModalWindow>
    </div>
  );
};

export default DropDownMediaBlock;
