import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory.jsx';
import useCommon from '../../hooks/useCommon.jsx';
import OpeningBlock from '../../components/OpeningBlock.jsx';
import DropDownMediaBlock from '../../components/DropDownMediaBlock.jsx';
import roleAccess from '../../roleAccess.js';
import unidentifiedDeliveryService from '../../services/unidentified_delivery.js';
import userService from '../../services/user.js';
import ModalWindow from '../../components/ModalWindow.jsx';
import TextField from '@mui/material/TextField';
import SelectStorageCellBtn from '../../components/SelectStorageCellBtn.jsx';
import { statuses_translate } from '../../texts/statuses.js';
import { interface_translate } from '../../texts/interface.js';
import { api_error_translate } from '../../texts/error.js';
import '../shared.css';

import styles from './UnidentifiedDeliveryPage.module.css';

async function get_unidentified_delivery(id) {
  const unidentifiedDelivery = await unidentifiedDeliveryService.get(id);
  return unidentifiedDelivery;
}

async function update_unidentified_delivery_storage_cell(
  id,
  storage_cell_id,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const data = { storage_cell_id: storage_cell_id };
  const result = await unidentifiedDeliveryService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_user(id) {
  const user = await userService.get(id);
  return user;
}

const UnidentifiedDeliveryPage = () => {
  const { language, setGlobalError, resetGlobalMsg, userRole } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [unidentifiedDelivery, setUnidentifiedDelivery] = useState();
  const [selectStorageCellErrors, setSelectStorageCellErrors] = useState({});
  const [attrsForRead, setAttrsForRead] = useState(new Set());

  useEffect(() => {
    const req = async () => {
      await requestGetUnidentifiedDelivery();
    };
    req();
    pushPage(location.pathname);
    const attrs_for_read = new Set(
      roleAccess[userRole].fieldRestrictions['Unidentified deliveries']?.read
    );
    setAttrsForRead(attrs_for_read);
  }, []);

  const updateStorageCell = async (storage_cell) => {
    if (!storage_cell) return;
    const result = await update_unidentified_delivery_storage_cell(
      unidentifiedDelivery.id,
      storage_cell.id,
      setSelectStorageCellErrors,
      setGlobalError,
      resetGlobalMsg,
      language
    );
    if (result) {
      requestGetUnidentifiedDelivery();
    }
    return result;
  };

  const requestGetUnidentifiedDelivery = async () => {
    const unidentified_delivery_resp = await get_unidentified_delivery(params.id);
    const responsible = [];
    for (const user_id of unidentified_delivery_resp.responsible_ids) {
      const user = await get_user(user_id);
      responsible.push(user);
    }
    unidentified_delivery_resp.responsible = responsible;
    const co_executors = [];
    for (const user_id of unidentified_delivery_resp.co_executors_ids) {
      const user = await get_user(user_id);
      co_executors.push(user);
    }
    unidentified_delivery_resp.co_executors = co_executors;
    unidentified_delivery_resp.status_tranclate =
      statuses_translate[unidentified_delivery_resp.status][language];
    unidentified_delivery_resp.storehouse_id =
      unidentified_delivery_resp.storage_cell
        ? unidentified_delivery_resp.storage_cell.shelving.storehouse.id
        : '';
    unidentified_delivery_resp.storehouse_name =
      unidentified_delivery_resp.storage_cell
        ? unidentified_delivery_resp.storage_cell.shelving.storehouse.name
        : '';
    unidentified_delivery_resp.storage_cell_name =
      unidentified_delivery_resp.storage_cell
        ? `${unidentified_delivery_resp.storage_cell.shelving.name}${unidentified_delivery_resp.storage_cell.number}`
        : '';
    setUnidentifiedDelivery(unidentified_delivery_resp);
  };

  if (unidentifiedDelivery == null) return;

  return (
    <div className="pageViewModel">
      <div className="controls">
        {roleAccess[userRole].permissions['Unidentified deliveries'].edit && (
          <button
            onClick={() => {
              navigate('edit');
            }}
          >
            {interface_translate['Edit'][language]}
          </button>
        )}
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
        <SelectStorageCellBtn
          btnName={interface_translate['Assign storage cell'][language]}
          setSelectedStorageCell={async (storage_cell) => {
            const result = await updateStorageCell(storage_cell);
            return result;
          }}
          errors={selectStorageCellErrors}
        />
      </div>
      {unidentifiedDelivery && (
        <div className="viewData">
          <OpeningBlock
            title={interface_translate['About unidentified delivery'][language]}
            open={true}
          >
            {attrsForRead.has('id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['unidentified delivery id'][language]}
                </div>
                <span>{unidentifiedDelivery.id}</span>
              </div>
            )}
            {attrsForRead.has('track_number') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Track number'][language]}
                </div>
                <span>{unidentifiedDelivery.track_number}</span>
              </div>
            )}
            {attrsForRead.has('status_translate') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Status'][language]}
                </div>
                <span>{unidentifiedDelivery.status_tranclate}</span>
              </div>
            )}
            {attrsForRead.has('total_weight_in_kg') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Total weight in kg'][language]}
                </div>
                <span>{unidentifiedDelivery.total_weight_in_kg}</span>
              </div>
            )}
            {attrsForRead.has('total_volume_in_m3') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Total volume in м³'][language]}
                </div>
                <span>{unidentifiedDelivery.total_volume_in_m3}</span>
              </div>
            )}
            {attrsForRead.has('actual_content') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Actual content'][language]}
                </div>
                <span>{unidentifiedDelivery.actual_content}</span>
              </div>
            )}
            {attrsForRead.has('media_content_acceptance') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Media content of acceptance'][language]}
                </div>
                <div className="listDropDomnMedia">
                  {unidentifiedDelivery.media_content_acceptance.map(
                    (model, id) => {
                      let mediaBlock;
                      let preViewImg;
                      if (model.file_type === 'photo') {
                        mediaBlock = (
                          <img
                            src={model.url}
                            alt="image"
                          />
                        );
                        preViewImg = mediaBlock;
                      } else if (model.file_type === 'video') {
                        mediaBlock = (
                          <video
                            src={model.url}
                            alt="video"
                            controls
                            style={{ maxWidth: '100%' }}
                          />
                        );
                        preViewImg = (
                          <p>{interface_translate['Video'][language]}</p>
                        );
                      }
                      return (
                        <DropDownMediaBlock
                          key={id}
                          preViewImg={preViewImg}
                          mediaBlock={mediaBlock}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            )}
            {attrsForRead.has('storehouse_name') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Storehouse'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(
                      `/storehouses/${unidentifiedDelivery.storehouse_id}`,
                      {
                        state: { from: location },
                      }
                    )
                  }
                >
                  {unidentifiedDelivery.storehouse_name}
                </span>
              </div>
            )}
            {attrsForRead.has('storage_cell_name') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Storage cell'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(
                      `/storage_cells/${unidentifiedDelivery.storage_cell.id}`,
                      {
                        state: { from: location },
                      }
                    )
                  }
                >
                  {unidentifiedDelivery.storage_cell_name}
                </span>
              </div>
            )}
            {attrsForRead.has('responsible') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Responsible'][language]}
                </div>
                <div className="listModels">
                  {unidentifiedDelivery.responsible.map((model) => {
                    return (
                      <div
                        className="modelBlock"
                        key={model.id}
                      >
                        {`${model.surname} ${model.name} ${model.patronymic}`}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {attrsForRead.has('co_executors') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Co executors'][language]}
                </div>
                <div className="listModels">
                  {unidentifiedDelivery.co_executors.map((model) => {
                    return (
                      <div
                        className="modelBlock"
                        key={model.id}
                      >
                        {`${model.surname} ${model.name} ${model.patronymic}`}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </OpeningBlock>
        </div>
      )}

      {!unidentifiedDelivery && <div>Пусто</div>}
    </div>
  );
};

export default UnidentifiedDeliveryPage;
