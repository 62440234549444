import { useState } from 'react';
import SortHeader from './SortHeader';
import styles from './TableHeader.module.css';

const TableHeader = (props) => {
  const { attr, name_for_display, use_sort, sortData, setSortData } = props;
  const [btnSortShow, setBtnSortShow] = useState(false);

  return (
    <th
      onMouseEnter={() => {
        setBtnSortShow(true);
      }}
      onMouseLeave={() => {
        setBtnSortShow(false);
      }}
    >
      <div className={styles.content}>
        {name_for_display}
        {use_sort && (
          <SortHeader
            className={styles.sortBtns}
            header_attr={attr}
            btnShow={btnSortShow}
            sortData={sortData}
            setSortData={setSortData}
          />
        )}
      </div>
    </th>
  );
};

export default TableHeader;
