import React, { useState } from 'react';
import useCommon from '../../hooks/useCommon';
import userService from '../../services/user';
import styles from './shered.module.css';
import { interface_translate } from '../../texts/interface';
import * as roles from '../../roles';
import { api_error_translate } from '../../texts/error';

const AddEmployeeForm = (props) => {
  const { language, setGlobalError, setGlobalMsg, userRole } = useCommon();
  const { closeModal } = props; // Используем для закрытия модального окна
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [messengerLink, setMessengerLink] = useState('');
  const [role, setRole] = useState(roles.user);
  const [bitrixId, setBitrixId] = useState('');
  const [errors, setErrors] = useState({});

  const onClickAddEmployee = async (event) => {
    event.preventDefault();
  
    const fullNameParts = fullName.trim().split(' ');
  
    if (fullNameParts.length !== 3) {
      setGlobalError(
        interface_translate?.['Full name must include surname, name, and patronymic']?.[language] ||
        'Full name must include surname, name, and patronymic'
      );
      return;
    }
  
    const data = {
      email: email,
      raw_password: pass,
      full_name: fullName,
      phone_number: phoneNumber || null,
      link_to_messenger: messengerLink,
      role: role,
      bitrix_id: bitrixId,
    };
  
    try {
      const result = await userService.addEmployee(data, setGlobalError);
  
      // Предполагаем, что ответ успешен, если получен объект с id
      if (result && result.id) {
        setGlobalMsg(
          interface_translate?.['Employee added successfully']?.[language] || 'Employee added successfully'
        );

        setEmail('');
        setPass('');
        setFullName('');
        setPhoneNumber('');
        setMessengerLink('');
        setRole(roles.user);
        setBitrixId('');

        closeModal(); // Закрываем модальное окно после успешного добавления
      } else {
        setErrors(result?.errors || {});
        setGlobalError(
          interface_translate?.['Unexpected error occurred']?.[language] || 'Unexpected error occurred'
        );
      }
    } catch (error) {
      setGlobalError(
        interface_translate?.['Unexpected error occurred']?.[language] || 'Unexpected error occurred'
      );
    }
  };

  // Проверяем, если роль не admin, не отображаем форму
  if (userRole !== roles.admin) {
    return null;
  }

  return (
    <form
      id="addEmployeeForm"
      className={styles.form}
      onSubmit={onClickAddEmployee}
    >
      <h2>{interface_translate?.['Add Employee']?.[language] || 'Add Employee'}</h2>
      <div className={styles.formFields}>
        <div className={styles.formField}>
          <label htmlFor="email">
            {interface_translate?.['Email']?.[language] || 'Email'}:
          </label>
          <input
            type="text"
            id="email"
            name="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {errors['email'] && (
          <div className="openingBlockErrorAttr">
            {api_error_translate?.[errors['email'][0]]?.[language] || 'Error'}
          </div>
        )}
        <div className={styles.formField}>
          <label htmlFor="password">
            {interface_translate?.['Password']?.[language] || 'Password'}:
          </label>
          <input
            type="password"
            id="password"
            name="password"
            required
            value={pass}
            onChange={(e) => setPass(e.target.value)}
          />
        </div>
        <div className={styles.formField}>
          <label htmlFor="full_name">
            {interface_translate?.['Full Name']?.[language] || 'Full Name'}:
          </label>
          <input
            type="text"
            id="full_name"
            name="full_name"
            required
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        {errors['full_name'] && (
          <div className="openingBlockErrorAttr">
            {api_error_translate?.[errors['full_name'][0]]?.[language] || 'Error'}
          </div>
        )}
        <div className={styles.formField}>
          <label htmlFor="phone_number">
            {interface_translate?.['Phone Number']?.[language] || 'Phone Number'}:
          </label>
          <input
            type="text"
            id="phone_number"
            name="phone_number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <div className={styles.formField}>
          <label htmlFor="messenger_link">
            {interface_translate?.['Link to messenger']?.[language] || 'Link to messenger'}:
          </label>
          <input
            type="text"
            id="messenger_link"
            name="messenger_link"
            value={messengerLink}
            onChange={(e) => setMessengerLink(e.target.value)}
          />
        </div>
        <div className={styles.formField}>
          <label htmlFor="bitrix_id">
            {interface_translate?.['Bitrix ID']?.[language] || 'Bitrix ID'}:
          </label>
          <input
            type="text"
            id="bitrix_id"
            name="bitrix_id"
            value={bitrixId}
            onChange={(e) => setBitrixId(e.target.value)}
          />
        </div>
        <div className={styles.formField}>
          <label htmlFor="role">
            {interface_translate?.['Role']?.[language] || 'Role'}:
          </label>
          <select
            id="role"
            name="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            {Object.entries(roles).map(([key, value]) => (
              <option key={key} value={value}>
                {interface_translate?.[key]?.[language] || key}
              </option>
            ))}
          </select>
        </div>
      </div>
      <button type="submit">{interface_translate?.['Add Employee']?.[language] || 'Add Employee'}</button>
    </form>
  );
};

export default AddEmployeeForm;
