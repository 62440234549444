import { useState, useEffect } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import ShipmentRead from '../../components/model_cards/ShipmentRead';
import shipmentService from '../../services/shipment';
import userService from '../../services/user.js';
import orderService from '../../services/order.js';
import { statuses_translate } from '../../texts/statuses.js';
import { types } from '../../texts/types.js';
import { interface_translate } from '../../texts/interface.js';
import ModalWindow from '../../components/ModalWindow';
import useCommon from '../../hooks/useCommon.jsx';
import styles from './ShipmentsByInvoiceNumber.module.css';
import usePageMovementHistory from '../../hooks/usePageMovementHistory.jsx';

async function get_shipments(filters = {}, size = 50) {
  const result = await shipmentService.get_by_filters(filters, { size: size });
  return result.data;
}

async function get_order(id) {
  const order = await orderService.get(id);
  return order;
}

async function get_user(id) {
  const user = await userService.get(id);
  return user;
}

async function get_shipment_logistics(id) {
  const shipment_logistics = await shipmentService.get_logistics(id);
  return shipment_logistics;
}
async function getShipmentLogistics(shipment_id) {
  const shipmentLogistics = await get_shipment_logistics(shipment_id);
  return shipmentLogistics;
}

async function getShipmentForDisplay(shipment, language) {
  let order = await get_order(shipment.order_id);
  shipment.order = order;
  const responsible = [];
  for (const user_id of shipment.responsible_ids) {
    const user = await get_user(user_id);
    responsible.push(user);
  }
  shipment.responsible = responsible;
  const co_executors = [];
  for (const user_id of shipment.co_executors_ids) {
    const user = await get_user(user_id);
    co_executors.push(user);
  }
  shipment.co_executors = co_executors;
  shipment.status_translate = statuses_translate[shipment.status][language];
  shipment.packing_translate = types[shipment.packing][language];
  shipment.storehouse_id = shipment.storage_cell
    ? shipment.storage_cell.shelving.storehouse.id
    : '';
  shipment.storehouse_name = shipment.storage_cell
    ? shipment.storage_cell.shelving.storehouse.name
    : '';
  shipment.storage_cell_name = shipment.storage_cell
    ? `${shipment.storage_cell.shelving.name}${shipment.storage_cell.number}`
    : '';
  let total_amount = 0;
  const shipment_products = [];
  for (let shipment_product of shipment.shipment_products) {
    shipment_product = get_product_for_display(
      shipment_product,
      order,
      language
    );
    if (shipment_product.currency == 'cny') {
      total_amount += shipment_product.total_cost * order.rate_cny_to_rub;
    } else if (shipment_product.currency == 'usd') {
      total_amount += shipment_product.total_cost * order.rate_dollar_to_rub;
    } else if (shipment_product.currency == 'rub') {
      total_amount += shipment_product.total_cost;
    }
    shipment_products.push(shipment_product);
  }
  shipment.shipment_products = shipment_products;
  shipment.total_amount = total_amount;
  return shipment;
}

function get_product_for_display(shipment_product, order, language) {
  const product = shipment_product.product;
  if (!product.link) product.link = '';
  product.qty_with_unit = `${product.qty} ${
    types[product.measurement_unit][language]
  }`;
  product.total_cost = product.price * product.qty;
  product.supplier_name = product.supplier.name;
  product.client_name = order.client.name;

  shipment_product.shipment_qty_with_unit = `${shipment_product.qty} ${
    types[shipment_product.product.measurement_unit][language]
  }`;

  return { ...product, ...shipment_product };
}

const ShipmentsByInvoiceNumber = () => {
  const { language } = useCommon();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [shipmentsForDisplay, setShipmentsForDisplay] = useState([]);
  const [viewBadSearchByInvoiceNumber, setViewBadSearchByInvoiceNumber] =
    useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(null);

  useEffect(() => {
    const invoiceNumber = searchParams.get('invoice_number');
    setInvoiceNumber(invoiceNumber);
    const req = async () => {
      const shipments = await get_shipments({
        invoice_number: invoiceNumber,
        compare_invoice_number: 'full',
      });
      if (shipments.length == 0) {
        setViewBadSearchByInvoiceNumber(true);
        return;
      }
      for (let shipment of shipments) {
        shipment = await getShipmentForDisplay(shipment);
        const logistics = await getShipmentLogistics(shipment.id);
        shipment.logistics = logistics;
      }
      setShipmentsForDisplay(shipments, language);
    };
    req();
    pushPage(`${location.pathname}${location.search}`);
  }, []);

  return (
    <div className="pageViewModel">
      <div className="controls">
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', {
              relative: 'path',
            });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
      </div>
      <div className={`vertical align-start ${styles.shipmentsContainer}`}>
        {shipmentsForDisplay.map((shipment) => {
          return (
            <ShipmentRead
              shipment={shipment}
              products={shipment.products}
              shipmentLogistics={shipment.logistics}
            />
          );
        })}
        {viewBadSearchByInvoiceNumber && (
          <ModalWindow
            isActive={viewBadSearchByInvoiceNumber}
            setIsActive={(value) => {
              setViewBadSearchByInvoiceNumber(value);
            }}
          >
            <div className="modalWindowDefaultContainer">
              <p>{interface_translate['Not found'][language]}</p>
              <button
                onClick={async () => {
                  setViewBadSearchByInvoiceNumber(false);
                  navigate('/unidentified_cargo/create', {
                    relative: 'path',
                    state: { invoiceNumber: invoiceNumber },
                  });
                }}
              >
                {interface_translate['Create unidentified cargo'][language]}
              </button>
            </div>
          </ModalWindow>
        )}
      </div>
    </div>
  );
};

export default ShipmentsByInvoiceNumber;
