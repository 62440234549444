import apiRequester from '../ApiRequester';
import { model_names } from '../texts/model_names.js';
import { api_error_translate } from '../texts/error.js';

class UnidentifiedCargoService {
  async create(data, setErrors, setGlobalError, resetGlobalMsg, language) {
    try {
      const formData = new FormData();
      formData.append('comment', data.comment ? data.comment : null);
      formData.append(
        'invoice_number',
        data.invoice_number ? data.invoice_number : null
      );
      formData.append(
        'storage_cell_id',
        data.storage_cell_id ? data.storage_cell_id : null
      );
      if (data.responsible_ids.length > 0) {
        data.responsible_ids.forEach((m) => {
          formData.append(`responsible_ids`, m);
        });
      } else {
        formData.append('responsible_ids', -1);
      }
      data.media_content_acceptance?.forEach((m) => {
        formData.append(`media_content_acceptance`, m);
      });
      await apiRequester.post('/unidentified_cargo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const err_msg = err.response.data.errors;
          if (err_msg === 'unidentified_cargo already exists') {
            setErrors({});
            setGlobalError(api_error_translate[err_msg][language]);
            resetGlobalMsg();
            return;
          } else {
            setErrors({ ...err_msg });
            resetGlobalMsg();
            return;
          }
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
      console.error(`Could not fetch data: ${err}`);
    }
  }

  async get(id) {
    try {
      const response = await apiRequester.get(`/unidentified_cargo/${id}`);
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }

  async get_by_filters(filterData, paginationSettings = { size: 20 }) {
    const jsonData = JSON.stringify(filterData);
    try {
      const response = await apiRequester.post(
        `/unidentified_cargo/filters`,
        jsonData,
        {
          params: paginationSettings,
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }

  async get_statuses() {
    const response = await apiRequester.get(`/unidentified_cargo/statuses`);
    return response.data;
  }

  async update(id, data, setErrors, setGlobalError, resetGlobalMsg, language) {
    const co_executor_ids_already_in_responsible_ids_pattern =
      /ids: .* already in responsible_ids/;
    try {
      const formData = new FormData();
      if (data.comment !== undefined) {
        formData.append('comment', data.comment ? data.comment : null);
      }
      if (data.invoice_number !== undefined) {
        formData.append(
          'invoice_number',
          data.invoice_number ? data.invoice_number : null
        );
      }
      if (data.status !== undefined) {
        formData.append('status', data.status);
      }
      if (data.storage_cell_id !== undefined) {
        formData.append('storage_cell_id', data.storage_cell_id);
      }
      if (data.responsible_ids !== undefined) {
        if (data.responsible_ids.length > 0) {
          data.responsible_ids.forEach((m) => {
            formData.append(`responsible_ids`, m);
          });
        } else {
          formData.append('responsible_ids', -1);
        }
      }
      if (data.media_content_acceptance_for_create !== undefined) {
        data.media_content_acceptance_for_create?.forEach((m) => {
          formData.append(`media_content_acceptance_for_create`, m);
        });
      }
      if (data.media_content_acceptance_id_for_delete !== undefined) {
        if (data.media_content_acceptance_id_for_delete.length > 0) {
          data.media_content_acceptance_id_for_delete?.forEach((m) => {
            formData.append(`media_content_acceptance_id_for_delete`, m);
          });
        } else {
          formData.append('media_content_acceptance_id_for_delete', -1);
        }
      }
      await apiRequester.patch(`/unidentified_cargo/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const err_msg = err.response.data.errors;
          if (
            err_msg.hasOwnProperty('co_executors_ids') &&
            co_executor_ids_already_in_responsible_ids_pattern.test(
              err_msg['co_executors_ids']
            )
          ) {
            setErrors({
              co_executors_ids: ['co_executors_ids already in responsible_ids'],
            });
            return;
          } else if (err_msg === 'unidentified_cargo already exists') {
            setErrors({});
            setGlobalError(api_error_translate[err_msg][language]);
            resetGlobalMsg();
            return;
          } else {
            setErrors({ ...err_msg });
            return;
          }
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
      console.error(`Could not fetch data: ${err}`);
    }
  }

  async delete(id, setGlobalError, language) {
    try {
      const response = await apiRequester.delete(`/unidentified_cargo/${id}`);
      return response.data;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (err_msg === 'foreign connection') {
            let msg = `${api_error_translate['linked to another model'][language]}: ${model_names[detail_msg][language]}`;
            setGlobalError(msg);
          }
        }
        return;
      }
      console.error(`Could not fetch data: ${err}`);
      throw err;
    }
  }
}

const unidentifiedCargoService = new UnidentifiedCargoService();
export default unidentifiedCargoService;
