import { useEffect } from 'react';
import { ImCross } from 'react-icons/im';
import styles from './GlobalMsg.module.css';

const GlobalMsg = (props) => {
  const { msg, resetMsg } = props;
  
  useEffect(() => {
    if (msg) {
      const timer = setTimeout(() => {
        resetMsg();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [msg]);
  if (!msg) return null;
  return (
    <div className={styles.GlobalMsg}>
      {msg}
      <ImCross
        style={{ cursor: 'pointer', fontSize: '10px' }}
        onClick={resetMsg}
      />
    </div>
  );
};

export default GlobalMsg;
